import React from 'react';

import { TouchableOpacity, Text, Image } from 'react-native';
import backIcon from '../assets/back-icon@3x.png';

// NOTE(dave): The 'hitSlop' here may cause issues with the width of the cards
// in the navigation stack...
const BackButton = ({ onPress, style = {} }) => (
    <TouchableOpacity
        onPress={onPress}
        hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
    >
        <Image
            resizeMode={Image.resizeMode.contain}
            source={{ uri: backIcon }}
            style={[style, { width: 18, height: 18 }]}
        />
    </TouchableOpacity>
);

export default BackButton;
