import React, { Component } from 'react';

import { ScrollView, Image, StyleSheet, View } from 'react-native';

import { Text, LargeLogo, Spacer, Button } from '../components';

import { colors } from '../constants/theme';

import magicLinkIllustration from '../assets/magic-hat@3x.png';

class MagicLinkReceiverScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            completeLoginClicked: false
        }
        this.handleLoginPress = this.handleLoginPress.bind(this);
    }
    async handleLoginPress() {
        this.setState({ completeLoginClicked: true });
        const { login } = this.props;
        await login();
    }

    render() {
        const { completeLoginClicked } = this.props;
        const showValidateText = !!completeLoginClicked
        return (
            <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                <View style={styles.body}>
                    <LargeLogo />
                    <Spacer size={25} />
                    <Image
                        resizeMode={Image.resizeMode.contain}
                        source={{ uri: magicLinkIllustration }}
                        style={styles.illustration}
                    />
                    <Spacer size={20} />
                    {showValidateText && (
                        <Text style={styles.instructionsText}>
                            Please wait while we validate your magic link...
                        </Text>
                    )}
                </View>
                <View style={styles.footer}>
                    {!showValidateText && (
                        <Button
                            style={styles.circleButton}
                            disabled={completeLoginClicked}
                            onPress={this.handleLoginPress}
                        >
                            Tap To Complete Login
                        </Button>
                    )}
                </View>
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 5,
        backgroundColor: colors.green,
    },
    illustration: {
        width: 245,
        height: 175,
        alignSelf: 'center',
    },
    instructionsText: {
        textAlign: 'center',
        fontSize: 21,
        color: colors.white,
        paddingLeft: 25,
        paddingRight: 25,
    },
    footer: {
        padding: 20,
        backgroundColor: colors.green,
        justifyContent: 'flex-end'
    },
    circleButton: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 30
    }
});

export default MagicLinkReceiverScreen;
