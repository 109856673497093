import React from 'react';
import { Linking, StyleSheet, Text } from 'react-native';

import { colors } from '../constants/theme';

const PrivacyPolicyTerms = () => (
    <Text style={styles.agreeText}>
        By continuing, you agree to Joey's{" "}
        <Text style={styles.link} onPress={() => Linking.openURL('https://getjoey.com/terms/')}>terms and conditions of use</Text>
        {" "}and{" "}
        <Text style={styles.link} onPress={() => Linking.openURL('https://getjoey.com/privacy/')}>privacy policy</Text>
    </Text>
);

export default PrivacyPolicyTerms;

const styles = StyleSheet.create({
    agreeText: {
        color: colors.white,
        fontSize: 15,
        textAlign: 'center',
        fontFamily: 'SctoGroteskA-Regular'
    },
    link: {
        textDecorationLine:'underline',
    }
});