import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Spacer, Button } from '../components';

import { colors } from '../constants/theme';

export default ({ onNotifyMe }) => (
    <View style={styles.container}>
        <View style={styles.innerContainer}>
            <Spacer size={15} />
            <Text style={styles.titleText}>
                Out of Coverage Area
            </Text>
            <Spacer size={5} />
            <Text style={styles.contentText}>
                We're sorry, Joey is not available at this location yet. Would you like us to let you know when we are in your area?
            </Text>
        </View>
        <Button onPress={onNotifyMe} muted>
            PLEASE NOTIFY ME
        </Button>
    </View>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    innerContainer: {
        width: `100%`,
        backgroundColor: colors.white,
        padding: 35,
        paddingTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentContainer: {},
    titleText: {
        fontFamily: 'ClanOT-Medium',
        fontSize: 18,
    },
    contentText: {
        textAlign: 'center',
    },
});