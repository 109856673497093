import React from 'react';
import { Linking, View } from 'react-native';
import { StackNavigator, NavigationActions } from 'react-navigation';
import { StripeProvider, Elements } from 'react-stripe-elements';

import {
    LandingScreen,
    LoginScreen,
    RegisterScreen,
    MagicLinkSender,
    MagicLinkReceiverScreen,
    LocationScreen,
    ItemDetailsScreen,
    ServicesScreen,
    AdditionalServicesScreen,
    OrderSummaryScreen,
    PaymentScreen,
    ConfirmationScreen,
    MyRequestsScreen,
    DeliveryDetailEnRouteScreen,
    DeliveryDetailOrderedScreen,
    DeliveryDetailCompleteScreen,
    DeliveryDetailAcceptedScreen,
    AccountManagementScreen,
    DeliveryCategoryReasonScreen,
    ServiceConfirmationScreen,
    DeleteAccountScreen
} from '../../src/screens';

import {
    ValueWeightInfoModal,
    PaymentCVCInfoModal,
    DeliveryCancelModal,
    DeliveryTipModal,
    OutOfCoverageAreaModal,
    PhotoViewerModal,
    EditPhotoModal,
    PhoneInUseRedirectModal,
    WithoutAddingCouponInfoModal,
    LocationSpecialInstructionsModal,
    HowYouHeardAboutUsModal
} from '../../src/modals';

import presenters from '../../presenters';

import { MenuButton, BackButton } from '../../src/components';

import { colors } from '../../src/constants/theme';
import AddOn from '../../lib/add-on';

const navigateLanding = NavigationActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: 'Landing' })],
});

const navigateLocation = NavigationActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: 'Location' })],
});

const navigateMyRequests = NavigationActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: 'MyRequests' })],
});

const navigateAccountManagement = NavigationActions.reset({
    index: 0,
    actions: [NavigationActions.navigate({ routeName: 'AccountManagement' })],
});

const menuItemHome = {
    label: 'Home',
    section: 'main',
    action: navigator => {
        navigator.dispatch(navigateLanding);
    },
};

const menuItemHomeLoggedIn = {
    label: 'Home',
    section: 'main',
    action: navigator => {
        navigator.dispatch(navigateLocation);
    }
};

const menuItemMyRequests = {
    label: 'My Requests',
    section: 'main',
    action: navigator => {
        navigator.dispatch(navigateMyRequests);
    },
};

const menuItemAccountManagement = {
    label: 'Account Management',
    section: 'main',
    action: navigator => {
        navigator.dispatch(navigateAccountManagement);
    },
};

const menuItemBecomeADriver = {
    label: 'Become A Driver',
    section: 'main',
    action: navigator => {
        Linking.openURL('http://getjoey.com/drivers/');
    },
};

const menuItemJoeyBusiness = {
    label: 'Joey Business',
    section: 'main',
    action: navigator => {
        Linking.openURL('https://www.getjoey.com/business');
    },
};

const menuItemHelp = {
    label: 'Help',
    section: 'main',
    action: navigator => {
        Linking.openURL('https://getjoey.com/faqs');
    },
};

const menuItemPrivacyPolicy = {
    label: '© 2021 Joey | Privacy Policy',
    section: 'footer',
    action: navigator => {
        Linking.openURL('http://getjoey.com/privacy');
    },
};

const joeyPhone = process.env.TWILIO_PHONE;

export default class ReactNavigationRouterAdapter {
    constructor(args) {
        this._onToggleMenu = args.handleToggleMenu;
        this._onLogout = args.handleLogout;
        this._onShowModal = args.handleShowModal;
        this._onDismissModal = args.handleDismissModal;

        //.NOTE(dave): These next few functions should probably be in some sort of
        // Infrastructure lib/adapter...
        this.onBeginLoading = args.handleBeginLoading;
        this.onEndLoading = args.handleEndLoading;
        this.onShowAlert = args.handleShowAlert;
    }

    getAuthenticatedNavigator(lib) {
        return this._getStack('Location', lib);
    }

    getUnauthenticatedNavigator(lib) {
        return this._getStack('Landing', lib);
    }

    getConsumerSideMenu(lib) {
        return [
            menuItemHomeLoggedIn,
            menuItemAccountManagement,
            menuItemMyRequests,
            menuItemBecomeADriver,
            menuItemJoeyBusiness,
            menuItemHelp,
            {
                label: 'Logout',
                section: 'main',
                action: async navigator => {
                    navigator.dispatch(navigateLanding);

                    await lib.user.logout();
                },
            },
            menuItemPrivacyPolicy
        ];
    }

    getUnauthenticatedSideMenu(lib) {
        return [
            menuItemHome,
            menuItemBecomeADriver,
            menuItemJoeyBusiness,
            menuItemHelp,
            menuItemPrivacyPolicy
        ];
    }

    logout() {
        this._onLogout();
    }

    goBack(navigator) {
        navigator.dispatch(
            NavigationActions.back({
                key: navigator.state.key,
            })
        );
    }

    _getStack(initialRouteName, lib) {
        return StackNavigator(
            {
                Landing: {
                    path: '',
                    screen: ({ navigation }) => {
                        const handleLogin = () => navigation.navigate('Login');
                        const handleNewCustomer = () => navigation.navigate('Register');

                        return (
                            <LandingScreen
                                onLogin={handleLogin}
                                onNewCustomer={handleNewCustomer}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        title: 'Joey',
                        headerTitle: '',
                        headerStyle: {
                            backgroundColor: colors.green,
                        },
                        headerLeft: (
                            <MenuButton
                                onPress={this._onToggleMenu}
                                style={{ marginLeft: 25 }}
                            />
                        )
                    }),
                },
                Login: {
                    path: 'login',
                    screen: ({ navigation }) => {
                        const navigateMagicLinkSender = NavigationActions.reset(
                            {
                                index: 0,
                                actions: [
                                    NavigationActions.navigate({
                                        routeName: 'MagicLinkSender',
                                    }),
                                ],
                            }
                        );

                        const handleContinue = async (email, phone) => {
                            const user = await lib.user.login(email, phone);

                            if (!user.error) {
                                navigation.navigate('MagicLinkSender');
                            }
                        };

                        return <LoginScreen onContinue={handleContinue} phone={navigation.state.params ? navigation.state.params.phone : null}/>;
                    },
                    navigationOptions: ({ navigation }) => ({
                        title: 'Login',
                        headerTitle: '',
                        headerStyle: {
                            backgroundColor: colors.green,
                        },
                    }),
                },
                Register: {
                    path: 'register',
                    screen: ({ navigation }) => {
                        const handleContinue = async (email, phone) => {
                            const user = await lib.user.register(email, phone);
                            if (!user.error) {
                                document.location.href='/';
                                //navigation.dispatch(navigateLocation); // not necessary since we're not building the native app anymore, causes weird bug on ios and desktop safari https://sevenhillstechnology.slack.com/archives/CJ6CKBJAH/p1636699451093900
                            } else if (user.error === 'Magic link sent.') {
                                navigation.navigate('MagicLinkSender', {sender: 'register'});
                            } else {
                                alert(user.error);
                            }
                        };

                        return <RegisterScreen onContinue={handleContinue} />;
                    },
                    navigationOptions: ({ navigation }) => ({
                        title: 'New Customer',
                        headerTitle: '',
                        headerStyle: {
                            backgroundColor: colors.green,
                        },
                    }),
                },
                RegisterPhone: {
                    path: 'register/:phone',
                    screen: ({ navigation }) => {
                        const handleContinue = async (email, phone) => {
                            const user = await lib.user.register(email, phone);
                            if (!user.error) {
                                document.location.href='/';
                                //navigation.dispatch(navigateLocation); // not necessary since we're not building the native app anymore, causes weird bug on ios and desktop safari https://sevenhillstechnology.slack.com/archives/CJ6CKBJAH/p1636699451093900
                            } else if (user.error === 'Magic link sent.') {
                                navigation.navigate('MagicLinkSender', {sender: 'register'});
                            } else {
                                alert(user.error);
                            }
                        };

                        return <RegisterScreen onContinue={handleContinue} phone={navigation.state.params ? navigation.state.params.phone : null} />;
                    },
                    navigationOptions: ({ navigation }) => ({
                        title: 'New Customer',
                        headerTitle: '',
                        headerStyle: {
                            backgroundColor: colors.green,
                        },
                    }),
                },
                MagicLinkSender: {
                    path: '',
                    screen: ({navigation}) => {
                        const navigateLogin = NavigationActions.reset({
                            index: 0
                        });

                        const handleGoBack = async() => {
                            navigation.goBack();
                        }

                        const {params} = navigation.state;
                        const sender = params ? params.sender : null;

                        return <MagicLinkSender handleGoBack={handleGoBack} sender={sender} />;
                    },
                    navigationOptions: () => ({
                        title: 'Login',
                        headerTitle: '',
                        headerStyle: {
                            backgroundColor: colors.green,
                        },
                    }),
                },
                MagicLinkReceiver: {
                    path: 'magic/:token',
                    screen: ({ navigation }) => {
                        const login = async () => {
                            const user = await lib.user.validateMagicLink(
                                navigation.state.params.token
                            );

                            if (!user.error) {
                                document.location.href='/';
                                //navigation.dispatch(navigateLocation); // not necessary since we're not building the native app anymore, causes weird bug on ios and desktop safari https://sevenhillstechnology.slack.com/archives/CJ6CKBJAH/p1636699451093900
                            } else {
                                navigation.dispatch(navigateLanding);
                            }
                        };

                        return <MagicLinkReceiverScreen login={login} />;
                    },
                    navigationOptions: () => ({
                        title: '',
                        headerStyle: {
                            backgroundColor: colors.green,
                        },
                        headerLeft: (
                            <MenuButton
                                onPress={this._onToggleMenu}
                                style={{ marginLeft: 25 }}
                            />
                        ),
                    }),
                },
                Location: {
                    path: '',
                    screen: ({ navigation }) => {
                        lib.driveBuilder.reset();

                        const handleContinue = async ({
                            origin,
                            destination
                        }) => {
                            let drive = await lib.driveBuilder.submitLocations(
                                origin,
                                destination
                            );

                            if (!drive.error) {
                                navigation.navigate('DeliveryCategoryReason');
                            }
                        };

                        const handleOutOfCoverageArea = ({ address }) => {
                            this._onShowModal(OutOfCoverageAreaModal, {
                                onNotifyMe: () => {
                                    const toAddress = 'info@getjoey.com';
                                    const subject = 'Out of Range Feedback';
                                    const thankYou =
                                        'Thank you! We will send you an update on when JOEY is available in your neighborhood.';
                                    const lineBreak = '%0D%0A%0D%0A';
                                    const outOfRangeAddress = `Out of range address: ${address}`;

                                    Linking.openURL(
                                        `mailto:${toAddress}?Subject=${subject}&Body=${thankYou}${lineBreak}${outOfRangeAddress}`
                                    );
                                },
                            });
                        };

                        const checkForSpecialInstructions = async (address) => {
                            const business = await lib.business.getSpecialInstructions(address);
                            if (business.error) alert(business.error.message);
                            return business.result;
                        }

                        const handleLocationSpecialInstructions = ({ originBusiness, destinationBusiness, onContinue }) => {
                            const onContinueWithDismiss = () => {
                                this._onDismissModal();
                                onContinue();
                            }

                            this._onShowModal(LocationSpecialInstructionsModal, {
                                originBusiness,
                                destinationBusiness,
                                onContinue: onContinueWithDismiss,
                                onDismiss: this._onDismissModal.bind(this),
                            });
                        }

                        const checkIfActiveDeliveryIsInProgress =
                            lib.drive.isActiveDeliveryInProgress;

                        const handleOnActiveDeliveryPressed = () => {
                            const navigateMyRequests = NavigationActions.reset({
                                index: 0,
                                actions: [
                                    NavigationActions.navigate({
                                        routeName: 'MyRequests',
                                    }),
                                ],
                            });

                            navigation.dispatch(navigateMyRequests);
                        };

                        const handleGetCity = async (coords) => {
                            return await lib.city.getClosestCity(coords);
                        }

                        const handleCheckHowYouHeard = async () => {
                            const {result: user} = await lib.user.getById(window.localStorage.getItem('@joey-consumer-user-id'));
                            if (!user.howYouHeardAboutUs) {
                                this._onShowModal(HowYouHeardAboutUsModal, {
                                    onSubmit: async (data) => {
                                        let {howYouHeardAboutUs, howYouHeardAboutUsTimeStamp} = data;
                                        await lib.user.update({howYouHeardAboutUs, howYouHeardAboutUsTimeStamp})
                                        this._onDismissModal();
                                    }
                                }, true);
                            }
                        }

                        return (
                            <LocationScreen
                                onToggleMenu={this._onToggleMenu}
                                onContinue={handleContinue}
                                onOutOfCoverageArea={handleOutOfCoverageArea}
                                checkIfActiveDeliveryIsInProgress={checkIfActiveDeliveryIsInProgress}
                                onActiveDeliveryPressed={handleOnActiveDeliveryPressed}
                                onGetCity={handleGetCity}
                                onLocationSpecialInstructions={handleLocationSpecialInstructions}
                                checkForSpecialInstructions={checkForSpecialInstructions}
                                onCheckHowYouHeard = {handleCheckHowYouHeard}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        title: 'Location',
                        header: null,
                    }),
                },
                ItemDetails: {
                    path: '',
                    screen: ({ navigation }) => {
                        const { origin, destination } = lib.driveBuilder;

                        const hasSpecialInstructions =
                            (origin.business && origin.business.consumerNotes) ||
                            (destination.business && destination.business.consumerNotes);

                        let showLocationSpecialInstructions = null;

                        if (hasSpecialInstructions) {
                            showLocationSpecialInstructions = () => {
                                this._onShowModal(LocationSpecialInstructionsModal, {
                                    originBusiness: origin.business,
                                    destinationBusiness: destination.business,
                                    onDismiss: this._onDismissModal.bind(this),
                                });
                            }
                        }

                        const handleContinue = async ({
                            totalValue,
                            totalWeight,
                            description,
                        }) => {
                            const drive = await lib.driveBuilder.submitCargo(
                                totalValue,
                                totalWeight,
                                description
                            );

                            if (!drive.error) {
                                console.log(lib.driveBuilder.toString());
                                navigation.navigate('Services');
                            }
                        };

                        const showValueWeightInfo = () =>
                            this._onShowModal(ValueWeightInfoModal);

                        const showEditPhotoModal = (index, photo) => {
                            this._onShowModal(EditPhotoModal, {
                                photo,
                                onPhotoRemove: () => {
                                    lib.driveBuilder.removePhoto(index);

                                    this._onDismissModal();
                                    // NOTE(dave): Running setParams refreshes
                                    // the route and allows it to get the latest
                                    // props.
                                    navigation.setParams();
                                },
                            });
                        };

                        const handleUploadPhoto = async photo => {
                            await lib.driveBuilder.addPhoto(photo);

                            // NOTE(dave): Running setParams refreshes
                            // the route and allows it to get the latest
                            // props.
                            navigation.setParams();
                        };

                        return (
                            <ItemDetailsScreen
                                onContinue={handleContinue}
                                onShowValueWeightInfo={showValueWeightInfo}
                                onEditPhoto={showEditPhotoModal}
                                onUploadPhoto={handleUploadPhoto}
                                uploadedPhotoURLs={lib.driveBuilder.photoURLs}
                                beginLoading={this.onBeginLoading}
                                showLocationSpecialInstructions={showLocationSpecialInstructions}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'What can we help you deliver?',
                    }),
                },
                DeliveryCategoryReason: {
                    path: '',
                    screen: ({ navigation }) => {
                        const handleContinue = async ({
                            reason,
                        }) => {
                            const drive = await lib.driveBuilder.submitCategoryReason(reason);

                            if (!drive.error) {
                                console.log(lib.driveBuilder.toString());
                                navigation.navigate('ItemDetails');
                            }
                        };

                        return (
                            <DeliveryCategoryReasonScreen
                                onContinue={handleContinue}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Select a category or reason for delivery.',
                    })
                },
                Services: {
                    path: '',
                    screen: ({ navigation }) => {
                        const handleSelect = async ({ truck }) => {
                            lib.driveBuilder.truck = truck;

                            console.log(lib.driveBuilder.toString());

                            navigation.navigate('ServiceConfirmation', {truck});
                        };

                        const fetchTrucks = lib.truck.filter;

                        // We really only want to look up trucks for the origin city, for some reason this takes an array
                        const originCity = lib.driveBuilder.origin.serviceCityId ? [lib.driveBuilder.origin.serviceCityId] : [];

                        return (
                            <ServicesScreen
                                fetchTrucks={fetchTrucks}
                                onSelect={handleSelect}
                                originCity={originCity}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Select a service',
                    }),
                },
                ServiceConfirmation: {
                    path: '',
                    screen: ({ navigation }) => {
                        const handleContinue = async ({ }) => {
                            await lib.driveBuilder.loadAddOns();
                            navigation.navigate('AdditionalServices');
                        };

                        return (
                            <ServiceConfirmationScreen
                                driveBuilder={lib.driveBuilder}
                                onContinue={handleContinue}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Service Type',
                    }),
                },
                AdditionalServices: {
                    path: '',
                    screen: ({ navigation }) => {

                        const handleContinue = async () => {
                            const schedulingAddOn = lib.driveBuilder.addOns.find(addOn => addOn.optionType === AddOn.OPTION_TYPES.scheduled);
                            if (schedulingAddOn && schedulingAddOn.value && (!schedulingAddOn.value.date || !schedulingAddOn.value.time)) {
                                lib.router.showAlert({
                                    title: 'Error',
                                    message: 'Please choose your scheduling date and time, or deselect the scheduled delivery option',
                                });
                                return;
                            }

                            let drive = await lib.driveBuilder.submitOrder();

                            if (!drive.error) {
                                console.log(
                                    lib.driveBuilder.toString()
                                );

                                navigation.navigate('OrderSummary');
                            }
                        };

                        const setAddOn = (addOnId, value) => {
                            lib.driveBuilder.setAddOn(addOnId, value);
                            navigation.setParams();
                        }

                        return (
                            <AdditionalServicesScreen
                                onContinue={handleContinue}
                                driveBuilder={lib.driveBuilder}
                                addOnLib={lib.addOn}
                                setAddOn={setAddOn}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Additional services',
                    }),
                },
                OrderSummary: {
                    path: '',
                    screen: ({ navigation }) => {
                        const handleContinue = async () => {
                            navigation.navigate('Payment');
                        };

                        return (
                            <OrderSummaryScreen
                                driveBuilder={lib.driveBuilder}
                                onContinue={handleContinue}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Order Summary',
                    }),
                },
                Payment: {
                    path: '',
                    screen: ({ navigation }) => {
                        const { _id, estimatedCost } = lib.driveBuilder;
                        
                        let userId = '';

                        const handleGetUserInfo = async () => {
                            const {
                                result: drive,
                            } = await lib.drive.getById(_id);
                
                            const {
                                result: user,
                            } = await lib.user.getById(drive.requesterId);

                            userId = drive.requesterId;

                            return user;
                        };

                        const handleCheckExistingUser = async (email, phone) => {
                            const {
                                result: users,
                            } = await lib.user.checkForExistingUserByUserInfo(userId, email, phone);

                            return users;
                        }

                        const handleCreditCardPayment = async ({
                            cardToken,
                            saveSource,
                            usingSavedSource,
                            onComplete,
                            name,
                            email,
                            phone,
                            couponId
                        }) => {
                            phone = phone.replace(/[()\- ]/g, '');

                            const drive = await lib.driveBuilder.submitCreditCardPayment(
                                cardToken,
                                name,
                                email,
                                phone,
                                couponId,
                                saveSource,
                                usingSavedSource
                            );

                            if (drive.error) {
                                return { error: drive.error };
                            } else {
                                // NOTE(dave): Right now, resetting to the
                                // confirmation screen pops all the current screens
                                // on the stack. IMO it's an undesirable result. The
                                // following issue on GitHub outlines a proposal for
                                // an option to fix it:
                                // https://github.com/react-community/react-navigation/issues/1663
                                if (onComplete) {
                                    onComplete('success');
                                }

                                const navigateConfirmation = NavigationActions.reset(
                                    {
                                        index: 0,
                                        actions: [
                                            NavigationActions.navigate({
                                                routeName: 'Confirmation',
                                            }),
                                        ],
                                    }
                                );

                                navigation.dispatch(navigateConfirmation);
                                console.log(lib.driveBuilder.toString());
                            }

                            if (onComplete) {
                                onComplete('error');
                            }
                        };

                        const handleApplePayPayment = async ({
                            token,
                            callback,
                            couponId
                        }) => {
                            let drive = await lib.driveBuilder.submitApplePayPayment(
                                token,
                                couponId
                            );

                            if (!drive.error) {
                                console.log('success!');

                                callback();

                                // NOTE(dave): Right now, resetting to the
                                // confirmation screen pops all the current screens
                                // on the stack. IMO it's an undesirable result. The
                                // following issue on GitHub outlines a proposal for
                                // an option to fix it:
                                // https://github.com/react-community/react-navigation/issues/1663
                                const navigateConfirmation = NavigationActions.reset(
                                    {
                                        index: 0,
                                        actions: [
                                            NavigationActions.navigate({
                                                routeName: 'Confirmation',
                                            }),
                                        ],
                                    }
                                );

                                navigation.dispatch(navigateConfirmation);
                                console.log(lib.driveBuilder.toString());
                            }
                        };

                        const showPaymentCVCInfo = () =>
                            this._onShowModal(PaymentCVCInfoModal);

                        const createPaymentRequest = (requestPayerPhone ,requestPayerEmail) => {
                            const paymentRequest = lib.payment.gateway.paymentRequest({
                                country: 'US',
                                currency: 'usd',
                                total: {
                                    label: 'Joey Service',
                                    amount: Number(estimatedCost),
                                    pending: true
                                },
                                requestPayerName: true,
                                requestPayerPhone,
                                requestPayerEmail
                            });

                            return paymentRequest;
                        }

                        const handleCouponAdd = async (coupon) => {
                            const {
                                result: drive,
                            } = await lib.drive.getById(_id);
                            const originCityId = drive.route.origin.serviceCityId;
                            return await lib.coupon.checkIfCouponIsValid(coupon, originCityId, presenters.coupons.details);
                        }

                        const handleSubmitWithoutAddingCoupon = async () => {
                            this._onShowModal(WithoutAddingCouponInfoModal);
                        };

                        return (
                            <StripeProvider stripe={lib.payment.gateway.stripe}>
                                <Elements fonts={[
                                    {
                                        cssSrc: process.env.FONTS_URL
                                    }
                                ]}>
                                    <PaymentScreen
                                        onCreditCardPayment={handleCreditCardPayment}
                                        isApplePayAvailable={
                                            lib.payment.isApplePayAvailable
                                        }
                                        onApplePayPayment={handleApplePayPayment}
                                        onPaymentCVCInfo={showPaymentCVCInfo}
                                        showAlert={this.onShowAlert}
                                        estimatedCost={estimatedCost}
                                        createPaymentRequest={createPaymentRequest}
                                        getUserInfo={handleGetUserInfo}
                                        checkExistingUser={handleCheckExistingUser}
                                        onCouponAdd={handleCouponAdd}
                                        onShowModal={handleSubmitWithoutAddingCoupon}
                                        goBack={navigation.goBack}
                                    />
                                </Elements>
                            </StripeProvider>
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Checkout',
                    }),
                },
                Confirmation: {
                    path: '',
                    screen: ({ navigation }) => {
                        const handleSubmit = async (email) => {
                            return await lib.user.recommendFriend(email);
                        }

                        const handleNavigateHome = () => {
                            navigation.dispatch(navigateLocation);
                        }

                        return (
                            <ConfirmationScreen
                                onSubmit={handleSubmit}
                                onToggleMenu={this._onToggleMenu}
                                onNavigateHome={handleNavigateHome}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Order Complete. Yay!'
                    }),
                },
                MyRequest: {
                    path: 'my-requests/:driveId',
                    screen: ({ navigation }) => {    
                        const handleSelect = async ({ drive }) => {
                            console.log(drive);

                            const { id, statusType } = drive;

                            const {
                                result: driveDetails,
                            } = await lib.drive.getById(id);

                            handleNavigation(statusType, driveDetails);
                        };

                        const redirect = async () => {
                            const {
                                result: driveDetails
                            } = await lib.drive.getById(navigation.state.params.driveId);

                            const {statusType} = driveDetails.summaryData;
                            handleNavigation(statusType, driveDetails);
                        };

                        const handleNavigation = (statusType, driveDetails) => {
                            if (statusType === 'enRoute') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailEnRoute',
                                        params: { drive: driveDetails },
                                    })
                                );
                            } else if (statusType === 'ordered') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailOrdered',
                                        params: { drive: driveDetails },
                                    })
                                );
                            } else if (statusType === 'accepted') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailAccepted',
                                        params: { drive: driveDetails },
                                    })
                                );
                            } else if (statusType === 'complete') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailComplete',
                                        params: { drive: driveDetails },
                                    })
                                );
                            }
                        }

                        redirect();

                        const fetchDrives = lib.drive.filter;

                        return (
                            <MyRequestsScreen
                                fetchData={fetchDrives}
                                onSelect={handleSelect}
                                onToggleMenu={this._onToggleMenu}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'My Requests'
                    }),
                },
                MyRequests: {
                    path: 'my-requests',
                    screen: ({ navigation }) => {
                        const handleSelect = async ({ drive }) => {
                            console.log(drive);

                            const { id, statusType } = drive;

                            const {
                                result: driveDetails,
                            } = await lib.drive.getById(id);

                            if (statusType === 'enRoute') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailEnRoute',
                                        params: { drive: driveDetails },
                                    })
                                );
                            } else if (statusType === 'ordered') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailOrdered',
                                        params: { drive: driveDetails },
                                    })
                                );
                            } else if (statusType === 'accepted') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailAccepted',
                                        params: { drive: driveDetails },
                                    })
                                );
                            } else if (statusType === 'complete') {
                                navigation.dispatch(
                                    NavigationActions.navigate({
                                        routeName: 'DeliveryDetailComplete',
                                        params: { drive: driveDetails },
                                    })
                                );
                            }
                        };

                        const fetchDrives = lib.drive.filter;

                        return (
                            <MyRequestsScreen
                                fetchData={fetchDrives}
                                onSelect={handleSelect}
                                onToggleMenu={this._onToggleMenu}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'My Requests'
                    }),
                },
                DeliveryDetailEnRoute: {
                    path: 'my-requests', //'request/:request-id',
                    screen: ({ navigation }) => {
                        const {
                            id,
                            summaryData,
                            deliveryData,
                            support : {driverExt},
                            driverData
                        } = navigation.state.params.drive;

                        return (
                            <DeliveryDetailEnRouteScreen
                                id={id}
                                summaryData={summaryData}
                                deliveryData={deliveryData}
                                phone={joeyPhone}
                                ext={driverExt}
                                driverData={driverData}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'En Route'
                    }),
                },
                DeliveryDetailOrdered: {
                    path: 'my-requests', //'request/:request-id',
                    screen: ({ navigation }) => {
                        console.log(
                            '!!!!!!!!!!',
                            navigation.state.params.drive
                        );

                        const {
                            id,
                            summaryData,
                            cargoData,
                            deliveryData,
                            photoData = [],
                            price,
                            consumerPrice,
                            couponValue,
                        } = navigation.state.params.drive;

                        const handleCancel = () => {
                            this._onShowModal(DeliveryCancelModal, {
                                onCancel: async () => {
                                    const drive = await lib.drive.cancel(id);

                                    if (!drive.error) {
                                        this._onDismissModal();

                                        const navigateMyRequests = NavigationActions.reset(
                                            {
                                                index: 0,
                                                actions: [
                                                    NavigationActions.navigate({
                                                        routeName: 'MyRequests',
                                                    }),
                                                ],
                                            }
                                        );

                                        navigation.dispatch(navigateMyRequests);
                                    }
                                },
                            });
                        };

                        const showPhotoViewer = () => {
                            if (photoData.length > 0) {
                                this._onShowModal(PhotoViewerModal, {
                                    photos: photoData,
                                });
                            }
                        };

                        return (
                            <DeliveryDetailOrderedScreen
                                id={id}
                                summaryData={summaryData}
                                cargoData={cargoData}
                                deliveryData={deliveryData}
                                price={price}
                                onCancel={handleCancel}
                                showPhotoViewer={showPhotoViewer}
                                photo={photoData.length > 0 ? photoData[0] : null}
                                goBack={navigation.goBack}
                                consumerPrice={consumerPrice}
                                couponValue={couponValue}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Delivery Details'
                    }),
                },
                DeliveryDetailAccepted: {
                    path: 'my-requests', //'request/:request-id',
                    screen: ({ navigation }) => {
                        const {
                            id,
                            summaryData,
                            cargoData,
                            deliveryData,
                            driverData,
                            photoData = [],
                            price,
                            support : {driverExt},
                            consumerPrice,
                            couponValue,
                        } = navigation.state.params.drive;

                        const showPhotoViewer = () => {
                            if (photoData.length > 0) {
                                this._onShowModal(PhotoViewerModal, {
                                    photos: photoData,
                                });
                            }
                        };

                        const handleCancel = () => {
                            this._onShowModal(DeliveryCancelModal, {
                                onCancel: async () => {
                                    const drive = await lib.drive.cancel(id);

                                    if (!drive.error) {
                                        this._onDismissModal();

                                        const navigateMyRequests = NavigationActions.reset(
                                            {
                                                index: 0,
                                                actions: [
                                                    NavigationActions.navigate({
                                                        routeName: 'MyRequests',
                                                    }),
                                                ],
                                            }
                                        );

                                        navigation.dispatch(navigateMyRequests);
                                    }
                                },
                            });
                        };

                        return (
                            <DeliveryDetailAcceptedScreen
                                id={id}
                                summaryData={summaryData}
                                cargoData={cargoData}
                                deliveryData={deliveryData}
                                driverData={driverData}
                                price={price}
                                consumerPrice={consumerPrice}
                                couponValue={couponValue}
                                showPhotoViewer={showPhotoViewer}
                                photo={photoData.length > 0 ? photoData[0] : null}
                                onCancel={handleCancel}
                                phone={joeyPhone}
                                ext={driverExt}
                                goBack={navigation.goBack}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Delivery Details'
                    }),
                },
                DeliveryDetailComplete: {
                    path: 'my-requests', //'request/:request-id',
                    screen: ({ navigation }) => {
                        const {
                            id,
                            summaryData,
                            cargoData,
                            deliveryData,
                            photoData = [],
                            price,
                            starRating,
                            driverData,
                            tip,
                            addOns,
                            consumerPrice,
                            couponValue,
                        } = navigation.state.params.drive;


                        const showPhotoViewer = () => {
                            if (photoData.length > 0) {
                                this._onShowModal(PhotoViewerModal, {
                                    photos: photoData,
                                });
                            }
                        };

                        const handleRating = async (starRating) => {
                            await lib.drive.rateDrive(id, starRating);

                            const {
                                result: driveDetails,
                            } = await lib.drive.getById(id);

                            navigation.setParams({ drive: driveDetails });
                        }

                        const handleAddTip = async () => {
                            this._onShowModal(DeliveryTipModal, {
                                onAddTip: async (amount) => {
                                    await lib.drive.addTip(id, amount);

                                    const {
                                        result: driveDetails,
                                    } = await lib.drive.getById(id);

                                    navigation.setParams({drive: driveDetails});
                                    this._onDismissModal();
                                },
                            });
                        };

                        return (
                            <DeliveryDetailCompleteScreen
                                id={id}
                                summaryData={summaryData}
                                cargoData={cargoData}
                                deliveryData={deliveryData}
                                price={price}
                                addOns={addOns}
                                showPhotoViewer={showPhotoViewer}
                                onRating={handleRating}
                                starRating={starRating}
                                driverData={driverData}
                                onAddTip={handleAddTip}
                                tip={tip}
                                photo={photoData.length > 0 ? photoData[0] : null}
                                goBack={navigation.goBack}
                                consumerPrice={consumerPrice}
                                couponValue={couponValue}
                                drive={navigation.state.params.drive}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Delivery Details'
                    }),
                },
                AccountManagement: {
                    path: 'account-management',
                    screen: ({ navigation }) => {
                        const getUser = async () => await lib.user.getById(getUserId());
                        const updateUser = async (userData) => await lib.user.update(userData);
                        const removeSavedCard = async(cardId) => await lib.user.removeSavedCard(cardId);
                        

                        return (
                            <AccountManagementScreen
                                getUser={getUser}
                                updateUser={updateUser}
                                removeSavedCard={removeSavedCard}
                                onToggleMenu={this._onToggleMenu}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Account Info'
                    }),
                },
                DeleteAccount: {
                    path: 'delete-account',
                    screen:({navigation}) => {
                        const getUser = async () => await lib.user.getById(getUserId());
                        const logout = async(navigator) => {
                            navigator.dispatch(navigateLanding);
                            await lib.user.logout();
                        }
                        const eraseUserData = async() =>{
                            return await _resolve(lib.user.eraseUserData())
                        }

                        return(
                            <DeleteAccountScreen
                                getUser={getUser}
                                logout={logout}
                                eraseUserData={eraseUserData}
                            />
                        );
                    },
                    navigationOptions: () => ({
                        header: null,
                        title: 'Delete Account'
                    }),
                    
                },
            },
            {
                initialRouteName,
                headerMode: 'screen',
                navigationOptions: ({ navigation }) => ({
                    headerTitleStyle: {
                        alignSelf: 'center',
                        color: colors.darkGrey,
                        fontFamily: 'ClanOT-Medium',
                        fontSize: 15,
                    },
                    headerStyle: {
                        backgroundColor: colors.grey,
                    },
                    headerLeft: (
                        <BackButton
                            onPress={() => setTimeout(navigation.goBack, 0)}
                            style={{ marginLeft: 25 }}
                        />
                    ),
                }),
            }
        );
    }
}
