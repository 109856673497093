import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, ScrollView, Image, TouchableOpacity, Switch } from 'react-native';
import { CardElement, injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import {
    Input,
    Button,
    Text,
    Spacer,
    NavigationHeader,
} from '../components';

import {blurAllFields} from '../helpers/blur-all-fields';

import { colors } from '../constants/theme';
import closeIcon from '../assets/close-icon-large-orange@3x.png';
import closeIconWhite from '../assets/close-icon-large-white@3x.png';

import { InfoModal } from '../modals';

class PaymentScreen extends PureComponent {
    static propTypes = {
        onCreditCardPayment: PropTypes.func.isRequired,
        isApplePayAvailable: PropTypes.func.isRequired,
        onApplePayPayment: PropTypes.func.isRequired,
        onPaymentCVCInfo: PropTypes.func.isRequired,
        showAlert: PropTypes.func.isRequired,
        estimatedCost: PropTypes.string.isRequired,
        createPaymentRequest: PropTypes.func.isRequired,
        getUserInfo: PropTypes.func.isRequired,
        checkExistingUser: PropTypes.func.isRequired,
        onCouponAdd: PropTypes.func.isRequired,
        onShowModal: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired
    };

    state = {
        nameOnCard: '',
        paymentRequest: null,
        phoneNumber: '',
        email: '',
        hasPhone: false,
        hasEmail: false,
        coupon: '',
        addedCoupon: null,
        invalidCoupon: false,
        showModal: false,
        nameErorr: null,
        phoneEmailError: null
    };

    componentDidMount = async () => {
        try {
            const user = await this.props.getUserInfo();

            if (user.phone) {
                this.setState({ hasPhone: true });
                this.setState({phoneNumber: user.phone});
            }

            if (user.email) {
                this.setState({ hasEmail: true });
                this.setState({email: user.email});
            }

            if (user.savedCards) {
                this.setState({
                    savedCards: user.savedCards,
                    usingSavedSource: true
                });
            }

            const paymentRequest = this.props.createPaymentRequest(!this.state.hasPhone, !this.state.hasEmail);

            this.setState({paymentRequest});

            paymentRequest.on('token', async ({complete, token, ...data}) => {
                
                const { onCreditCardPayment } = this.props;

                this.blurAllFields();

                if (data.payerEmail || data.payerPhone) {
                    const users = await this.props.checkExistingUser(data.payerEmail, data.payerPhone);

                    if (users.length > 0) {
                        if (!this.state.hasEmail) {
                            alert('The specified email address is already in use. Please specify another email.');
                            return false;
                        }
                        if (!this.state.hasPhone) {
                            alert('The specified phone number is already in use. Please specify another phone number.');
                            return false;
                        }
                    }
                }

                if (!this.state.hasPhone) {
                    this.setState({phoneNumber: data.payerPhone});
                }
    
                if (!this.state.hasEmail) {
                    this.setState({email: data.payerEmail});
                }

                onCreditCardPayment({
                    cardToken: token,
                    onComplete: complete,
                    name: data.payerName,
                    email: this.state.email,
                    phone: this.state.phoneNumber,
                    couponId: this.state.addedCoupon ? this.state.addedCoupon.id : null
                });
            });

            paymentRequest.canMakePayment().then((result) => {
                this.setState({applePayButtonVisible: !!result});
            });

        } catch(err) {
            console.log(err);
        }
    };

    handleCreditCardPayment = async (ev) => {
        ev.preventDefault();

        const { onCreditCardPayment, onShowModal } = this.props;

        if (!this.state.hasEmail && this.state.email.trim() === "") {
            this.setState({ phoneEmailError: "Email is required"});
            return;
        } else {
            this.setState({ phoneEmailError: null});
        }

        if (!this.state.hasPhone && this.state.phoneNumber.trim() === "") {
            this.setState({ phoneEmailError: "Phone is required"});
            return;
        } else {
            this.setState({ phoneEmailError: null});
        }

        if (this.state.usingSavedSource) {
            if (!this.state.selectedSavedSource) {
                this.setState({ cardError: "Select a payment source or enter a card" });
                return;
            } else {
                this.setState({ cardError: null });
            }
            await onCreditCardPayment({
                cardToken: { id: this.state.selectedSavedSource.id },
                usingSavedSource: true,
                couponId: this.state.addedCoupon ? this.state.addedCoupon.id : null,
                email: this.state.email,
                phone: this.state.phoneNumber
            });
            return;
        }

        if (this.state.nameOnCard.trim() === "") {
            this.setState({ nameError: "Name on card is required."});
            return false;
        } else {
            this.setState({ nameError: null});
        }

        this.blurAllFields();

        if (this.state.coupon != '' && this.state.addedCoupon == null) {
            onShowModal();
            return;
        }

        const users = await this.props.checkExistingUser(this.state.email, this.state.phoneNumber);

        if (users.length > 0) {
            if (!this.state.hasEmail) {
                alert('The specified email address is already in use. Please specify another email.');
                return false;
            }
            if (!this.state.hasPhone) {
                alert('The specified phone number is already in use. Please specify another phone number.');
                return false;
            }
        }

        const { token, error } = await this.props.stripe.createToken({
            type: 'card',
            name: this.state.nameOnCard
        });

        if (error) {
            this.setState({ cardError: error.message });
            return false;
        }
        else if (!token) {
            this.setState({ cardError: "Could not process payment, please verify card information is correct" });
            return false;
        } else {
            this.setState({ cardError: null });
        }

        await onCreditCardPayment({
            cardToken: token,
            name: this.state.nameOnCard,
            email: this.state.email,
            phone: this.state.phoneNumber,
            couponId: this.state.addedCoupon ? this.state.addedCoupon.id : null,
            saveSource: this.state.saveSource
        });

    };

    handleApplePayPress = (token, callback) => {
        const { onApplePayPayment } = this.props;

        onApplePayPayment({
            token,
            callback,
            couponId: this.state.addedCoupon ? this.state.addedCoupon.id : null
        });
    };

    handleCouponAdd = async () => {
        const { onCouponAdd } = this.props;
        
        const coupon = await onCouponAdd(this.state.coupon);
        if (coupon.error) {
            this.setState({invalidCoupon: true});
        } else {
            this.setState({invalidCoupon: false});
            this.setState({addedCoupon: coupon.result});
        }
    };

    blurAllFields = () => {
        blurAllFields();
    };

    onRemoveCoupon = () => {
        this.setState({addedCoupon: null, coupon: ''});
    }

    onCloseError = () => {
        this.setState({invalidCoupon: false});
    }

    render() {
        const {
            estimatedCost,
            goBack
        } = this.props;

        const {
            nameOnCard,
            applePayButtonVisible,
            phoneNumber,
            email,
            hasPhone,
            hasEmail,
            coupon,
            addedCoupon,
            invalidCoupon,
            showModal
        } = this.state;

        let discount = 0;
        if (addedCoupon != null) {
            discount = parseFloat(addedCoupon.type == "$" ? addedCoupon.value : estimatedCost * (addedCoupon.value / 100).toFixed(2));
            if (discount > estimatedCost) {
                discount = estimatedCost;
            }
        }

        const { savedCards, selectedSavedSource, phoneEmailError } = this.state;

        return (
            <View style={styles.container}>
                <NavigationHeader 
                    goBack={goBack} 
                    titleText={'Checkout'}
                    style={{backgroundColor: colors.creamWhite}}
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <Text>
                            <Text style={[styles.bold, styles.disclaimer]}>Payment is not processed until your delivery is completed.</Text>{' '}
                            <Text style={styles.disclaimer}>
                                When submitting your request, a pre-authorization will be placed on the payment method used, to verify.
                                You may see a pending charge, depending on your bank.
                                Once your delivery is completed, your payment method on file will be charged for delivery.
                            </Text>
                        </Text>
                        <View style={styles.hr} />
                        <Spacer size={20} />
                        {applePayButtonVisible &&
                            <View style={styles.paymentRequestContainer}>
                                <PaymentRequestButtonElement
                                    paymentRequest={this.state.paymentRequest}
                                />
                                <View style={styles.hr} />
                                <Spacer size={10} />
                            </View>
                        }
                        {!!phoneEmailError && (
                            <Text style={{color: 'red', paddingTop: '10px'}}>
                                {phoneEmailError}
                            </Text>
                        )}
                        {!hasPhone &&
                            <Input
                                ref={'phoneNumber'}
                                type={'primary'}
                                placeholderTextColor={'dark'}
                                placeholder={'Mobile Phone'}
                                keyboardType={'phone-pad'}
                                style={styles.input}
                                maskType={Input.maskTypes.phoneNumber}
                                onChangeText={text =>
                                    this.setState({ phoneNumber: text })}
                                value={phoneNumber}
                            />
                        }
                        {!hasPhone && <Spacer size={20} />}
                        {!hasEmail && 
                            <Input
                                ref={'email'}
                                type={'primary'}
                                placeholderTextColor={'dark'}
                                keyboardType={'email-address'}
                                placeholder={'Email'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                style={styles.input}
                                onChangeText={text => this.setState({ email: text })}
                                value={email}
                            />
                        }
                        <Text style={styles.paymentInfoText}>Payment Information</Text>
                        {this.state.usingSavedSource ?
                            <View style={{width: '100%'}}>
                                {!!this.state.cardError && (
                                    <Text style={styles.error}>
                                        {this.state.cardError}
                                    </Text>
                                )}
                                {savedCards.map(savedCard => (
                                    <View>
                                        <View
                                            key={savedCard.id}
                                            style={{ flexDirection: 'row', borderStyle: 'dashed', borderWidth: 1, padding: 10 }}
                                        >
                                            <View style={{ flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                <Switch
                                                    onValueChange={(newSwitchSetting) => this.setState({selectedSavedSource: newSwitchSetting ? savedCard : null})}
                                                    value={selectedSavedSource && savedCard.id === selectedSavedSource.id}
                                                />
                                            </View>
                                            <View style={{ flex: 10, paddingLeft: 10 }}>
                                                <Text>
                                                    <Text style={{fontWeight: 'bold'}}>Cardholder: </Text>
                                                    {savedCard.name}
                                                </Text>
                                                <Spacer size={10} />
                                                <View style={styles.savedPaymentInfoContainer}>
                                                    <Text>{savedCard.brand}</Text>
                                                    <Text>****{savedCard.last4}</Text>
                                                    <Text>Exp: {savedCard.exp_month}/{savedCard.exp_year}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <Spacer size={10} />
                                    </View>
                                ))}
                                <Button onPress={() => this.setState({ usingSavedSource: false, selectedSavedSource: null })}
                                    style={styles.couponButton}
                                    color={colors.darkGrey2}
                                    height={44}
                                >
                                    Use a different card
                                </Button>

                                <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
                                    <View style={{ flexDirection: 'row', flex: 1 }} />
                                    {addedCoupon == null ?
                                        <View style={styles.estimatedCostContainer}>
                                            <Text style={styles.estimatedCostHeader}>Est. Total</Text>
                                            <Text style={styles.estimatedCostAmount}>${parseFloat(estimatedCost).toFixed(2)}</Text>

                                        </View>
                                    :
                                        <View>
                                            <View style={styles.couponAddedEstimatedCostContainer}>
                                                <Text style={styles.couponAddedHeader}>Estimated Cost:</Text>
                                                <Text style={styles.couponAddedValue}>${parseFloat(estimatedCost).toFixed(2)}</Text>
                                            </View>
                                            <View style={styles.couponAddedEstimatedCostContainer}>
                                                <Text style={styles.couponAddedHeader}>{addedCoupon.name} - {addedCoupon.type == "$" ? "$" : ""}{addedCoupon.value}{addedCoupon.type == "%" ? "%" : ""} Off Coupon:</Text>
                                                <Text style={styles.couponAddedValue}>-${parseFloat(discount).toFixed(2)}</Text>
                                            </View>
                                            <View style={styles.couponAddedEstimatedCostContainer}>
                                                <Text style={styles.couponAddedHeader}>New Estimate:</Text>
                                                <Text style={styles.couponAddedValue}>${parseFloat(Math.abs(estimatedCost - discount)).toFixed(2)}</Text>
                                            </View>
                                        </View>
                                    }
                                </View>
                            </View>
                            :
                            <View style={{width: '100%'}}>
                                {(savedCards && savedCards.length) &&
                                    <View>
                                        <Button onPress={() => this.setState({ usingSavedSource: true })}
                                            style={styles.couponButton}
                                            color={colors.darkGrey2}
                                            height={44}
                                        >
                                            Use a saved payment source
                                        </Button>
                                        <Spacer size={20} />
                                    </View>
                                }
                                <Input
                                    ref={'nameOnCard'}
                                    type={'primary'}
                                    placeholderTextColor={'dark'}
                                    placeholder={'Name on card'}
                                    autoCapitalize={'words'}
                                    autoCorrect={false}
                                    style={styles.input}
                                    onChangeText={text => this.setState({ nameOnCard: text })}
                                    value={nameOnCard}
                                />
                                {!!this.state.nameError && (
                                    <Text style={{color: 'red', paddingTop: '10px'}}>
                                        {this.state.nameError}
                                    </Text>
                                )}
                                <Spacer size={20} />
                                {!!this.state.cardError && (
                                    <Text style={styles.error}>
                                        {this.state.cardError}
                                    </Text>
                                )}
                                <View style={styles.stripeContainer}>
                                    <CardElement 
                                        classes={{base: 'stripe-input'}} 
                                        style={{
                                            base: {
                                                fontSize: '16px',
                                                fontFamily: '"SctoGroteskA-Regular", sans-serif',
                                                color: colors.darkGrey2,
                                                backgroundColor: colors.white,
                                                height: 44
                                            }
                                        }} 
                                    />
                                </View>
                                <Spacer size={20} />
                                <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                        <Text style={{paddingRight: 10}}>Save this card</Text>
                                        <Switch
                                            onValueChange={() => this.setState({ saveSource: !this.state.saveSource })}
                                            value={this.state.saveSource}
                                        />
                                    </View>
                                    {addedCoupon == null ?
                                        <View style={styles.estimatedCostContainer}>
                                            <Text style={styles.estimatedCostHeader}>Est. Total</Text>
                                            <Text style={styles.estimatedCostAmount}>${parseFloat(estimatedCost).toFixed(2)}</Text>

                                        </View>
                                    :
                                        <View>
                                            <View style={styles.couponAddedEstimatedCostContainer}>
                                                <Text style={styles.couponAddedHeader}>Estimated Cost:</Text>
                                                <Text style={styles.couponAddedValue}>${parseFloat(estimatedCost).toFixed(2)}</Text>
                                            </View>
                                            <View style={styles.couponAddedEstimatedCostContainer}>
                                                <Text style={styles.couponAddedHeader}>{addedCoupon.name} - {addedCoupon.type == "$" ? "$" : ""}{addedCoupon.value}{addedCoupon.type == "%" ? "%" : ""} Off Coupon:</Text>
                                                <Text style={styles.couponAddedValue}>-${parseFloat(discount).toFixed(2)}</Text>
                                            </View>
                                            <View style={styles.couponAddedEstimatedCostContainer}>
                                                <Text style={styles.couponAddedHeader}>New Estimate:</Text>
                                                <Text style={styles.couponAddedValue}>${parseFloat(Math.abs(estimatedCost - discount)).toFixed(2)}</Text>
                                            </View>
                                        </View>
                                    }
                                </View>
                            </View>
                        }
                        <Spacer size={20} />
                        {addedCoupon == null ? 
                            <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <View style={{flex: 1}}>
                                    {invalidCoupon && (
                                        <Text style={styles.error}>
                                            Invalid Coupon
                                        </Text>
                                    )}
                                    <Input
                                        ref={'coupon'}
                                        type={'primary'}
                                        placeholderTextColor={'dark'}
                                        placeholder={'Coupon Code'}
                                        autoCorrect={false}
                                        style={styles.couponInput}
                                        onChangeText={(text) => this.setState({ coupon: text.toUpperCase() })}
                                        value={coupon}
                                    />
                                </View>
                                <View style={{width: 120, flexDirection: 'row', alignItems: 'flex-end'}}>
                                    <Button onPress={this.handleCouponAdd} 
                                            style={styles.couponButton}
                                            color={colors.darkGrey2}
                                            height={44}
                                    >
                                        APPLY
                                    </Button>
                                </View>
                            </View>
                            : 
                            <View style={{display: 'flex', flexDirection: 'column', height: 30, width: '100%', alignItems: 'flex-end'}}>
                                <View style={{alignItems: 'center'}}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row' }} onPress={this.onRemoveCoupon}>
                                        <Image
                                            source={{ uri: closeIcon }}
                                            style={{ width: 20, height: 20, flex: 1, marginRight: 10 }}
                                        />
                                        <Text style={styles.changeCoupon}>Change Coupon</Text>
                                    </TouchableOpacity>
                                </View>
                            </View> 
                        }
                    </View>
                    <Spacer size={10} />
                    <Button
                        onPress={this.handleCreditCardPayment}
                        style={styles.submitButton}
                    >
                        Submit
                    </Button>
                </ScrollView>
                {showModal ? 
                    <InfoModal
                        title={'Are you sure?'}
                        body={
                            'If you pay before hitting "Use Coupon", your coupon will not be used'
                        }
                    />
                : null}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: colors.white,
    },
    stripeContainer: {
        backgroundColor: colors.white,
        height: 40
    },
    input: {
        fontSize: 16,
        flex: 1,
        maxHeight: 44,
        width: '100%'
    },
    infoButton: {
        marginRight: 15,
    },
    paymentInfoText: {
        fontSize: 18,
        textAlign: 'center',
        marginTop: 15,
        marginBottom: 15,
        fontFamily: 'Recoleta-Semibold'
    },
    paymentRequestContainer: {
        width: '100%'
    },
    couponInput: {
        flex: 1,
        marginRight: 20
    },
    couponButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        borderRadius: 30,
        fontSize: 18,
        height: 44
    },
    errorHeader: {
        fontSize: 18,
        flex: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        marginLeft: 20
    },
    errorCancelButton: {
        flex: 1,
        alignItems: 'flex-end'
    },
    estimatedCostContainer: {
        display: 'block'
    },
    estimatedCostHeader: {
        color: colors.darkGrey2, 
        fontSize: 18,
        marginRight: 20
    },
    estimatedCostAmount: {
        flex: 1,
        color: colors.darkGrey2, 
        fontSize: 18
    },
    couponAddedEstimatedCostContainer: {
        flexDirection: 'row',
        marginBottom: 5
    },
    couponAddedHeader: {
        flex: 3,
        textAlign: 'right',
        marginRight: 20
    },
    couponAddedValue: {
        textAlign: 'right',
        fontSize: 16
    },
    changeCoupon: {
        color: '#e67859'
    },
    savedPaymentInfoContainer: {
        justifyContent: 'space-between', 
        flexDirection: 'row',
        maxWidth: 300
    },
    submitButton: {
        backgroundColor: colors.green,
        borderRadius: 30,
        margin: 20,
    },
    body: {
        width: `100%`,
        paddingTop: 10,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1
    },
    titleText: {
        fontSize: 26,
        textAlign: 'center',
        marginLeft: 30,
        marginRight: 30
    },
    titleSubText: {
        fontSize: 18,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    container: {
        flex: 1,
        backgroundColor: colors.creamWhite,
    },
    error: {
        color: colors.red,
        marginRight: 20,
        marginBottom: 5,
        fontSize: 16
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        width: '100%',
        marginTop: 20
    },
    disclaimer: {
        color: colors.midGrey4
    },
    bold: {
        fontFamily: 'SctoGroteskA-Medium'
    }
});

export default injectStripe(PaymentScreen);