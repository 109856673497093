import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet, TouchableOpacity, Switch, TextInput} from 'react-native';

import {Text, Spacer, Button} from '../components';
import {colors} from '../constants/theme';

export default class DeliveryTipModal extends Component {
    static propTypes = {
        onAddTip: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            tipValue: 0,
            customTipValue: 0,
            isCustomValue: false,
            error: ''
        };
    }

    setValue = (value) => {
        this.setState({ error: '', tipValue: value });
    };

    setIsCustomValue = (isCustomValue) => {
        this.setState({ error: '', tipValue: 0, customTipValue: 0, isCustomValue });
    }

    setCustomTipValue = (value) => {
        const numericOnly = Number(value.replace(/\D/, ''));
        this.setState({ error: '', customTipValue: numericOnly });
    }

    setError = (error) => this.setState({ error });

    getTipButtonStyle = (buttonAmount) => {
        const { isCustomValue, tipValue } = this.state;
        if (isCustomValue) return [styles.tipButton, styles.tipButtonDisabled];
        if (tipValue === buttonAmount) return [styles.tipButton, styles.tipButtonSelected];
        return styles.tipButton;
    }

    getTipTextStyle = (buttonAmount) => {
        const { isCustomValue, tipValue } = this.state;
        if (isCustomValue) return [styles.buttonText, styles.textDisabled];
        if (tipValue === buttonAmount) return [styles.buttonText, styles.textSelected];
        return styles.buttonText;
    }

    submitTip = () => {
        const { isCustomValue, tipValue, customTipValue } = this.state;
        if (isCustomValue) {
            if (customTipValue === 0) {
                return this.setError('Tip value cannot be 0. To exit this popup, click the X in the upper right');
            }
            if (customTipValue < 0) {
                return this.setError('Tip value cannot be negative');
            }
            if (customTipValue > 300) { // Arbitrary amount to prevent accidentally adding too much, or fraudulent activity
                return this.setError('Tip value exceeds the maximum');
            }
            this.props.onAddTip(customTipValue);
        } else {
            if (tipValue === 0) {
                return this.setError('Tip value cannot be 0. To exit this popup, click the X in the upper right');
            }
            this.props.onAddTip(tipValue);
        }
    }

    render() {
        const { error, isCustomValue, customTipValue, tipValue } = this.state;
        return (
            <View style={styles.container}>
                <View style={styles.innerContainer}>
                    <View style={styles.contentContainer}>
                        <Text style={styles.titleText}>
                            Add a Tip
                        </Text>
                        <Spacer size={5} />
                        <Text style={styles.contentText}>
                            100% of the tip goes to the Driver
                        </Text>
                        <Spacer size={30} />
                        {!!error && (
                            <Text style={styles.error}>{error}</Text>
                        )}
                        <View style={styles.tipButtonContainer}>
                            <TouchableOpacity
                                disabled={isCustomValue}
                                style={this.getTipButtonStyle(5)}
                                onPress={() => this.setValue(5)}>
                                <Text
                                    style={this.getTipTextStyle(5)}>
                                    $5
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                disabled={isCustomValue}
                                style={this.getTipButtonStyle(10)}
                                onPress={() => this.setValue(10)}>
                                <Text
                                    style={this.getTipTextStyle(10)}>
                                    $10
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                disabled={isCustomValue}
                                style={this.getTipButtonStyle(20)}
                                onPress={() => this.setValue(20)}>
                                <Text
                                    style={this.getTipTextStyle(20)}>
                                    $20
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                disabled={isCustomValue}
                                style={this.getTipButtonStyle(25)}
                                onPress={() => this.setValue(25)}>
                                <Text
                                    style={this.getTipTextStyle(25)}>
                                    $25
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.customTipContainer}>
                            <Switch
                                onValueChange={(bool) => this.setIsCustomValue(bool)}
                                value={isCustomValue}
                            />
                            <Text style={[styles.contentText, isCustomValue ? {} : styles.greyText]}> Custom Amount: $</Text>
                            <TextInput
                                style={[styles.customTipInput, isCustomValue ? {} : { borderColor: colors.midGrey, color: colors.midGrey } ]}
                                disabled={!isCustomValue}
                                value={customTipValue}
                                onChangeText={value => this.setCustomTipValue(value)}
                                maxLength={3}
                            />
                            <Text style={[styles.contentText, isCustomValue ? {} : styles.greyText]}>.00</Text>
                        </View>
                    </View>
                    <View style={styles.submitButtonContainer}>
                        <Button onPress={() => this.submitTip()}
                                style={styles.circleButton}
                                color={colors.white}
                                height={48}
                        >
                            Submit
                        </Button>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    innerContainer: {
        width: '100%',
        backgroundColor: colors.white,
        padding: 20
    },
    contentContainer: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    titleText: {
        fontFamily: 'Recoleta-Semibold',
        fontSize: 21,
        color: colors.darkGrey2,
    },
    contentText: {
        fontSize: 18,
        color: colors.darkGrey2,
        textAlign: 'center',
    },
    greyText: {
        color: colors.darkGrey2,
    },
    tipButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    tipButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey,
        paddingHorizontal: 10,
        paddingVertical: 13,
        cursor: 'pointer',
        borderRadius: 7,
        width: 60
    },
    tipButtonSelected: {
        backgroundColor: colors.darkGrey
    },
    tipButtonDisabled: {
        borderColor: colors.midGrey
    },
    customTipContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 15
    },
    customTipInput: {
        width: 26,
        paddingVertical: 5,
        paddingHorizontal: 8,
        marginHorizontal: 2,
        boxSizing: 'content-box',
        borderWidth: 1,
        borderRadius: 7,
        borderStyle: 'solid',
        borderColor: colors.darkGrey,
    },
    textSelected: {
        color: colors.white,
        fontSize: 21
    },
    textDisabled: {
        color: colors.darkGrey2,
        fontSize: 21
    },
    submitButtonContainer: {
        width: '100%',
        marginTop: 30,
        marginBottom: 15
    },
    error: {
        color: colors.red,
        marginBottom: 10,
        fontSize: 16,
        textAlign: 'center'
    },
    buttonText: {
        color: colors.darkGrey2,
        fontSize: 21,
        textAlign: 'center'
    },
    circleButton: {
        backgroundColor: colors.green,
        borderWidth: 1,
        borderRadius: 30,
        height: 48
    },
});