import lib from '../lib';
import adapters from '../adapters';
import presenters from '../presenters';
import attachResponseInterceptor from './helpers/response-interceptor';

import { create } from 'axios';

export default ({ routerContainer, routerProps }) => {
    const routerAdapter = new adapters.Router.reactNavigation(routerProps);


    const userId = window.localStorage.getItem('@joey-consumer-user-id');

    const api = composeAxios(process.env.API_BASE);

    const stripe = Stripe(process.env.STRIPE_API_KEY);

    const libs = {
        router: new lib.Router(routerAdapter, routerContainer),

        user: new lib.User({
            store: new adapters.User.api(api, process.env.MAGIC_URL),
            storage: new adapters.Storage.localStorage(window.localStorage),
        }),

        driveBuilder: new lib.DriveBuilder({
            presenters: {
                allPhotos: presenters.photos.all(
                    process.env.API_BASE,
                ),
            },
        }),

        drive: new lib.Drive({
            store: new adapters.Drive.api(api, userId),
            presenters: {
                all: presenters.drives.all,
                details: presenters.drives.details(
                    process.env.API_BASE,
                ),
            },
        }),

        upload: new lib.Upload({ store: new adapters.Upload.api(api) }),

        truck: new lib.Truck({
            store: new adapters.Truck.api(api),
            presenters: {
                all: presenters.trucks.all(process.env.API_BASE),
            },
        }),

        payment: new lib.Payment({
            gateway: new adapters.Payment.stripe({ stripe }),
        }),

        coupon: new lib.Coupon({
            store: new adapters.Coupon.api(api)
        }),

        city: new lib.City({
            store: new adapters.City.api(api)
        }),

        addOn: new lib.AddOn({
            store: new adapters.AddOn.api(api)
        }),

        business: new lib.Business({
            store: new adapters.Business.api(api)
        }),
    };

    Object.keys(libs).forEach(name => libs[name].initLib(libs));

    attachResponseInterceptor(api, libs);

    return libs;
};

function composeAxios(baseURL) {
    const api = create({
        baseURL,
        withCredentials: true,
    });

    api.interceptors.request.use(
        async config => {
            const authToken = getAuthToken();
            if (authToken && !config.url.endsWith("refresh-token")) {
                config.headers['Authorization'] = 'Bearer ' + authToken;
            }

            return config;
        },
        async error => ({ error })
    );

    return api;
}
