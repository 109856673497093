import React from 'react';

import { InfoModal } from '../modals';

export default () => (
    <InfoModal
        title={'Email'}
        body={
            'Joey requires your email so that we can send you a receipt of ' +
                'your payment. Drivers will not have your email address.'
        }
    />
);
