import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, View, Dimensions } from 'react-native';

import {
    Button,
    Input,
    Spacer,
    LargeLogo
} from '../components';

import { colors } from '../constants/theme';
import PrivacyPolicyTerms from '../components/privacy-policy-terms';

export default class Register extends Component {
    static propTypes = {
        onContinue: PropTypes.func.isRequired,
        phone: PropTypes.string
    };

    state = {
        email: null,
        phoneNumber: null,
    };

    handleContinue = () => {
        const { email, phoneNumber } = this.state;

        this.props.onContinue(email, phoneNumber);
    };

    componentDidMount() {
        const { phone } = this.props;
        if (phone) {
            let newPhone = phone;
            newPhone = newPhone.replace('+', '');
            this.setState({phoneNumber: newPhone});
        }
    }

    render() {
        const { email, phoneNumber } = this.state;
        const screenWidth = Dimensions.get('window').width;

        return (
            <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                <View style={styles.body}>
                    <LargeLogo />
                    <View style={{ padding: 45 }} />
                    {screenWidth <= 761 ?
                        <Input
                            style={{textAlign: 'center'}}
                            ref={'phoneNumber'}
                            type={'secondary'}
                            placeholder={'Mobile Phone'}
                            keyboardType={'phone-pad'}
                            onSubmitEditing={this.handleContinue}
                            onChangeText={text => this.setState({ phoneNumber: text })}
                            value={phoneNumber}
                            maskType={Input.maskTypes.phoneNumber}
                        /> 
                        :
                        <Input
                            style={{textAlign: 'center'}}
                            ref={'email'}
                            type={'secondary'}
                            keyboardType={'email-address'}
                            placeholder={'Email'}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            onSubmitEditing={this.handleContinue}
                            onChangeText={text => this.setState({ email: text })}
                            value={email}
                        />
                    }
                    <Spacer size={20} />
                    <PrivacyPolicyTerms />
                </View>
                <View style={styles.footer}>
                    <Button
                        onPress={this.handleContinue}
                        style={styles.circleButton}
                    >
                        Continue
                    </Button>
                </View>
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    body: {
        backgroundColor: colors.green,
        padding: 20,
        flex: 1, 
        justifyContent: 'flex-start'
    },
    footer: {
        padding: 20,
        backgroundColor: colors.green,
        justifyContent: 'flex-end'
    },
    circleButton: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 30
    }
});
