import { toClientEntity, toServerEntity } from '../../helpers';

export default class CouponApiAdapter {
    constructor(api) {
        this._api = api;
    }

    async checkIfCouponIsValid(name, originCityId) {
        let coupon = await _resolve(this._api.post(`/coupons/filter`, {name}));

        if (coupon.error) {
            return {
                error: coupon.error.message,
            };
        }

        coupon = coupon.result;

        if (coupon.length < 1) {
            return {
                error: 'Coupon not found.'
            };
        }

        coupon = coupon.filter(c => c.cities.includes(originCityId));

        if (!coupon.length) return errorObject('Coupon is not valid in this region');

        coupon = coupon[0];

        return {
            result: toClientEntity.Coupon(coupon)
        };
    }
}