import React from 'react';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'

import {
    StyleSheet,
    View,
    ScrollView,
    Image,
} from 'react-native';

import {
    Text,
    Button,
    DeliveryAddressRow,
    DeliveryCargoRow,
    NavigationHeader,
    Spacer,
} from '../components';

import { colors } from '../constants/theme';

import AddOn from '../../lib/add-on';
import moment from 'moment';

class DeliveryDetailComplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            canTip: moment().isBefore(moment(props.drive.tipEndTime)),
        }
    }

    componentDidMount() {
        const { drive } = this.props;

        this.checkCanTipInterval = setInterval(() => {
            this.setState({ canTip: moment().isBefore(moment(drive.tipEndTime)) });
        }, 5 * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.checkCanTipInterval);
    }

    render() {
        const {
            summaryData,
            cargoData,
            deliveryData,
            addOns,
            showPhotoViewer,
            onRating,
            starRating,
            driverData,
            onAddTip,
            tip,
            photo,
            id,
            goBack,
            consumerPrice,
            drive,
        } = this.props;
        
        const { canTip } = this.state;
        const { title, pickupTime, endTime, truckName } = summaryData;
        const { description: cargoDescription } = cargoData;
        const { origin, destination } = deliveryData;
        const {firstname, lastname, profilePhoto } = driverData;
        const name = `${firstname} ${lastname.length > 0 ? lastname[0] + '.' : ""}`;
        const enumeratedAddOns = AddOn.enumerate(addOns, '\n');
    
        const pickup = moment(pickupTime);
        const end = moment(endTime);
        const hours = end.diff(pickup, 'hours');
        const minutes = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(pickupTime, "HH:mm:ss"))).format("mm")
        const duration = `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min`;

    
        return (
            <View style={styles.container}>
                <NavigationHeader 
                    titleText={'Delivery Details'}
                    style={{backgroundColor: colors.creamWhite}}
                    goBack={goBack}
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <View style={styles.feedbackContainer}>
                            <Text style={styles.feedback}>Feedback</Text>
                            <Image
                                source={{ uri: profilePhoto }}
                                style={{ width: 80, height: 80, borderRadius: 40 }}
                            />
                            <Text style={styles.name}>{name}</Text>
                            <Text style={styles.feedbackDetails}>Your feedback will help improve our delivery experience.</Text>

                            <Rater total={5} rating={starRating || 0} onRate={({rating}) => onRating(rating)}/>

                            <Spacer size={20} />

                            { tip ? 
                                <Text style={{color: colors.darkGrey}}>You tipped ${tip/100}</Text>
                            : null }
                            { !tip && canTip &&
                                <Button onPress={onAddTip} 
                                        style={styles.addTip}
                                        color={colors.darkGrey2}
                                        height={36}
                                        fontSize={14}
                                >
                                    Add a Tip
                                </Button>
                            }
                            <Text style={styles.date}>{title}</Text>
                        </View>
                        <View style={styles.whiteTab}>
                            <Text style={styles.whiteTabTitle}>Summary</Text>
                            <View style={styles.row}>
                                <Text style={styles.rowDetailsStart}>Service</Text>
                                <Text style={styles.rowDetailsEnd}>{truckName}</Text>
                            </View>
                            <View style={styles.hr} />
                            <View style={styles.row}>
                                <Text style={styles.rowDetailsStart}>Additional</Text>
                                <Text style={styles.rowDetailsEnd}>{enumeratedAddOns ? enumeratedAddOns + '\n' : null}</Text>
                            </View>
                            <View style={styles.hr} />
                            <View style={styles.row}>
                                <Text style={styles.rowDetailsStart}>Duration</Text>
                                <Text style={styles.rowDetailsEnd}>{duration}</Text>
                            </View>
                            <View style={styles.hr} />
                            <View style={styles.row}>
                                <Text style={styles.rowDetailsStart}>Total</Text>
                                <Text style={styles.rowDetailsEnd}>${parseFloat(consumerPrice).toFixed(2)}</Text>
                            </View>
                        </View>
                        <View style={styles.whiteTab}>
                            <Text style={styles.whiteTabTitle}>Item(s)</Text>
                            <DeliveryCargoRow
                                style={{width: '100%'}}
                                description={cargoDescription}
                                showPhotoViewer={showPhotoViewer}
                                photo={photo}
                                photoSize={120}
                            />
                        </View>
                        <View style={styles.whiteTab}>
                            <Text style={styles.whiteTabTitle}>Location</Text>
                            <DeliveryAddressRow 
                                style={{width: '100%'}} 
                                origin={origin} 
                                destination={destination} 
                            />
                        </View>
                        <Text style={styles.supportIdText}>Support ID: {id.slice(-5)}</Text>
                    </View>
                </ScrollView>
            </View>
        );
    }
};

export default DeliveryDetailComplete;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.creamWhite,
    },
    rateDriveButtonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 20
    },
    supportIdText: {
        fontSize: 12,
        color: 'lightslategray',
        textAlign: 'center'
    },
    body: {
        width: `100%`,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1,
        marginBottom: 20,
        marginTop: 15
    },
    feedback: {
        color: colors.darkGrey2,
        fontSize: 21,
        fontFamily: 'Recoleta-Semibold',
        marginBottom: 20
    },
    feedbackDetails: {
        color: "#6D7B6B",
        fontSize: 16,
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 20
    },
    name: {
        fontSize: 14,
        color: colors.darkGrey2,
        marginTop: 20
    },
    feedbackContainer: {
        paddingHorizontal: 22,
        alignItems: 'center',
    },
    addTip: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        borderRadius: 30,
        height: 36,
        width: 130
    },
    date: {
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 10,
        color: colors.darkGrey2
    },
    whiteTab: {
        backgroundColor: colors.white,
        padding: 20,
        width: `100%`,
        alignItems: 'center',
        borderRadius: 4,
        marginVertical: 10
    },
    whiteTabTitle: {
        color: colors.darkGrey2,
        fontSize: 21,
        fontFamily: 'Recoleta-Semibold',
        width: '100%',
        marginBottom: 20
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        marginLeft: 20,
        marginRight: 20,
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: 5,
        paddingBottom: 15
    },
    rowDetailsStart: {
        flex: 1,
        fontSize: 16,
        textAlign: 'start'
    },
    rowDetailsEnd: {
        flex: 1,
        fontSize: 16,
        textAlign: 'end'
    },
});