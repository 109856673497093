import React from 'react';

import { ScrollView, StyleSheet, View, Image } from 'react-native';

import { Button, Text, LargeLogo, OrSpacer } from '../components';

import { colors } from '../constants/theme';

export default ({ onLogin, onNewCustomer }) => (
    <ScrollView style={styles.container}>
        <LargeLogo />
        <View style={{ padding: 45 }} />
        <Button style={styles.circleButton} onPress={onLogin}>
            Login
        </Button>
        <OrSpacer />
        <Button style={styles.circleButton} onPress={onNewCustomer}>
            Proceed as new customer
        </Button>
    </ScrollView>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: colors.green,
    },
    title: {
        color: colors.white,
        alignSelf: 'center',
        marginTop: 75,
        fontSize: 75,
    },
    circleButton: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 30
    }
});
