import sanitize from '../sanitize';

export default data => {
    let {
        id,
        name,
        latitude,
        longitude,
        radius
    } = data;

    return sanitize({
        id,
        name,
        latitude,
        longitude,
        radius
    });
};