import moment from 'moment';
import { buildPhotoURL } from '../../helpers';

const STATUSES = {
    available: 'Ordered',
    accepted: 'Accepted',
    started: 'En Route',
    loading: 'En Route',
    driving: 'En Route',
    delivered: 'Complete',
    refunded: 'Complete',
};

const STATUS_TYPES = {
    available: 'ordered',
    accepted: 'accepted',
    started: 'enRoute',
    loading: 'enRoute',
    driving: 'enRoute',
    delivered: 'complete',
    refunded: 'complete',
};

const getAddress = (address = {}) => {
    let { zip = '', city = '', state = '', street = '' } = address;

    return `${street} ${city} ${state} ${zip}`;
};

const DATE_FORMAT = 'MM/DD/YY';

export default (apiBase) => drive => {
    const {
        id,
        status,
        price,
        consumerCargo = {},
        route = {},
        driver = {},
        truck,
        helpers,
        dolly,
        createdAt,
        startTime,
        pickupTime,
        endTime,
        starRating,
        payment,
        support,
        requesterId,
        coupon,
        addOns,
        consumerPrice,
        couponValue,
        tipEndTime,
    } = drive;

    const authToken = getAuthToken();

    const currentStatus = STATUS_TYPES[status];

    const { description = 'No Description', images = [] } = consumerCargo;

    const { profilePhoto, firstname, lastname } = driver;

    let startDate = moment(createdAt).format(DATE_FORMAT);
    let endDate;

    if (currentStatus === 'ordered') {
        endDate = 'TBD';
    } else if (currentStatus === 'accepted') {
        endDate = moment(createdAt).add(1, 'days').format(DATE_FORMAT);
    } else if (currentStatus === 'enRoute') {
        startDate = moment(startTime).format(DATE_FORMAT);
        endDate = moment(startTime).add(1, 'days').format(DATE_FORMAT);
    } else if (currentStatus === 'complete') {
        startDate = moment(startTime).format(DATE_FORMAT);
        endDate = moment(endTime).format(DATE_FORMAT);
    }

    const { name } = truck;
    const helpersText = `${helpers > 0 ? ` + ${helpers} Helper${helpers > 1 ? 's' : ''}` : ''}`;
    const dollyText = `${dolly ? ' + Dolly' : ''}`;

    const summaryData = {
        title: `${startDate} - ${endDate}`,
        description: `${name}${helpersText}${dollyText}`,
        statusDisplay: STATUSES[status],
        statusType: currentStatus,
        coupon,
        truckName: name,
        startTime,
        pickupTime,
        endTime
    };

    const cargoData = { description };

    const deliveryData = {
        origin: getAddress(route.origin),
        destination: getAddress(route.destination),
    };

    const photoData = images.map(photo => {
        return buildPhotoURL(apiBase, authToken, photo);
    });

    const driverData = {
        profilePhoto: buildPhotoURL(apiBase, authToken, profilePhoto),
        firstname,
        lastname
    };

    const tip = payment ? payment.tip : null;

    const formatDollars = dollarValue => typeof dollarValue === 'number' ? dollarValue.toFixed(2) : dollarValue;

    return {
        id,
        summaryData,
        cargoData,
        deliveryData,
        photoData,
        price: formatDollars(price),
        starRating,
        driverData,
        tip,
        support,
        requesterId,
        route,
        addOns,
        consumerPrice: formatDollars(typeof consumerPrice === 'number' ? consumerPrice : price),
        couponValue: formatDollars(couponValue),
        tipEndTime,
    };
};
