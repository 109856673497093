import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ListView,
    StyleSheet,
    View,
    Image,
    ScrollView
} from 'react-native';

import { 
    Text, 
    NavigationHeader, 
    Spacer,
    Button
} from '../components';

import { colors } from '../constants/theme';
import clock from '../assets/clock@3x.png';
import _ from 'lodash';

const ds = new ListView.DataSource({
    rowHasChanged: (r1, r2) => r1 !== r2,
});

export default class ServicesScreen extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        fetchTrucks: PropTypes.func.isRequired,
        originCity: PropTypes.array.isRequired,
        goBack: PropTypes.func.isRequired
    };

    constructor() {
        super();

        this.state = {
            dataSource: ds.cloneWithRows([]),
        };
    }

    async componentDidMount() {
        const { fetchTrucks, originCity } = this.props;

        const trucks = await _resolve(fetchTrucks({enabled: true, cities: originCity}));

        if (trucks.error) {
            console.log(trucks.error);
        }

        this.setState({
            dataSource: ds.cloneWithRows(trucks.result),
        });
    }

    renderRow = rowData => {
        const { icon, title, haulSize, basePrice, timeLimit, displayTimePer } = rowData;

        return (
            <View style={styles.rowContainer}>
                <View style={{flex: 1}}>
                    <Text style={styles.rowTitle}>{title}</Text>
                    <Spacer size={5} />
                    <Text style={styles.rowSize}>Haul Size: {haulSize}</Text>
                    <Spacer size={15} />
                    <View style={{flex: 1}}>
                        <Image
                            source={{ uri: icon }}
                            style={{ width: 240, height: 150, resizeMode: "contain", margin: 'auto' }}
                        />
                    </View>
                    <Text style={styles.rowPrice}>${_.round(basePrice)}</Text>
                    <View style={styles.rowPerHour}>
                        <Image 
                            source={{ uri: clock }}
                            style={{ width: 20, height: 20, marginRight: 5 }} />
                        <Text style={styles.perHourText}>per {displayTimePer}</Text>
                    </View>
                    <Spacer size={15} />
                </View>
                <View style={styles.footer}>
                    <Button
                        onPress={() => this.props.onSelect({ truck: rowData })}
                        style={styles.circleButton}
                        color={colors.darkGrey2}
                        height={48}
                    >
                        Select
                    </Button>
                </View>
            </View>
        );
    };

    render() {
        const { goBack } = this.props;
        return (
            <View style={styles.container}>
                <NavigationHeader 
                    goBack={goBack} 
                    titleText={'Select a service'}
                    style={{backgroundColor: colors.creamWhite}}
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <Text style={styles.titleSubText}>
                            Scroll to view/select services
                        </Text>
                        <Spacer size={15} />
                        <ListView
                            style={styles.listContainer}
                            dataSource={this.state.dataSource}
                            renderRow={this.renderRow}
                        />
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    body: {
        width: `100%`,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        flex: 1
    },
    titleText: {
        fontSize: 26,
        textAlign: 'center',
        marginLeft: 30,
        marginRight: 30
    },
    titleSubText: {
        fontSize: 18,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    container: {
        flex: 1,
        backgroundColor: colors.creamWhite,
    },
    rowContainer: {
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 420,
        shadowColor: colors.black,
        shadowOpacity: 0.21,
        shadowRadius: 6,
        elevation: 16,
        paddingTop: 30,
        paddingBottom: 38,
        paddingLeft: 47,
        paddingRight: 47, 
        marginTop: 10,
        marginBottom: 10
    },
    rowTitle: {
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        fontFamily: 'Recoleta-Semibold',
    },
    rowSize: {
        fontSize: 16,
        color: colors.darkGrey2,
        textAlign: 'center',
    },
    rowPrice: {
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        fontFamily: 'Recoleta-Semibold',
    },
    rowPerHour: {
        justifyContent: 'center',
        flexDirection: 'row'
    },
    perHourText: {
        fontSize: 16,
        color: colors.darkGrey2,
        textAlign: 'center',
    },
    rowButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        backgroundColor: colors.white,
        borderRadius: 30
    },
    footer: {
        justifyContent: 'flex-end'
    },
    listContainer: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20
    },
    circleButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        backgroundColor: colors.white,
        borderRadius: 30
    },
});