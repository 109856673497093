import drives from './drives';
import trucks from './trucks';
import photos from './photos';
import coupons from './coupons';

export default {
    drives,
    trucks,
    photos,
    raw: data => data,
    coupons
};
