import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet,
    View,
    ScrollView,
    Image,
    TouchableOpacity,
    Linking
} from 'react-native';

import {
    Text,
    Button,
    Spacer,
    MenuButton,
    NavigationHeader
} from '../components';

import Input from '../components/input';

import { colors } from '../constants/theme';

import trashIcon from '../assets/recycle-bin.png';
import edit from '../assets/edit@3x.png';

import { withNavigation } from 'react-navigation';


const defaultErrors = {
    nameError: null,
    phoneError: null,
    emailError: null,
}

class AccountManagement extends Component {
    static propTypes = {
        getUser: PropTypes.func,
        updateUser: PropTypes.func,
        removeSavedCard: PropTypes.func,
        onToggleMenu: PropTypes.func.isRequired
    }

    constructor() {
        super();
        this.state = {
            editingPersonalInformation: false,
            awaitingUserUpdateRequest: false,
            name: '',
            phone: '',
            email: '',
            savedCards: [],
            ...defaultErrors
        }
    }

    toggleEditingPersonalInformation() {
        this.setState({ editingPersonalInformation: !this.state.editingPersonalInformation })
    }

    setUserState(user) {
        const firstname = user.firstname || '';
        const lastname = user.lastname || '';
        const name = (firstname + ' ' + lastname).trim();
        this.setState({
            name,
            phone: user.phone || '',
            email: user.email || '',
            savedCards: user.savedCards || []
        })
    }

    async getUser() {
        const { result: user, error: getUserError} = await this.props.getUser();

        if (getUserError) {
            console.error(getUserError);
            alert('Could not fetch user details');
        } else {
            this.setUserState(user);
        }
    }

    validateUserUpdate() {
        const { name, phone, email } = this.state;
        const errors = {};

        if (!name.trim()) {
            errors.nameError = 'Name field cannot be empty';
        }
        if (!phone || phone.replace(/\D/g, '').length !== 10) {
            errors.phoneError = 'Please enter your 10-digit phone number';
        }
        if (!email.trim()) { // TODO: May add better validation for this
            errors.emailError = 'Please enter a valid email address';
        }

        this.setState(errors);

        if (Object.keys(errors).length) {
            return false;
        }

        return true;
    }

    setEditingUserInformation() {
        this.setState({ editingPersonalInformation: true });
    }

    async cancelEditingUserInformation() {
        if (this.state.awaitingUserUpdateRequest) return; // The API request has already gone out, can't cancel
        await this.getUser(); // Get the user's information again, so that if they edited fields we're not still showing the edited values after cancel
        this.setState({
            editingPersonalInformation: false,
            ...defaultErrors
        });
    }

    async updateUser() {
        this.setState({ ...defaultErrors});

        const isValid = this.validateUserUpdate();

        if (!isValid) return;

        let { name, phone, email } = this.state;

        name = name.trim();
        phone = phone.trim();
        email = email.trim();

        const splitName = name.split(' ');
        const firstname = splitName[0];
        const lastname = splitName.pop();

        this.setState({ awaitingUserUpdateRequest: true });

        const { result: user, error: updateUserError} = await this.props.updateUser({ firstname, lastname, phone, email });

        if (updateUserError) {
            console.error(updateUserError);
            alert('Could not update user details')
        } else {
            this.setUserState(user);
            this.setState({ editingPersonalInformation: false });
        }

        this.setState({ awaitingUserUpdateRequest: false });
    }

    async removeSavedCard(cardId) {
        const { result: user, error: removeCardError} = await this.props.removeSavedCard(cardId);

        if (removeCardError) {
            console.error(removeCardError);
            alert('Could not remove card');
        } else {
            this.setUserState(user);
        }
    }

    async componentDidMount() {
        await this.getUser();
    }

    

    render() {

        const {
            editingPersonalInformation,
            name,
            nameError,
            phone,
            phoneError,
            email,
            emailError,
            savedCards
        } = this.state;

        const commonStyle = {
            height: 44,
            paddingLeft: 20,
            paddingRight: 20,
            flex: 1
        }

        const textInputProperties = editingPersonalInformation ?
            { style: commonStyle } :
            {
                style: {
                    backgroundColor: colors.creamWhite,
                    color: colors.midGrey4,
                    ...commonStyle
                },
                disabled: true
            };

        const { onToggleMenu } = this.props;

        const inputVisibilityHandler = (text, input) => {
            return editingPersonalInformation ? input : text;
        }

        const formatPhone = () => {
            if (phone !== '') {
                let formattedPhone = phone.replaceAll(/[^0-9]/g, '');
                return `(${formattedPhone.substring(0,3)}) ${formattedPhone.substring(3,6)}-${formattedPhone.substring(6)}`;
            }
            return phone;
        }
        
        return (
            <View style={styles.container}>
                <NavigationHeader 
                    titleText={'Account Info'}
                    style={{backgroundColor: colors.creamWhite}}
                    hamburgerMenu={
                        <MenuButton
                            onPress={onToggleMenu}
                            style={{ marginLeft: 25, marginTop: 8, position: 'absolute' }}
                        />
                    }
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <View style={{width: '100%'}}>
                            <View>
                                <View style={styles.inputRow}>
                                    <Text style={styles.inputTitleText}>Name: </Text>
                                    {inputVisibilityHandler(
                                        <View style={styles.noEditingTextContainer}>
                                            <Text style={styles.notEditingText}>{name}</Text>
                                        </View>,
                                        <Input
                                            ref={'name'}
                                            type={'primary'}
                                            onChangeText={text => this.setState({ name: text })}
                                            value={name}
                                            style={{flex: 1}}
                                            {...textInputProperties}
                                        />
                                    )}
                                </View>
                                {nameError && <Text style={styles.fieldErrorText}>{nameError}</Text>}
                            </View>
                            <Spacer size={10} />
                            <View>
                                <View style={styles.inputRow}>
                                    <Text style={styles.inputTitleText}>Phone No: </Text>
                                    {inputVisibilityHandler(
                                        <View style={styles.noEditingTextContainer}>
                                            <Text style={styles.notEditingText}>{formatPhone()}</Text>
                                        </View>,
                                        <Input
                                            ref={'phone'}
                                            type={'primary'}
                                            keyboardType={'phone-pad'}
                                            maskType={Input.maskTypes.phoneNumber}
                                            onChangeText={text => this.setState({ phone: text })}
                                            value={phone}
                                            style={{flex: 1}}
                                            {...textInputProperties}
                                        />
                                    )}
                                </View>
                                {phoneError && <Text style={styles.fieldErrorText}>{phoneError}</Text>}
                            </View>
                            <Spacer size={10} />
                            <View>
                                <View style={styles.inputRow}>
                                    <Text style={styles.inputTitleText}>Email: </Text>
                                    {inputVisibilityHandler(
                                        <View style={styles.noEditingTextContainer}>
                                            <Text style={styles.notEditingText}>{email}</Text>
                                        </View>,
                                        <Input
                                            ref={'email'}
                                            type={'primary'}
                                            onChangeText={text => this.setState({ email: text })}
                                            value={email}
                                            style={{flex: 1, paddingRight: 0}}
                                            {...textInputProperties}
                                        />
                                    )}
                                </View>
                                {emailError && <Text style={styles.fieldErrorText}>{emailError}</Text>}
                            </View>
                        </View>

                        <Spacer size={20} />
                        
                        {!editingPersonalInformation ?
                            <View style={{width: '100%', alignItems: 'flex-end'}}>
                                <TouchableOpacity
                                    onPress={this.setEditingUserInformation.bind(this)}
                                    style={styles.editButton}
                                    height={44}
                                >
                                    <Image
                                        source={{ uri: edit }}
                                        style={{ width: 17, height: 17, marginRight: 10, marginLeft: 15 }}
                                    />
                                    <Text style={{fontSize: 18, color: colors.darkGrey2, flex: 1}}>EDIT</Text>
                                </TouchableOpacity>
                            </View>
                            :
                            <View style={{width: '100%', flexDirection: 'row'}}>
                                <Button
                                    onPress={this.cancelEditingUserInformation.bind(this)}
                                    style={styles.cancelButton}
                                    height={44}
                                    color={colors.midGrey4}
                                    fontSize={18}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onPress={this.updateUser.bind(this)}
                                    style={styles.saveButton}
                                    height={44}
                                    color={colors.white}
                                    fontSize={18}
                                >
                                    Save
                                </Button>
                            </View>
                        }

                        <View style={styles.hr} />

                        <Spacer size={20} />
                        {(savedCards.length !== 0) && (
                            <View style={{width: '100%'}}>
                                <Text style={styles.sectionTitle}>Saved Payment Methods</Text>
                                <Spacer size={20} />

                                {savedCards.map(savedCard => (
                                    <View key={savedCard.id}>

                                        <View
                                            key={savedCard.id}
                                            style={{ flexDirection: 'row', backgroundColor: colors.white, padding: 15, minHeight: 98, borderRadius: 4 }}
                                        >

                                            <View style={{ flex: 1 }}>
                                                <Text style={{marginBottom: 5}}>
                                                    <Text style={{marginRight: 5}}>Cardholder: </Text>
                                                    {savedCard.name}
                                                </Text>
                                                <View style={styles.savedPaymentInfoContainer}>
                                                    <Text style={{marginTop: 4, marginBottom: 4}}>{savedCard.brand} ****{savedCard.last4}</Text>
                                                    <Text style={{marginTop: 4}}>Exp: {savedCard.exp_month}/{savedCard.exp_year}</Text>
                                                </View>
                                            </View>

                                            <View style={{ height: '100%', alignItems: 'center' }}>
                                                <TouchableOpacity onPress={() => this.removeSavedCard(savedCard.id)}>
                                                    <Image
                                                        source={{ uri: trashIcon }}
                                                        style={styles.trashIcon}
                                                    />
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                        <Spacer size={20} />
                                    </View>
                                ))}
                            </View>
                        )}
                        <View style={styles.hr} />
                        <Text style={styles.deleteText} onPress={()=>this.props.navigation.navigate('DeleteAccount')} >Delete Account</Text>
                    </View>
                </ScrollView>
            </View>
        );
    }
};

export default withNavigation(AccountManagement)

const styles = StyleSheet.create({
    inputRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inputTitleText: {
        fontFamily: 'Recoleta-Semibold',
        fontSize: 21,
        color: colors.darkGrey2,
        width: 115
    },
    input: {
        height: 46,
        paddingLeft: 20
    },
    sectionTitle: {
        fontFamily: 'Recoleta-Semibold',
        fontSize: 21,
        color: colors.darkGrey2,
        textAlign: 'center'
    },
    savedPaymentInfoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    trashIcon: {
        width: 16,
        height: 19
    },
    fieldErrorText: {
        color: colors.red,
        marginHorizontal: 'auto',
        marginTop: 10
    },
    container: {
        flex: 1,
        backgroundColor: colors.creamWhite,
        width: `100%`,
    },
    body: {
        width: `100%`,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1,
    },
    cancelButton: {
        borderWidth: 1,
        borderColor: colors.midGrey3,
        borderRadius: 30,
        fontSize: 18,
        height: 44,
        width: 110,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    editButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        borderRadius: 30,
        fontSize: 18,
        height: 44,
        width: 110,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    saveButton: {
        borderRadius: 30,
        fontSize: 18,
        height: 44,
        width: 110,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.green,
        marginLeft: 'auto'
    },
    notEditingText: {
        color: colors.midGrey4,
        textAlign: 'left',
        fontSize: 16,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        height: 44,
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word'
        
    },
    noEditingTextContainer: {
        paddingLeft: 20,
        height: 44,
        flex: 1
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        width: '100%',
        marginTop: 30
    },
    deleteText:{
        fontSize:12
    }
});