import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, ScrollView } from 'react-native';

import { Text, Spacer, Button, Input } from '../components';

import { colors } from '../constants/theme';
import RadioPillGroup from '../components/radio-pill-group';
import moment from 'moment';

export default class howYouHeardAboutUsModal extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            howYouHeardAboutUs: '',
            other: '',
            error: false
        };
    }
    
    handlehowYouHeardAboutUs = (howYouHeardAboutUs) => {
        this.setState(state => {
            return {
                ...state,
                howYouHeardAboutUs,
                error: false
            };
        });
    }

    handleOtherChange = (text) => {
        this.setState(state => {
            return {
                ...state,
                other: text
            };
        });
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        let {howYouHeardAboutUs, other} = this.state;
        let answer = howYouHeardAboutUs;
        if (this.state.howYouHeardAboutUs === 'other') {
            answer = other;
        }

        if (answer.length === 0) {
            this.setState(state => {
                return {
                    ...state,
                    error: true
                };
            });
        }  else {
            this.setState(state => {
                return {
                    ...state,
                    error: false
                };
            });

            onSubmit({howYouHeardAboutUs: answer, howYouHeardAboutUsTimeStamp: moment().format()});
        }
    }

    render() {
        return (
            <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                <View style={styles.container}>
                    <View style={styles.innerContainer}>
                        <Spacer size={15} />
                        <Text style={styles.titleText}>
                            Please tell us how you heard about Joey
                        </Text>
                        <Spacer size={15} />
                        <Text style={styles.titleSubText}>
                            Please select one
                        </Text>
                        <Spacer size={25} />
                        {this.state.error && 
                            <Text style={styles.error}>Required</Text>
                        }
                        <RadioPillGroup 
                            options={[{
                                label: 'Social Media',
                                value: 'socialMedia'
                            },{
                                label: 'Radio',
                                value: 'radio'
                            },{
                                label: 'Store / Business',
                                value: 'storeOrBusiness'
                            },{
                                label: 'Friend',
                                value: 'friend'
                            },{
                                label: 'Saw Joey Vehicle',
                                value: 'vehicle'
                            },{
                                label: 'Thumbtack',
                                value: 'thumbtack'
                            },{
                                label: 'Yelp',
                                value: 'yelp'
                            },{
                                label: 'Internet Search',
                                value: 'internet'
                            },
                            {
                                label: 'Billboard',
                                value: 'billboard'
                            },
                            {
                                label: 'Local Event',
                                value: 'localEvent'
                            }
                        ]}
                        onClick={this.handlehowYouHeardAboutUs} />
                        <Spacer size={15} />
                        {this.state.howYouHeardAboutUs === 'other' && 
                            <View>
                                {this.state.error && 
                                    <Text style={styles.error}>Required</Text>
                                }
                                <Input
                                    type={'primary'}
                                    placeholder={'Other'}
                                    onChangeText={text => this.handleOtherChange(text)}
                                    value={this.state.other}
                                />
                            </View>
                        }
                    </View>
                    <View style={styles.footer}>
                        <Button
                            onPress={this.handleSubmit}
                            style={styles.circleButton}
                        >
                            Continue
                        </Button>
                    </View>
                </View>
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: colors.creamWhite,
    },
    innerContainer: {
        width: `100%`,
        padding: 20,
        alignItems: 'center',
        flex: 1
    },
    contentContainer: {},
    titleText: {
        fontFamily: 'Recoleta-Semibold',
        fontSize: 26,
        textAlign: 'center'
    },
    titleSubText: {
        fontSize: 18,
        textAlign: 'center'
    },
    contentText: {
        textAlign: 'center',
    },
    error: {
        color: colors.red
    },
    circleButton: {
        backgroundColor: colors.green,
        borderRadius: 30
    },
    body: {
        backgroundColor: colors.green,
        padding: 20,
        flex: 1, 
        justifyContent: 'flex-start'
    },
    footer: {
        padding: 20,
        justifyContent: 'flex-end'
    },
});