import React from 'react';

import { StyleSheet } from 'react-native';
import { Text } from '../components';
import { colors } from '../constants/theme';

export default () => (
    <Text style={styles.spacer}>
        OR
    </Text>
);

const styles = StyleSheet.create({
    spacer: {
        padding: 35,
        fontFamily: 'SctoGroteskA-Regular',
        fontSize: 16,
        textAlign: 'center',
        color: colors.white,
    },
});
