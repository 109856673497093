export default class UploadApiAdapter {
    constructor(api) {
        this._api = api;
    }

    async upload(file) {
        let fd = new FormData();

        fd.append('file', file);

        let upload = await _resolve(
            this._api.post('/uploads', fd, { timeout: 180000 })
        );

        if (upload.error) {
            return {
                error: upload.error.message,
            };
        }

        upload = upload.result.Key;

        return {
            result: upload,
        };
    }

    async fetch(key) {
        let upload = await _resolve(this._api.get('/uploads/' + key));

        if (upload.error) {
            return {
                error: upload.error.message,
            };
        }

        upload = upload.result;

        return {
            result: upload,
        };
    }
}
