import { toClientEntity, toServerEntity } from '../../helpers';

class DriveApiAdapter {
    constructor(api, userId) {
        this._api = api;
        this._userId = userId;
    }

    getByUser = async params => {
        if (!this._userId) {
            // DJ: This happens for newly registered users. It probably shouldn't happen,
            // so this is just treating the symptoms (which are insignificant, just a confusing error in the API logs)
            console.warn('Could not fetch drives, user is not authenticated');
            return { result: [] };
        }
        let drives = await _resolve(
            this._api.get('/drives/' + this._userId + '/consumer', { params })
        );

        if (drives.error) {
            return {
                error: drives.error,
            };
        }

        drives = drives.result;

        drives = drives.map(c => toClientEntity.Drive(c));

        return {
            result: drives,
        };
    };

    async getById(id) {
        let drive = await _resolve(
            this._api.get('/drives/' + id, {
                params: { populate: 'truck,driver,requester' },
            })
        );

        if (drive.error) {
            return {
                error: drive.error.message,
            };
        }

        drive = drive.result;

        drive = toClientEntity.Drive(drive);

        return {
            result: drive,
        };
    }

    async create(data) {
        data = toServerEntity.Drive(data);

        let drive = await _resolve(this._api.post('/drives', data));

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        drive = toClientEntity.Drive(drive);

        return {
            result: drive,
        };
    }

    async update(data) {
        let { id, ...updatedDrive } = data;

        data = toServerEntity.Drive(updatedDrive);

        let drive = await _resolve(this._api.put('/drives/' + id, data));

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        drive = toClientEntity.Drive(drive);

        return {
            result: drive,
        };
    }

    async chargeCreditCard(data) {
        let { id, source, saveSource, usingSavedSource, customer, couponId } = data;

        let drive = await _resolve(
            this._api.post('/drives/' + id + '/charge', {
                source,
                saveSource,
                usingSavedSource,
                customer,
                couponId
            })
        );

        if (drive.error) return errorObject(drive.error);

        drive = drive.result;

        drive = toClientEntity.Drive(drive);

        return resultObject(drive);
    }

    async chargeApplePay(data) {
        let { id, source, couponId } = data;

        let drive = await _resolve(
            this._api.post('/drives/' + id + '/charge', {
                source,
                couponId
            })
        );

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        drive = toClientEntity.Drive(drive);

        return {
            result: drive,
        };
    }
}

export default DriveApiAdapter;
