import React from 'react';

import {
    StyleSheet,
    View,
    ScrollView,
    Image,
    TouchableOpacity,
    Linking,
} from 'react-native';

import {
    Text,
    Button,
    DeliveryAddressRow,
    DeliveryCargoRow,
    NavigationHeader,
    DeliveryRow,
    Spacer
} from '../components';

import { colors } from '../constants/theme';

import phoneIcon from '../assets/green-call@3x.png';

export default ({
    summaryData,
    cargoData,
    deliveryData,
    driverData,
    price,
    showPhotoViewer,
    onCancel,
    photo,
    ext, 
    phone,
    id,
    goBack,
    consumerPrice,
    couponValue,
}) => {
    const { title, description, statusDisplay, statusType, coupon, truckName } = summaryData;
    const { description: cargoDescription } = cargoData;
    const { origin, destination } = deliveryData;
    const {firstname, lastname, profilePhoto } = driverData;
    const name = `${firstname} ${lastname.length > 0 ? lastname[0] + '.' : ""}`;

    return (
        <View style={styles.container}>
            <NavigationHeader 
                titleText={'Delivery Details'}
                style={{backgroundColor: colors.creamWhite}}
                goBack={goBack}
            />
            <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                <View style={styles.body}>
                    <DeliveryRow
                        style={{width: '100%', marginBottom: 20}}
                        title={title}
                        description={description}
                        statusDisplay={statusDisplay}
                        statusType={statusType}
                    />
                    <View style={styles.hr} />
                    <DeliveryAddressRow 
                        style={{marginTop: 20, marginBottom: 20, width: '100%'}} 
                        origin={origin} 
                        destination={destination} 
                    />
                    <View style={styles.hr} />
                    <View style={styles.driverContainer}>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <Image
                                source={{ uri: profilePhoto }}
                                style={{ width: 46, height: 46, borderRadius: 23 }}
                            />
                            <View style={styles.driverInfo}>
                                <Text style={styles.name}>{name}</Text>
                                <Text style={styles.truckName}>{truckName}</Text>
                            </View>
                        </View>
                        <TouchableOpacity
                            onPress={() => Linking.openURL(`tel:${phone},${ext}`)}
                            style={styles.callDriver}
                            height={46}
                        >
                            <Image
                                source={{ uri: phoneIcon }}
                                style={{ width: 17, height: 17, marginRight: 10, marginLeft: 15 }}
                            />
                            <Text style={{fontSize: 14, color: colors.green, flex: 1}}>Call Driver</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.hr} />
                    <DeliveryCargoRow
                        style={{marginTop: 20, marginBottom: 20}} 
                        description={cargoDescription}
                        showPhotoViewer={showPhotoViewer}
                        photo={photo}
                    />
                    <View style={styles.hr} />
                    <View style={styles.row}>
                        <Text style={styles.rowDetailsStart}>Est. Cost</Text>
                        <Text style={styles.rowDetailsEnd}>{`$${price}`}</Text>
                    </View>
                    <View style={styles.hr} />
                    {couponValue > 0 && (
                        <View style={{width: '100%', alignItems: 'center'}}>
                            <View style={styles.row}>
                                <Text style={styles.rowDetailsStart}>Coupon</Text>
                                <Text style={styles.rowDetailsEnd}>{`-$${couponValue}`}</Text>
                            </View>
                            <View style={styles.hr} />
                        </View>
                    )}
                    <View style={styles.row}>
                        <Text style={styles.rowDetailsStart}>Est. Total</Text>
                        <Text style={styles.rowDetailsEnd}>{`$${consumerPrice}`}</Text>
                    </View>
                    <Spacer size={40} />
                    <View style={{width: '100%'}}>
                        <Button onPress={onCancel} 
                                style={styles.cancel}
                                color={colors.darkGrey2}
                                height={36}
                                fontSize={14}
                        >
                            Cancel Order
                        </Button>
                    </View>
                    <Text style={styles.supportIdText}>Support ID: {id.slice(-5)}</Text>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.white,
    },
    supportIdText: {
        fontSize: 12,
        color: 'lightslategray',
        textAlign: 'center',
        marginTop: 10
    },
    body: {
        width: `100%`,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1,
        marginBottom: 20,
        marginTop: 15
    },
    name: {
        fontSize: 14,
        color: colors.darkGrey2,
        marginTop: 20
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        marginLeft: 20,
        marginRight: 20,
        width: '100%'
    },
    callDriver: {
        borderWidth: 1,
        borderColor: colors.green,
        borderRadius: 30,
        height: 46,
        width: 130,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    name: {
        fontSize: 14,
        color: colors.darkGrey2
    },
    truckName: {
        fontSize: 12,
        color: "#657463"
    },
    driverInfo: {
        flex: 1, 
        flexDirection: 'column', 
        marginStart: 10,
        justifyContent: 'center'
    },
    driverContainer: {
        width: '100%', 
        flexDirection: 'row', 
        marginVertical: 20
    },
    cancel: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        borderRadius: 30,
        height: 36,
        width: 130
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10
    },
    rowDetailsStart: {
        flex: 1,
        fontSize: 21,
        textAlign: 'start',
        fontFamily: 'Recoleta-Semibold',
    },
    rowDetailsEnd: {
        flex: 1,
        fontSize: 21,
        textAlign: 'end',
        fontFamily: 'Recoleta-Semibold',
    },
});