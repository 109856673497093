import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ListView,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    View
} from 'react-native';

import { DeliveryRow, MenuButton, NavigationHeader } from '../components';

import { colors } from '../constants/theme';

const ds = new ListView.DataSource({
    rowHasChanged: (r1, r2) => r1 !== r2,
});

export default class MyRequestsScreen extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        onToggleMenu: PropTypes.func.isRequired
    };

    constructor() {
        super();

        const data = [];

        this.state = {
            dataSource: ds.cloneWithRows(data),
        };
    }

    async componentDidMount() {
        const { fetchData } = this.props;

        let data = await _resolve(fetchData());

        if (data.error) {
            console.log(data.error);
        }

        data = data.result;

        this.setState({
            dataSource: ds.cloneWithRows(data),
        });
    }

    renderRow = rowData => {
        const { onSelect } = this.props;
        const { title, description, statusDisplay, statusType } = rowData;

        return (
            <TouchableOpacity onPress={() => onSelect({ drive: rowData })} style={{marginTop: 10}}>
                <DeliveryRow
                    title={title}
                    description={description}
                    statusDisplay={statusDisplay}
                    statusType={statusType}
                />
            </TouchableOpacity>
        );
    };

    render() {
        const { onToggleMenu } = this.props;
        return (
            <View style={styles.container}>
                <NavigationHeader 
                    titleText={'My Requests'}
                    style={{backgroundColor: colors.creamWhite}}
                    hamburgerMenu={
                        <MenuButton
                            onPress={onToggleMenu}
                            style={{ marginLeft: 25, marginTop: 8, position: 'absolute' }}
                        />
                    }
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <ListView
                            style={styles.container}
                            dataSource={this.state.dataSource}
                            renderRow={this.renderRow}
                            renderSeparator={(sId, rId) => (
                                <View style={styles.hr} />
                            )}
                        />
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.creamWhite,
        width: `100%`,
    },
    body: {
        width: `100%`,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1,
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        marginTop: 10
    }
});
