export default class Stripe {
    constructor(args) {
        this.stripe = args.stripe;

        // this.countryCode = 'US';
        // this.currencyCode = 'USD';
        // this.company = 'JOEY Inc.';
    }

    isApplePayAvailable() {
        return new Promise(resolve => {
            this.stripe.applePay.checkAvailability(available => {
                if (available) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    paymentRequest(options) {
        return this.stripe.paymentRequest(options);
    }

    // NOTE(dave): Can't use this here (for the web at least) since Apple checks
    // to see if the 'buildSession' function is being called in a button handler
    // or not.
    //
    // processApplePayPayment(amount) {
    //     const paymentRequest = {
    //         countryCode: this.countryCode,
    //         currencyCode: this.currencyCode,
    //         total: {
    //             label: this.company,
    //             amount: amount,
    //         },
    //     };

    //     return new Promise(resolve => {
    //         const session = this.stripe.applePay.buildSession(
    //             paymentRequest,
    //             (result, completion) => {
    //                 console.log('result: ', result.token);

    //                 resolve({
    //                     token: result.token.id,
    //                     onSuccess: () =>
    //                         completion(ApplePaySession.STATUS_SUCCESS),
    //                     onFailure: () =>
    //                         completion(ApplePaySession.STATUS_FAILURE),
    //                 });
    //             },
    //             error => {
    //                 console.log(error.message);
    //             }
    //         );

    //         session.oncancel = () => {
    //             console.log('User hit the cancel button in the payment window');

    //             resolve({});
    //         };

    //         session.begin();
    //     });
    // }
}
