import sanitize from '../sanitize';

export default data => {
    let { drivers_license = {}, vehicles = [], categories = [] } = data;

    return sanitize({
        id: data.id,
        email: data.email,
        scope: data.scope,
        nickname: data.nickname,
        lastname: data.lastname,
        firstname: data.firstname,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        middleInitial: data.middle_initial,
        dob: data.dob,
        ssn: data.social_security_number,
        phone: data.phone,
        avatar: data.profile_photo,
        driver: {
            notes: data.notes,
            status: data.status,
            paymentAccountId: data.connect_id,
            license: {
                state: drivers_license.state,
                photo: drivers_license.photo,
                number: drivers_license.number,
                expiration: drivers_license.expiration,
            },
            vehicles: data.vehicles,
        },
        categories: categories,
        address: data.address,
        savedCards: data.savedCards,
        howYouHeardAboutUs: data.howYouHeardAboutUs,
        howYouHeardAboutUsTimeStamp: data.howYouHeardAboutUsTimeStamp
    });
};
