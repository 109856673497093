import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

export default class Spacer extends Component {
    static propTypes = {
        horizontal: PropTypes.bool,
        expand: PropTypes.bool,
        size: PropTypes.number,
    };

    render() {
        const { horizontal, expand, size } = this.props;

        if (expand) {
            return <View style={styles.expand} />;
        }

        const style = {
            [horizontal ? 'width' : 'height']: size,
        };

        return <View style={style} />;
    }
}

const styles = StyleSheet.create({
    expand: {
        flex: 1,
    },
});
