class LocalStorageAdapter {
    constructor(localStorage) {
        this.localStorage = localStorage;
    }

    setItem(key, val) {
        this.localStorage.setItem(key, val);
    }

    getItem(key) {
        this.localStorage.getItem(key);
    }

    removeItem(key) {
        this.localStorage.removeItem(key);
    }
}

export default LocalStorageAdapter;
