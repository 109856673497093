import { isEqual } from 'lodash';

import { toClientEntity } from '../../helpers';
import { roundUpToHundredth } from '../../helpers/math';
import AddOn from '../add-on';

class DriveBuilder {
    constructor(args) {
        this.presenters = args.presenters;
        this._id;
        this._origin;
        this._destination;
        this._totalValue;
        this._totalWeight;
        this._description;
        this._photos = [];
        this._truck;
        this._helpers = 0;
        this._estimatedCost;
        this._dolly = false;
        this._addOns = [];
    }

    initLib(lib) {
        this.lib = lib;
    }

    submitLocations = async (origin, destination) => {
        const route = {
            origin: {
                business: origin.business,
                serviceCityId: origin.serviceCityId,
                street: origin.street,
                city: origin.city,
                state: origin.state,
                zip: origin.zip,
            },
            destination: {
                business: destination.business,
                serviceCityId: destination.serviceCityId,
                street: destination.street,
                city: destination.city,
                state: destination.state,
                zip: destination.zip,
            }
        };

        if (!this._id) {
            this.lib.router.beginLoading();

            let drive = await _resolve(
                this.lib.drive.create({
                    driveType: 'consumer',
                    route,
                })
            );

            if (drive.error) {
                console.log(drive.error);

                this.lib.router.showAlert({
                    title: 'Error',
                    message: drive.error.message,
                });

                this.lib.router.endLoading();

                return {
                    error: drive.error,
                };
            }

            drive = drive.result;

            console.log(drive);

            delete drive.route.origin.geo;
            delete drive.route.destination.geo;

            this._id = drive.id;
            this._origin = drive.route.origin;
            this._destination = drive.route.destination;

            this.lib.router.endLoading();

            return {
                result: drive,
            };
        } else {
            if (
                !isEqual(origin, this._origin) ||
                !isEqual(destination, this._destination)
            ) {
                this.lib.router.beginLoading();

                let drive = await _resolve(
                    this.lib.drive.update({
                        id: this._id,
                        route,
                    })
                );

                if (drive.error) {
                    console.log(drive.error);

                    this.lib.router.showAlert({
                        title: 'Error',
                        message: drive.error.message,
                    });

                    this.lib.router.endLoading();

                    return {
                        error: drive.error,
                    };
                }

                drive = drive.result;

                console.log(drive);

                delete drive.route.origin.geo;
                delete drive.route.destination.geo;

                this._origin = drive.route.origin;
                this._destination = drive.route.destination;

                this.lib.router.endLoading();

                return {
                    result: drive,
                };
            }

            return {};
        }

        console.log(this.toString());
    };

    addPhoto = async photo => {
        this.lib.router.beginLoading();

        let photoId = await _resolve(this.lib.upload.create(photo));

        if (photoId.error) {
            console.log(photoId.error);

            this.lib.router.showAlert({
                title: 'Error',
                message: photoId.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: photoId.error,
            };
        }

        photoId = photoId.result;

        this._photos.push(photoId);

        this.lib.router.endLoading();

        console.log(this.toString());

        return {
            result: photoId,
        };
    };

    removePhoto = index => {
        this._photos.splice(index, 1);
    };

    submitCargo = (value, weight, description) => {
        if (value <= 0) {
            this.lib.router.showAlert({
                title: 'Error',
                message: 'Total value must be greater than $0.00',
            });

            return { error: {} };
        }

        if (weight <= 0) {
            this.lib.router.showAlert({
                title: 'Error',
                message: 'Total weight must be greater 0',
            });

            return { error: {} };
        }

        if (
            description === undefined ||
            description === null ||
            description === ''
        ) {
            this.lib.router.showAlert({
                title: 'Error',
                message: 'Please enter a description',
            });

            return { error: {} };
        }

        this._totalValue = value;
        this._totalWeight = weight;
        this._description = description;

        return { result: {} };
    };

    submitOrder = async () => {
        const consumerCargo = {
            value: this._totalValue,
            weight: this._totalWeight,
            description: this._description,
            images: this._photos,
        };

        this.lib.router.beginLoading();

        let drive = await _resolve(
            this.lib.drive.update({
                id: this._id,
                consumerCargo,
                truckId: this._truck.id,
                helpers: this._helpers,
                dolly: this._dolly,
                addOns: this._addOns
            })
        );

        if (drive.error) {
            console.log(drive.error);

            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        console.log(drive);

        this._totalValue = drive.consumerCargo.value;
        this._totalWeight = drive.consumerCargo.weight;
        this._description = drive.consumerCargo.description;
        this._estimatedCost = drive.price;

        this.lib.router.endLoading();

        console.log(this.toString());

        return {
            result: drive,
        };
    };

    submitCreditCardPayment = async (
        cardToken,
        name,
        email,
        phone,
        couponId,
        saveSource,
        usingSavedSource
    ) => {
        this.lib.router.beginLoading();

        const userUpdateOptions = {};

        if (name) {
            // NOTE: It appears that `name` here is the cardholder name. This means that if the user is borrowing someone else's card, their name will be overwritten
            // I am not 100% on this, but that appears to be the case and should be investigated
            // If that is the case and this is corrected, we will need to change how we pass the cardholder name in `chargeCreditCard` below
            const splitName = name.split(' ');
            userUpdateOptions.firstname = splitName[0];
            userUpdateOptions.lastname = splitName.pop();
        }
        if (email) {
            userUpdateOptions.email = email;
        }
        if (phone) {
            userUpdateOptions.phone = phone;
        }

        let user = await _resolve(
            this.lib.user.update(userUpdateOptions)
        );

        if (user.error) {
            console.log(user.error);

            this.lib.router.showAlert({
                title: 'Error',
                message: 'Something went wrong, please try again.',
            });

            this.lib.router.endLoading();

            return {
                error: user.error,
            };
        }
        user = user.result;

        let drive = await _resolve(
            this.lib.drive.chargeCreditCard({
                id: this._id,
                source: {
                    cardToken
                },
                saveSource,
                usingSavedSource,
                customer: user,
                couponId
            })
        );

        if (drive.error) {
            console.log(drive.error);

            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        this.lib.router.endLoading();

        console.log(this.toString());

        return {
            result: drive,
        };
    };

    submitApplePayPayment = async (token, couponId) => {
        this.lib.router.beginLoading();

        console.log('$$$$$$$$$$$$$$$$ token: ', token);

        let drive = await _resolve(
            this.lib.drive.chargeApplePay({
                id: this._id,
                source: token,
                couponId
            })
        );

        if (drive.error) {
            console.log(drive.error);

            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        console.log(drive);

        this.lib.router.endLoading();

        console.log(this.toString());

        return {
            result: drive,
        };
    };

    loadAddOns = async () => {
        if (!this.origin || !this.origin.serviceCityId) {
            this.lib.router.showAlert({
                title: 'Error',
                message: 'Could not fetch list of add-on services, city not found',
            });
            return;
        }

        this.lib.router.beginLoading();

        const availableAddOns = await _resolve(this.lib.addOn.getAvailableAddOns(this.origin.serviceCityId));

        this.lib.router.endLoading();

        if (availableAddOns.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: 'Could not fetch list of add-on services',
            });
            return;
        }

        this.addOns = availableAddOns.result.map(addOn => toClientEntity.AddOn(addOn));
    }

    setAddOn = (addOnId, value) => {
        const existingAddOn = this.addOns.find(addOn => addOn.id === addOnId);
        if (existingAddOn.optionType === AddOn.OPTION_TYPES.integer && value < 0) return;
        if (existingAddOn.optionType === AddOn.OPTION_TYPES.scheduled && false) {
            // TODO: Add some kind of check (instead of false...) and alert if the date is less than 24 hours away
        }
        existingAddOn.value = value;
    }

    setAddOns = (addOns) => {
        this.addOns = addOns;
    }

    calcAddOnBasePrice = (addOn) => {
        return AddOn.calcBasePrice(addOn, this.truck.timeLimit);
    }

    estimateCost = () => {
        const addOnsCost = this.addOns.reduce((total, currAddOn) => total += this.calcAddOnBasePrice(currAddOn), 0);
        return (Number(this.truck.basePrice) + addOnsCost).toFixed(2);
    }

    calcMinuteRate = () => {
        const addOnsMinuteRate = this.addOns.reduce((total, currAddOn) => total += AddOn.calcMinuteRate(currAddOn), 0);
        // round up to the nearest penny and make sure there are two digits after the point
        return roundUpToHundredth(addOnsMinuteRate + this.truck.minuteRate, 2).toFixed(2);
    }

    submitCategoryReason = async (reason) => {
        this.lib.router.beginLoading();

        let drive = await _resolve(
            this.lib.drive.update({
                id: this._id,
                categoryReason: reason
            })
        );

        if (drive.error) {
            console.log(drive.error);

            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        console.log(drive);

        this.lib.router.endLoading();

        return {
            result: drive,
        };
    }

    toString() {
        return (
            'ID: ' +
            this._id +
            ' - ' +
            'Origin: ' +
            JSON.stringify(this._origin) +
            ' - ' +
            'Destination: ' +
            JSON.stringify(this._destination) +
            ' - ' +
            'Total Value: ' +
            this._totalValue +
            ' - ' +
            'Total Weight: ' +
            this._totalWeight +
            ' - ' +
            'Description: ' +
            this._description +
            ' - ' +
            'Photos: ' +
            this._photos.map(p => p) +
            ' - ' +
            'Truck: ' +
            JSON.stringify(this._truck) +
            ' - ' +
            'Helpers: ' +
            this._helpers +
            ' - ' +
            'Dolly: ' +
            this._dolly +
            ' - ' +
            'Estimated Cost: ' +
            this._estimatedCost
        );
    }

    reset() {
        this._id = null;
        this._origin = null;
        this._destination = null;
        this._totalValue = null;
        this._totalWeight = null;
        this._description = null;
        this._photos = [];
        this._truck = null;
        this._helpers = 0;
        this._estimatedCost = null;
        this._dolly = false;
    }

    get totalValue() {
        return this._totalValue;
    }

    set totalValue(value) {
        if (value) {
            this._totalValue = value;
        }
    }

    get totalWeight() {
        return this._totalWeight;
    }

    set totalWeight(value) {
        if (value) {
            this._totalWeight = value;
        }
    }

    get description() {
        return this._description;
    }

    set description(value) {
        if (value) {
            this._description = value;
        }
    }

    get truck() {
        return this._truck;
    }

    set truck(value) {
        if (value) {
            this._truck = value;
        }
    }

    get helpers() {
        return this._helpers;
    }

    set helpers(value) {
        if (value >  -1) {
            this._helpers = value;
        }
    }

    get dolly() {
        return this._dolly;
    }

    set dolly(value) {
        this._dolly = value;
    }

    get photoURLs() {
        return this.presenters.allPhotos(this._photos).map(p => p.url);
    }

    get estimatedCost() {
        return this._estimatedCost;
    }

    get origin() {
        return this._origin;
    }

    get destination() {
        return this._destination;
    }

    set addOns(addOns) {
        this._addOns = addOns;
    }

    get addOns() {
        return this._addOns;
    }
}

module.exports = DriveBuilder;
