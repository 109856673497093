import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Text, TouchableOpacity, StyleSheet, View } from 'react-native';

import { colors } from '../constants/theme';

export default class RadioPillGroup extends Component {
    static propTypes = {
        options: PropTypes.any,
        onClick: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            howYouHeardAboutUs: null
        };
    }

    handleOnClick = (howYouHeardAboutUs) => {
        const { onClick } = this.props;
        if (howYouHeardAboutUs !== this.state.howYouHeardAboutUs) {
            this.setState({
                howYouHeardAboutUs
            });

            onClick(howYouHeardAboutUs);
        }
    }

    render() {
        const {howYouHeardAboutUs} = this.state;
        const {options} = this.props;

        let oddColumn = options.filter((o, i) => i % 2 === 1);
        let evenColumn = options.filter((o, i) => i % 2 !== 1);
        return (
            <View style={styles.groupContainer}>
                <View style={[styles.columnContainer, styles.left]}>
                    {evenColumn.map((option, i) => {
                        const active = howYouHeardAboutUs && option.value === howYouHeardAboutUs;
                        return (
                            <TouchableOpacity key={i} style={[active ? styles.active : styles.inactive, styles.pill]} onPress={() => this.handleOnClick(option.value)}>
                                <Text style={active ? styles.activeText : styles.inactiveText}>{option.label}</Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
                <View style={[styles.columnContainer, styles.right]}>
                    {oddColumn.map((option, i) => {
                        const active = howYouHeardAboutUs && option.value === howYouHeardAboutUs;
                        return (
                            <TouchableOpacity key={i} style={[active ? styles.active : styles.inactive, styles.pill]} onPress={() => this.handleOnClick(option.value)}>
                                <Text style={active ? styles.activeText : styles.inactiveText}>{option.label}</Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
            </View>
        )
    }
};

const styles = StyleSheet.create({
    groupContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100%'
    },
    columnContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    pill: {
        height: 46,
        borderWidth: 1,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 30,
        justifyContent: 'center'
    },
    active: {
        backgroundColor: colors.green,
        borderColor: colors.green,
    },
    inactive: {
        backgroundColor: colors.creamWhite,
        borderColor: '#B9C4B8',
    },
    activeText: {
        color: colors.white,
        textAlign: 'center'
    },
    inactiveText: {
        color: colors.midGrey4,
        textAlign: 'center'
    },
    left: {
        marginRight: 10
    },
    right: {
        marginLeft: 10
    }
});