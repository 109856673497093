import React from 'react';

import { InfoModal } from '../modals';

export default () => (
    <InfoModal
        title={'Mobile'}
        body={
            'Joey requires your mobile number so the drivers can contact you ' +
                'to schedule your delivery.\n\nWe encrypt your phone number so ' +
                'drivers will never see it.'
        }
    />
);
