require('babel-polyfill');
import React from 'react';

import { AppRegistry } from 'react-native';
import { AppContainer } from 'react-hot-loader';

import App from './src';
import './web/app.css';

const renderApp = () => <App />;

AppRegistry.registerComponent('JoeyConsumerMobile', () => renderApp);

if (module.hot) {
    module.hot.accept();

    const renderHotApp = () => (
        <AppContainer>
            <App />
        </AppContainer>
    );

    AppRegistry.registerComponent('JoeyConsumerMobile', () => renderHotApp);
}

AppRegistry.runApplication('JoeyConsumerMobile', {
    rootTag: document.getElementById('root'),
});
