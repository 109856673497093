// Based on https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f

const shouldIntercept = (error) => {
    try {
        return error.response.status === 401 && !error.config.url.endsWith('refresh-token-consumer');
    } catch (e) {
        return false;
    }
};

const setTokenData = (user) => {
    window.localStorage.setItem('@joey-consumer', user.token);
    window.localStorage.setItem('@joey-consumer-user-id', user.id);
};

const attachTokenToRequest = (request, accessToken) => {
    request.headers['Authorization'] = 'Bearer ' + accessToken;
};

export default (api, libs) => {
    let isRefreshing = false;
    let failedQueue = [];

    const handleTokenRefresh = () => {
        return new Promise((resolve, reject) => {
            api.get('/users/refresh-token-consumer')
                .then(({ result }) => {
                    resolve(result);
                })
                .catch((err) => {
                    libs.user.logout()
                    reject(err);
                })
        });
    };

    const processQueue = (error, accessToken = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(accessToken);
            }
        });

        failedQueue = [];
    };

    const errorInterceptor = (error) => {
        if (!shouldIntercept(error) || error.config._retry || error.config._queued) {
            return Promise.reject({ error: error.response ? error.response.data : error });
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(user => {
                originalRequest._queued = true;
                attachTokenToRequest(originalRequest, user.token);
                return api.request(originalRequest);
            }).catch(refreshTokenErr => {
                return Promise.reject({ error: error.response ? error.response.data : error }); // Ignore refresh token request's error and return actual error for the original request
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            handleTokenRefresh()
                .then((user) => {
                    setTokenData(user, api);
                    attachTokenToRequest(originalRequest, user.token);
                    processQueue(null, user.token);
                    resolve(api.request(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                })
        });
    };

    api.interceptors.response.use(
        async response => ({ result: response.data }),
        errorInterceptor
    );
};
