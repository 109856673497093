import sanitize from '../sanitize';

export default data => {
    let {
        id,
        startDate,
        endDate,
        value,
        used,
        available,
        name,
        type,
        limited,
        cities,
    } = data;

    return sanitize({
        id,
        startDate,
        endDate,
        value,
        used,
        available,
        name,
        type,
        limited,
        cities,
    });
};