import React from 'react';

import { StyleSheet, View, Image, TouchableOpacity, Linking } from 'react-native';

import {
    Text,
    LocationMap,
    DeliveryAddressRow,
    NavigationHeader
} from '../components';

import { colors } from '../constants/theme';

import phoneIcon from '../assets/green-call@3x.png';

export default ({ summaryData, deliveryData, ext, phone, id, goBack, driverData }) => {
    const { truckName } = summaryData;
    const { origin, destination } = deliveryData;
    const {firstname, lastname, profilePhoto } = driverData;
    const name = `${firstname} ${lastname.length > 0 ? lastname[0] + '.' : ""}`;
    console.log(driverData)

    return (
        <View style={styles.container}>
            <NavigationHeader 
                titleText={'En Route'}
                style={{backgroundColor: colors.creamWhite}}
                goBack={goBack}
            />
                <View style={{flex: 1}}>
                    <DeliveryAddressRow style={{margin: 20}} origin={origin} destination={destination} />
                    <LocationMap
                        origin={origin}
                        destination={destination}
                        flex
                    />
                    <View style={styles.footer}>
                        <View style={{width: '100%', flexDirection: 'row'}}>
                            <View style={{flex: 1, flexDirection: 'row'}}>
                                <Image
                                    source={{ uri: profilePhoto }}
                                    style={{ width: 46, height: 46, borderRadius: 23 }}
                                />
                                <View style={styles.driverInfo}>
                                    <Text style={styles.name}>{name}</Text>
                                    <Text style={styles.truckName}>{truckName}</Text>
                                </View>
                            </View>
                            <TouchableOpacity
                                onPress={() => Linking.openURL(`tel:${phone},${ext}`)}
                                style={styles.callDriver}
                                height={46}
                            >
                                <Image
                                    source={{ uri: phoneIcon }}
                                    style={{ width: 17, height: 17, marginRight: 10, marginLeft: 15 }}
                                />
                                <Text style={{fontSize: 14, color: colors.green, flex: 1}}>Call Driver</Text>
                            </TouchableOpacity>
                        </View>
                        <Text style={styles.supportIdText}>Support ID: {id.slice(-5)}</Text>
                    </View>
                </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.white,
    },
    supportIdText: {
        fontSize: 12,
        color: 'lightslategray',
        textAlign: 'center',
        marginTop: 5
    },
    footer: {
        backgroundColor: colors.white,
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 20,
        flexDirection: 'column',
        alignItems: 'center'
    },
    callDriver: {
        borderWidth: 1,
        borderColor: colors.green,
        borderRadius: 30,
        height: 46,
        width: 130,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    name: {
        fontSize: 14,
        color: colors.darkGrey2
    },
    truckName: {
        fontSize: 12,
        color: "#657463"
    },
    driverInfo: {
        flex: 1, 
        flexDirection: 'column', 
        marginStart: 10,
        justifyContent: 'center'
    }
});
