import React from 'react';

import { View, StyleSheet, Image } from 'react-native';

import { Text, Spacer, Button } from '../components';

import { colors } from '../constants/theme';

import cancelOrderIcon from '../assets/cancel-order-icon@3x.png';

export default ({ onCancel }) => (
    <View style={styles.container}>
        <View style={styles.innerContainer}>
            <View style={styles.contentContainer}>
                <Image
                    resizeMode={Image.resizeMode.contain}
                    source={{ uri: cancelOrderIcon }}
                    style={{
                        width: 50,
                        height: 50,
                    }}
                />
                <Spacer size={15} />
                <Text style={styles.titleText}>
                    Cancel Order
                </Text>
                <Spacer size={5} />
                <Text style={styles.contentText}>
                    Please select from the options below.
                </Text>
                <Spacer size={15} />
            </View>
            <Button onPress={onCancel} muted>CANCEL ORDER</Button>
        </View>
    </View>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    innerContainer: {
        width: `100%`,
        backgroundColor: colors.white,
        padding: 20,
    },
    contentContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        fontFamily: 'ClanOT-Medium',
        fontSize: 18,
    },
    contentText: {
        textAlign: 'center',
    },
});
