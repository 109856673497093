import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dimensions,
    Animated,
    StyleSheet,
    View,
    TouchableOpacity,
    Text
} from 'react-native';

import { colors } from '../constants/theme';

const { height: deviceHeight } = Dimensions.get('window');

export default class Modal extends React.Component {
    static propTypes = {
        component: PropTypes.func.isRequired,
        passProps: PropTypes.object,
        animationType: PropTypes.string,
        onDismiss: PropTypes.func.isRequired,
        modalHeight: PropTypes.number,
        persistent: PropTypes.bool,
        fullscreen: PropTypes.bool,
    };

    static defaultProps = {
        animationType: 'fade',
        modalHeight: deviceHeight,
    };

    state = {
        offset: new Animated.Value(0),
    };

    componentDidMount() {
        if (this.props.component) {
            this._animateIn();
        }
    }

    componentWillReceiveProps(props) {
        if (!this.props.component && props.component) {
            this._animateIn();
        }
    }

    _animateIn = () => {
        Animated.timing(this.state.offset, {
            duration: 200,
            toValue: 1,
        }).start();
    };

    animateOut = callback => {
        Animated.timing(this.state.offset, {
            duration: 200,
            toValue: 0,
        }).start(callback);
    };

    render() {
        const {
            modalHeight,
            animationType,
            passProps,
            component: Component,
            onDismiss,
            persistent,
            fullscreen
        } = this.props;

        const style = animations[animationType](
            this.state.offset,
            deviceHeight - modalHeight
        );

        return (
            <Animated.View style={[styles.container, style]}>
                <View style={styles.backdrop} />
                <View style={styles.innerContent}>
                    <Component {...passProps} />
                </View>
                {!persistent && 
                    <View style={styles.closeButton}>
                        <TouchableOpacity style={{height: 26}} onPress={onDismiss}>
                            <Text style={{fontSize: 16, color: colors.white, margin: 'auto', fontFamily: 'SctoGroteskA-Regular'}}>X</Text>
                        </TouchableOpacity>
                    </View>
                }
            </Animated.View>
        );
    }
}

const animations = {
    slide: (value, finalHeight) => ({
        transform: [
            {
                translateY: value.interpolate({
                    inputRange: [0, 1],
                    outputRange: [deviceHeight, finalHeight],
                }),
            },
        ],
    }),
    fade: value => ({
        opacity: value,
    }),
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: '#000',
        opacity: 0.75,
    },
    innerContent: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeButton: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 15,
        width: 26,
        height: 26,
        position: 'absolute',
        right: 0,
        marginRight: 20,
        marginTop: 28
    }
});
