import moment from 'moment';

const STATUSES = {
    available: 'Ordered',
    accepted: 'Accepted',
    started: 'En Route',
    loading: 'En Route',
    driving: 'En Route',
    delivered: 'Complete',
    refunded: 'Complete',
};

const STATUS_TYPES = {
    available: 'ordered',
    accepted: 'accepted',
    started: 'enRoute',
    loading: 'enRoute',
    driving: 'enRoute',
    delivered: 'complete',
    refunded: 'complete',
};

const DATE_FORMAT = 'MM/DD/YY';

export default drives => {

    return drives
        .filter(d => d.status !== 'unpaid' && d.status !== 'cancelledFee' && d.status !== 'cancelledNoFee')
        .map(drive => {
            const {
                id,
                status,
                truck,
                helpers,
                dolly,
                createdAt,
                startTime,
                pickupTime,
                endTime,
            } = drive;

            const currentStatus = STATUS_TYPES[status];

            let startDate = moment(createdAt).format(DATE_FORMAT);
            let endDate;

            if (currentStatus === 'ordered') {
                endDate = 'TBD';
            } else if (currentStatus === 'accepted') {
                endDate = moment(createdAt).add(1, 'days').format(DATE_FORMAT);
            } else if (currentStatus === 'enRoute') {
                startDate = moment(startTime).format(DATE_FORMAT);
                endDate = moment(startTime).add(1, 'days').format(DATE_FORMAT);
            } else if (currentStatus === 'complete') {
                startDate = moment(startTime).format(DATE_FORMAT);
                endDate = moment(endTime).format(DATE_FORMAT);
            }

            const { name } = truck;
            const helpersText = `${helpers > 0 ? ` + ${helpers} Helper${helpers > 1 ? 's' : ''}` : ''}`;
            const dollyText = `${dolly ? ' + Dolly' : ''}`;

            return {
                id,
                title: `${startDate} - ${endDate}`,
                description: `${name}${helpersText}${dollyText}`,
                statusDisplay: STATUSES[status],
                statusType: currentStatus,
            };
        });
};
