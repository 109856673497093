import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimensions } from 'react-native';
import {
    GoogleMap,
    withGoogleMap,
    DirectionsRenderer,
    Marker,
    withScriptjs,
} from 'react-google-maps';

import originPin from '../assets/origin-pin@3x.png';
import destinationPin from '../assets/destination-pin@3x.png';

const googleMapURL =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCRThXMYQvLJCBTM8gHWz-KJWg7bnksWrg&libraries=geometry';

const CINCINNATI_LAT_LNG = {
    lat: 39.101825,
    lng: -84.512594,
};

const GoogleMapContainer = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            ref={props.onMapLoad}
            defaultZoom={12}
            zoom={props.zoom}
            center={props.center}
            bounds={props.bounds}
            ref={props.onMapMount}
            defaultCenter={props.center}
            onBoundsChanged={props.onBoundsChanged}
            defaultOptions={{
                disableDefaultUI: true,
            }}
        >
            {props.children}
        </GoogleMap>
    ))
);

export default class LocationMap extends Component {
    static propTypes = {
        origin: PropTypes.string,
        destination: PropTypes.string,
        style: PropTypes.any,
        onMapMounted: PropTypes.func,
        onBoundsChanged: PropTypes.func,
        flex: PropTypes.bool
    };

    isUnmounted = false;

    state = {
        zoom: 12,
        center: CINCINNATI_LAT_LNG,
        directions: null,
        origin: null,
        destination: null,
        markers: null,
    };

    handleBoundsChanged = () => {
        const newBounds = this._map.getBounds();
        this.setState({
            bounds: this._map.getBounds(),
            center: this._map.getCenter(),
            zoom: this._map.getZoom(),
        });

        this.props.onBoundsChanged(newBounds);
    };

    getCenterForAddress(address, callback) {
        const Geocoder = new google.maps.Geocoder();

        Geocoder.geocode({ address }, (results, status) => {
            const center = results[0].geometry.location;

            callback(center);
        });
    }

    getMarkerForPoint(point, pinIcon) {
        return {
            position: point,
            icon: {
                scaledSize: new google.maps.Size(40, 63),
                url: pinIcon,
            },
        };
    }

    placeMarkerForAddress(address, pin) {
        this.getCenterForAddress(address, center => {
            const markers = [this.getMarkerForPoint(center, pin)];

            this.setState({
                center,
                markers,
                directions: null,
            });
        });
    }

    componentDidMount() {
        this.processAddresses();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.origin !== prevProps.origin ||
            this.props.destination !== prevProps.destination
        ) {
            this.processAddresses();
        }
    }

    processAddresses = () => {
        const { origin, destination } = this.props;

        if (origin !== '' && destination === '') {
            this.placeMarkerForAddress(origin, originPin);
        } else if (destination !== '' && origin === '') {
            this.placeMarkerForAddress(destination, destinationPin);
        } else if (origin !== '' && destination !== '') {
            setTimeout(() => {
                const DirectionsService = new google.maps.DirectionsService();

                let { origin, destination } = this.props;

                DirectionsService.route(
                    {
                        origin,
                        destination,
                        travelMode: google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        this.getCenterForAddress(origin, originPoint => {
                            this.getCenterForAddress(
                                destination,
                                destinationPoint => {
                                    const originMarker = this.getMarkerForPoint(
                                        originPoint,
                                        originPin
                                    );
                                    const destinationMarker = this.getMarkerForPoint(
                                        destinationPoint,
                                        destinationPin
                                    );

                                    if (
                                        status ===
                                        google.maps.DirectionsStatus.OK
                                    ) {
                                        this.setState({
                                            directions: result,
                                            markers: [
                                                originMarker,
                                                destinationMarker,
                                            ],
                                        });
                                    } else {
                                        return;
                                    }
                                }
                            );
                        });
                    }
                );
            }, 500);
        } else {
            this.setState({ markers: null, directions: null });
        }
    };

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    handleMapMount = map => {
        this._map = map;

        if (this.props.onMapMounted) {
            this.props.onMapMounted();
        }
    };

    render() {
        const { style = {}, flex } = this.props;
        const { height, width } = Dimensions.get('window');
        const { zoom, bounds, center, markers, directions } = this.state;

        let mapStyle = {
            // TODO(dave): Fix this hack to try and offset the
            // center of the map...it doesn't work for smaller
            // devices.
            height: height + 125,
            ...style,
        };

        if (flex) {
            mapStyle = {
                flex: 1,
                height: '100%'
            }
        }

        return (
            <GoogleMapContainer
                zoom={zoom}
                center={center}
                markers={markers}
                onBoundsChanged={this.handleBoundsChanged}
                googleMapURL={googleMapURL}
                onMapMount={this.handleMapMount}
                loadingElement={<div />}
                containerElement={<div style={{flex: 1, height: '100%'}} />}
                mapElement={<div style={mapStyle} />}
            >
                {directions &&
                    <DirectionsRenderer
                        directions={directions}
                        options={{
                            suppressMarkers: true,
                        }}
                    />}
                {markers &&
                    markers.map((marker, index) => (
                        <Marker
                            position={marker.position}
                            key={index}
                            icon={marker.icon}
                        />
                    ))}
            </GoogleMapContainer>
        );
    }
}