import React from 'react';

import { View, StyleSheet, TouchableHighlight } from 'react-native';

import { Text, Spacer, Button } from '../components';

import { colors } from '../constants/theme';

export default ({ onContinue }) => (
    <View style={styles.container}>
        <View style={styles.innerContainer}>
            <Text style={styles.titleText}>
                It looks like you’ve used Joey before
            </Text>
            <Spacer size={10} />
            <Text>
                If you don’t think you have used Joey,
                <Text accessibilityRole='link' href={'mailto:customers@getjoey.com?subject=Customer%20Login&body=I%20cannot%20login%20to%20put%20in%20a%20Joey%20Request!'}>
                    <Text> contact us</Text>
                </Text>
            </Text>
        </View>
        <Button onPress={onContinue} muted>
            GO TO LOGIN
        </Button>
    </View>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    innerContainer: {
        width: `100%`,
        backgroundColor: colors.white,
        padding: 55,
        paddingVertical: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        fontFamily: 'ClanOT-Medium',
        fontSize: 18,
    },
    contentText: {
        textAlign: 'center',
    },
});
