import sanitize from '../sanitize';

export default data => {
    let { price = {} } = data;

    return sanitize({
        id: data.id,
        price: {
            hourly: price.hourly,
            timeLimit: price.timeLimit,
        },
        length: data.length,
        width: data.width,
        height: data.height,
        image: data.image,
        type: data.type,
        name: data.name,
        illustration: data.illustration,
        helperFee: data.helperFee
    });
};
