import { toClientEntity, toServerEntity } from '../../helpers';

export default class TruckApiAdapter {
    constructor(api) {
        this._api = api;
    }

    async filter(query) {
        let trucks = await _resolve(this._api.post('/trucks/filter', query));

        if (trucks.err) {
            return {
                error: trucks.error,
            };
        }

        trucks = trucks.result;

        trucks = trucks.map(t => toClientEntity.Truck(t));

        trucks = trucks.sort((a, b) => a.size - b.size);

        return {
            result: trucks,
        };
    }

    async getById(id) {
        let truck = await _resolve(this._api.get('/trucks/' + id));

        if (truck.error) {
            return {
                error: truck.error.message,
            };
        }

        truck = truck.result;

        truck = toClientEntity.Truck(truck);

        return {
            result: truck,
        };
    }

    async delete(id) {
        let truck = await _resolve(this._api.delete('/trucks/' + id));

        if (truck.error) {
            return {
                error: truck.error.message,
            };
        }

        truck = truck.result;

        truck = toClientEntity.Truck(truck);

        return {
            result: truck,
        };
    }

    async create(data) {
        data = toServerEntity.Truck(data);

        let truck = await _resolve(this._api.post('/trucks', data));

        if (truck.error) {
            return {
                error: truck.error,
            };
        }

        truck = truck.result;

        truck = toClientEntity.Truck(truck);

        return {
            result: truck,
        };
    }

    async update(data) {
        let { id, ...updatedTruck } = data;

        data = toServerEntity.Truck(updatedTruck);

        let truck = await _resolve(this._api.put('/trucks/' + id, data));

        if (truck.error) {
            return {
                error: truck.error,
            };
        }

        truck = truck.result;

        truck = toClientEntity.Truck(truck);

        return {
            result: truck,
        };
    }
}
