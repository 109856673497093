import React, { Component } from 'react';

import {
    ScrollView,
    StyleSheet,
    View,
} from 'react-native';

import moment from 'moment';

import { Text, Button, NavigationHeader } from '../components';

import { colors } from '../constants/theme';

import AddOn from '../../lib/add-on';

export default class OrderSummary extends Component {
    constructor() {
        super();

        this.state = {
            hasReloadedToForceButtonShowing: false
        }
    }
    componentDidMount = () => {
        // Customers were occasionally having issues where the "proceed to payment" button wasn't showing
        // I was never able to replicate this, but I found this dealt with in a similar way here, so hopefully it works:
        // https://github.com/Fortitheos/joey-consumer-mobile/commit/575bba2db78aaf7236a5e1c1029aebfbcdf1d1b6
        setTimeout(() => this.setState({ hasReloadedToForceButtonShowing: true }), 500)
    }

    render() {
        const {
            driveBuilder,
            onContinue,
            goBack
        } = this.props;
        const { truck, addOns, estimatedCost } = driveBuilder;
        const { title, displayTimeFor } = truck;

        const enumeratedAddOns = AddOn.enumerate(addOns, '\n');
        const schedulingAddOn = driveBuilder.addOns.find(addOn => addOn.optionType === AddOn.OPTION_TYPES.scheduled);
        let schedulingText = 'Your Joey driver will contact you soon to schedule delivery.'

        if (schedulingAddOn && schedulingAddOn.value) {
            schedulingText = `${moment(schedulingAddOn.value.date).format('MMMM Do')} - ${AddOn.SCHEDULING_OPTIONS[schedulingAddOn.value.time]}\nThe driver will contact you soon to arrange specific times for pickup.`
        }

        return (
            <View style={styles.container}>
                <NavigationHeader 
                    goBack={goBack} 
                    titleText={'Order Summary'}
                    style={{backgroundColor: colors.creamWhite}}
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <View style={styles.hr} />
                        <View style={styles.row}>
                            <Text style={styles.rowDetailsStart}>Service</Text>
                            <Text style={styles.rowDetailsEnd}>{title}</Text>
                        </View>
                        <View style={styles.hr} />
                        <View style={styles.row}>
                            <Text style={styles.rowDetailsStart}>Additional</Text>
                            <Text style={styles.rowDetailsEnd}>{enumeratedAddOns ? enumeratedAddOns + '\n' : null}</Text>
                        </View>
                        <View style={styles.hr} />
                        <View style={styles.row}>
                            <Text style={styles.rowDetailsStart}>Duration</Text>
                            <Text style={styles.rowDetailsEnd}>{displayTimeFor}</Text>
                        </View>
                        <View style={styles.hr} />
                        <View style={styles.row}>
                            <Text style={styles.rowDetailsStart}>Est. Total</Text>
                            <Text style={styles.rowDetailsEnd}>${estimatedCost}</Text>
                        </View>
                        <Text style={styles.serviceDetailsText}>Orders start at the pickup. Service exceeding the {driveBuilder.truck.displayTimeThe.toLowerCase()} estimate will incur additional charges of ${driveBuilder.calcMinuteRate()} per minute.</Text>
                        <View style={styles.hr} />
                        <Text style={styles.schedulingDetailsTitleText}>Scheduling</Text>
                        <Text style={styles.schedulingDetailsText}>
                            {schedulingText}
                        </Text>
                    </View>
                    <View style={styles.footer}>
                        {this.state.hasReloadedToForceButtonShowing === true &&
                            <Button
                                onPress={onContinue}
                                style={styles.circleButton}
                            >
                                Continue
                            </Button>
                        }
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    body: {
      width: `100%`,
      paddingTop: 10,
      paddingHorizontal: 20,
      alignItems: 'center',
      flex: 1
    },
    circleButton: {
      backgroundColor: colors.green,
      borderRadius: 30
    },
    footer: {
      padding: 20,
      justifyContent: 'flex-end'
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        marginLeft: 20,
        marginRight: 20,
        width: '100%'
    },
    container: {
      flex: 1,
      backgroundColor: colors.creamWhite
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: 5,
        paddingBottom: 15
    },
    rowDetailsStart: {
        flex: 1,
        fontSize: 21,
        textAlign: 'start',
        fontFamily: 'Recoleta-Semibold',
    },
    rowDetailsEnd: {
        flex: 1,
        fontSize: 21,
        textAlign: 'end',
        fontFamily: 'Recoleta-Semibold',
    },
    serviceDetailsText: {
        fontSize: 18,
        marginBottom: 15
    },
    schedulingDetailsTitleText: {
        marginTop: 25,
        fontSize: 24,
        fontFamily: 'Recoleta-Semibold',
        textAlign: 'center',
    },
    schedulingDetailsText: {
        textAlign: 'center',
        paddingHorizontal: 10,
        fontSize: 18,
        marginTop: 5
    }
});