export const colors = {
    white: '#ffffff',
    black: 'black',
    grey: '#EEF1F1',
    midGrey: '#D9E1E2',
    midGrey2: '#98A4AE',
    midGrey3: '#B9C4B8',
    midGrey4: '#647362',
    darkGrey: '#253746',
    darkGrey2: '#1A3C34',
    lightGreen: '#AADB1E',
    green: '#638C1C',
    darkGreen: '#648C1A',
    lightBlue: '#6EDBD5',
    red: '#D4451D',
    lightRed: '#F87C56',
    transparentGreen: '#82a349',
    creamWhite: '#F4F3F0'
};

export const fonts = {
    // form: {
    //     title: {
    //         fontSize: 24,
    //         color: 'rgb(33,42,53)',
    //     },
    //     subtitle: {
    //         fontSize: 18,
    //         lineHeight: 26,
    //         color: 'rgb(33,42,53)',
    //     },
    //     label: {
    //         fontSize: 14,
    //         fontWeight: 'bold',
    //         color: 'rgb(33,42,53)',
    //     },
    // },
};
