export default (addOn) => {
    // Set default values
    if (addOn.optionType === 'integer') addOn.value = 0;
    else if (addOn.optionType === 'boolean') addOn.value = false;
    else addOn.value === null;

    // Add-ons get saved directly onto the drive, and we won't need these fields
    delete addOn.cities;
    delete addOn.active;
    
    return addOn;
}