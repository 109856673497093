import { deleteClientRefreshToken } from "../../src/helpers/cookies";

export default class User {
    constructor(args) {
        this.store = args.store;
        this.storage = args.storage;

        this.login = this.login.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
        this.logout = this.logout.bind(this);
    }

    initLib(lib) {
        this.lib = lib;
    }

    login = async (email, phone) => {
        this.lib.router.beginLoading();
        
        if (phone) {
            phone = phone.replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '');
        }

        let user = await _resolve(this.store.generateMagicLink(email, phone));

        if (user.error) {
            this.lib.router.endLoading();

            if (user.error.message === 'No user with id.') {
                this.lib.router.showAlert({
                    title: 'Error',
                    message: 'We could not find a user with that email or phone number.',
                });
            }

            return {
                error: user.error.message,
            };
        }

        this.lib.router.endLoading();

        user = user.result;

        return {
            result: user,
        };
    };

    validateMagicLink = async code => {
        this.lib.router.beginLoading();

        let user = await _resolve(this.store.validateMagicLink(code));

        if (user.error) {
            this.lib.router.endLoading();

            this.lib.router.showAlert({
                title: 'Error',
                message: 'Sorry, your magic link is no longer valid. Please try to login again.',
            });

            return {
                error: user.error.message,
            };
        }

        user = user.result;

        this.lib.router.endLoading();

        this.storage.setItem('@joey-consumer', user.token);
        this.storage.setItem('@joey-consumer-user-id', user.id);

        return {
            result: user,
        };
    };

    async update(data) {
        let user = await _resolve(
            this.store.update({
                id: getUserId(),
                ...data,
            })
        );

        if (user.error) {
            this.lib.router.endLoading();
            return {
                error: user.error,
            };
        }

        user = user.result;

        console.log(user);

        this.storage.setItem('@joey-consumer-user-id', user.id);

        return {
            result: user,
        };
    }

    async register(email, phone) {
        this.lib.router.beginLoading();

        if (phone) {
            phone = phone.replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '');
        }

        let user = await _resolve(this.store.register(email, phone));

        if (user.error) {
            this.lib.router.endLoading();

            return {
                error: user.error,
            };
        }

        this.lib.router.endLoading();

        user = user.result;

        if (user.token && user.id) {
            this.storage.setItem('@joey-consumer', user.token);
            this.storage.setItem('@joey-consumer-user-id', user.id);
        }

        return {
            result: user,
        };
    }

    async getById(id) {
        this.lib.router.beginLoading();

        let user = await _resolve(this.store.getById(id));

        if (user.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: user.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: user.error,
            };
        }

        user = user.result;

        this.lib.router.endLoading();

        return {
            result: user
        };
    }

    async checkForExistingUserByUserInfo(userId, email, phone) {
        this.lib.router.beginLoading();

        let users = await _resolve(this.store.checkForExistingUserByUserInfo(userId, email, phone));

        if (users.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: users.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: users.error,
            };
        }

        users = users.result;

        this.lib.router.endLoading();

        return {
            result: users
        };
    }

    isLoggedIn() {
        if (getAuthToken()) {
            return true;
        }

        return false;
    }

    async logout() {
        this.storage.removeItem('@joey-consumer');
        this.storage.removeItem('@joey-consumer-user-id');
        deleteClientRefreshToken();

        this.lib.router.logout();
    }

    async removeSavedCard(cardId) {
        this.lib.router.beginLoading();
        const { result: user, error } = await _resolve(this.store.removeSavedCard(getUserId(), cardId));

        this.lib.router.endLoading();

        if (error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: error.message,
            });

            return { error };
        }

        return { result: user };
    }

    async recommendFriend(email) {
        this.lib.router.beginLoading();
        const { result: coupon, error } = await _resolve(this.store.recommendFriend(email));

        this.lib.router.endLoading();

        if (error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: error.message,
            });

            return { error };
        }

        return { result: coupon };
    }

    async eraseUserData(){
        const {result, error}=await _resolve(this.store.eraseUserData())

        if (error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: error.message,
            });

            return { error };
        }
        return {result}
    }
}