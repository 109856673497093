import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet,
    View,
    Image,
    Dimensions,
    TouchableOpacity,
} from 'react-native';

import { ImageInput } from '../components';

import { colors } from '../constants/theme';

import addPhotoIcon from '../assets/add-photo-icon@3x.png';

const screenWidth = Dimensions.get('window').width;
const photoWidth = screenWidth > 800 ? 800 / 5 : screenWidth / 5;

export default class PhotoButton extends Component {
    static propTypes = {
        current: PropTypes.bool,
        onImageChange: PropTypes.func.isRequired,
        onEditPhoto: PropTypes.func.isRequired,
        uploadedPhotoUrl: PropTypes.string,
        beginLoading: PropTypes.func.isRequired,
    };

    handleImageChange = image => {
        this.props.onImageChange(image);
    };

    render() {
        const {
            current,
            onEditPhoto,
            uploadedPhotoUrl,
            beginLoading,
        } = this.props;

        const containerStyle = [
            styles.container,
            current && { backgroundColor: colors.darkGrey2 },
        ];

        if (current) {
            return (
                <ImageInput
                    style={containerStyle}
                    onImageChange={this.handleImageChange}
                    beginLoading={beginLoading}
                >
                    {current &&
                        <Image
                            source={{ uri: addPhotoIcon }}
                            style={styles.photoIcon}
                        />}
                </ImageInput>
            );
        }

        if (uploadedPhotoUrl) {
            return (
                <TouchableOpacity style={containerStyle} onPress={onEditPhoto}>
                    {uploadedPhotoUrl &&
                        <Image
                            source={{ uri: uploadedPhotoUrl }}
                            style={styles.imagePreview}
                        />}
                </TouchableOpacity>
            );
        }

        return <View style={containerStyle} />;
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: photoWidth,
        height: photoWidth,
        backgroundColor: colors.midGrey3,
        margin: 2.5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    photoIcon: {
        width: 41,
        height: 30,
        marginRight: -5,
    },
    imagePreview: {
        width: `100%`,
        height: `100%`,
    },
});
