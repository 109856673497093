import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';

import { Text, Spacer, Button } from '../components';

import { colors } from '../constants/theme';

import extraHelperIcon from '../assets/extra-helper-icon@3x.png';

export default class ExtraHelperModal extends Component {
    static propTypes = {
        initialHelpers: PropTypes.number.isRequired,
        onSubmit: PropTypes.func.isRequired,
        helperFee: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            tickerValue: props.initialHelpers,
        };
    }

    incrementTicker = () => {
        this.setState(state => {
            return {
                tickerValue: state.tickerValue + 1,
            };
        });
    };

    decrementTicker = () => {
        this.setState(state => {
            return {
                tickerValue: Math.max(0, state.tickerValue - 1),
            };
        });
    };

    render() {
        const { onSubmit, helperFee } = this.props;
        const { tickerValue } = this.state;

        return (
            <View style={styles.container}>
                <View style={styles.innerContainer}>
                    <Image
                        resizeMode={Image.resizeMode.contain}
                        source={{ uri: extraHelperIcon }}
                        style={{
                            width: 65,
                            height: 65,
                        }}
                    />
                    <Spacer size={15} />
                    <Text style={styles.titleText}>
                        Need An Extra Helper?
                    </Text>
                    <Spacer size={5} />
                    <Text style={styles.contentText}>
                        Extra Helpers are ${helperFee} an hour.
                    </Text>
                    <Spacer size={30} />
                    <View style={styles.tickerContainer}>
                        <TouchableOpacity onPress={this.decrementTicker}>
                            <Text style={styles.tickerText}>-</Text>
                        </TouchableOpacity>
                        <Spacer horizontal size={20} />
                        <View style={styles.tickerValueContainer}>
                            <Text style={styles.tickerValueText}>
                                {tickerValue}
                            </Text>
                        </View>
                        <Spacer horizontal size={20} />
                        <TouchableOpacity onPress={this.incrementTicker}>
                            <Text style={styles.tickerText}>+</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <Button
                    onPress={() => onSubmit({ helpers: tickerValue })}
                    muted
                >
                    CONFIRM
                </Button>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    innerContainer: {
        width: `100%`,
        backgroundColor: colors.white,
        padding: 35,
        paddingTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentContainer: {},
    titleText: {
        fontFamily: 'ClanOT-Medium',
        fontSize: 18,
    },
    contentText: {
        textAlign: 'center',
    },
    tickerContainer: {
        flexDirection: 'row',
    },
    tickerText: {
        fontFamily: 'ClanOT-Thin',
        fontSize: 34,
    },
    tickerValueContainer: {
        backgroundColor: colors.grey,
        padding: 5,
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
    tickerValueText: {
        fontSize: 20,
    },
});
