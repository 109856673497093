import { buildPhotoURL } from '../../helpers';

export default (apiBase) => trucks => {
    return trucks.map(truck => {
        const { id, name, length, width, price, image, illustration, helperFee } = truck;

        const authToken = getAuthToken();

        const { hourly = 0, timeLimit = 0 } = price;
        const minuteRate = hourly / 60;
        const basePrice = Number(minuteRate * timeLimit);

        return {
            id,
            icon: buildPhotoURL(apiBase, authToken, image),
            illustration: buildPhotoURL(apiBase, authToken, illustration),
            title: name,
            haulSize: `${width}”W x ${length}”L`,
            displayTimePer: timeLimit === 60 ? 'hour' : `${timeLimit} minutes`,
            displayTimeFor: timeLimit === 60 ? '1 hour' : `${timeLimit} minutes`,
            displayTimeThe: timeLimit === 60 ? '1 hour' : `${timeLimit} minute`,
            basePrice,
            minuteRate,
            timeLimit,
            helperFee: helperFee
        };
    });
};
