import React, { Component, createElement } from 'react';
import {
    ListView,
    StyleSheet,
    View,
    ScrollView, 
    TouchableOpacity, 
    Switch
} from 'react-native';
import moment from 'moment';

import { 
    Text, 
    NavigationHeader, 
    Spacer,
    Button,
    AnimatedCheck
} from '../components';
import { colors } from '../constants/theme';
import AddOn from '../../lib/add-on';
import PropTypes from 'prop-types';

const ds = new ListView.DataSource({
    rowHasChanged: (r1, r2) => r1 !== r2,
});

const DatePicker = ({date, minDate, onChange, style}) => {
    // Couldn't find a datepicker that would work with react-native-web that didn't require a newer version of react and/or react-native
    return (
        <View style={{height: 40}}>
            {createElement('input', {
                    type: 'date',
                    value: date || '',
                    min: minDate,
                    onInput: onChange,
                    style
                })}
        </View>
    )
}

const BaseAddOnRow = ({ renderControls }) => (
    <View style={{ width: '100%' }}>
        <View style={styles.addOnRow}>
            <View style={styles.renderControls}>
                {renderControls}
            </View>
        </View>
    </View>
);

const IntegerAddOnRow = ({addOn, setAddOn}) => (
    <BaseAddOnRow
        renderControls={(
            <View style={styles.integerControls}>
                <TouchableOpacity
                    style={styles.integerButton}
                    onPress={() => setAddOn(addOn.id, addOn.value - 1)}
                >
                    <Text style={styles.integerButtonText}>-</Text>
                </TouchableOpacity>
                <Text style={styles.integerDisplay}>{addOn.value}</Text>
                <TouchableOpacity
                    style={styles.integerButton}
                    onPress={() => setAddOn(addOn.id, addOn.value + 1)}
                >
                    <Text style={styles.integerButtonText}>+</Text>
                </TouchableOpacity>
            </View>
        )}
    />
);

const ScheduledAddOnRow = ({addOn, setAddOn}) => {
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    return (
        <View style={{ width: '100%' }}>
            {!!addOn.value && (
                <View style={{ width: '100%' }}>
                    <View style={{ width: '100%', padding: 10 }}>
                        <View style={styles.schedulingInputRow}>
                            <DatePicker
                                style={{ backgroundColor: colors.creamWhite, paddingLeft: 10, paddingRight: 10, fontFamily: 'SctoGroteskA-Regular', border: 'none', borderRadius: 4, fontSize: 18, flex: 1, width: 'auto' }}
                                date={addOn.value.date}
                                minDate={tomorrow}
                                onChange={(event) => setAddOn(addOn.id, { ...addOn.value, date: event.target.value })}
                            />
                            <Spacer size={8} />
                            <select 
                                style={{ backgroundColor: colors.creamWhite, padding: 10, fontFamily: 'SctoGroteskA-Regular', border: 'none', borderRadius: 4, fontSize: 18, minHeight: 26 }}
                                onChange={event => setAddOn(addOn.id, { ...addOn.value, time: event.target.value })}
                            >
                                <option value="" disabled selected>Pick a Time</option>
                                <option value="morning">{AddOn.SCHEDULING_OPTIONS.morning}</option>
                                <option value="afternoon">{AddOn.SCHEDULING_OPTIONS.afternoon}</option>
                                <option value="evening">{AddOn.SCHEDULING_OPTIONS.evening}</option>
                            </select>
                        </View>
                        <Text style={styles.schedulingInfoText}>Your driver will contact you to coordinate time.</Text>
                    </View>
                </View>
            )}
        </View>
    )
}

export default class ServiceDetail extends Component {
    static propTypes = {
        driveBuilder: PropTypes.any.isRequired,
        onContinue: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired
    };
    
    constructor() {
        super();

        this.state = {
            loaded: false,
            dataSource: ds.cloneWithRows([])
        };
    }
    componentDidMount = async () => {
        setTimeout(function() {
            this.setState({
                loaded: true
            });
        }.bind(this), 500);

        this.setState({
            dataSource: ds.cloneWithRows(this.props.driveBuilder.addOns),
        });
    }

    renderRow = addOn => {
        const { name, optionType, description, id } = addOn;
        const { setAddOn, driveBuilder } = this.props;
        const price = driveBuilder.calcAddOnBasePrice(addOn);

        console.log(addOn.value)

        return (
            <View style={styles.rowContainer}>
                {(optionType === AddOn.OPTION_TYPES.boolean && addOn.value) && (
                    <AnimatedCheck size={30} />
                )}
                {(optionType === AddOn.OPTION_TYPES.integer && addOn.value > 0) && (
                    <AnimatedCheck size={30} />
                )}
                {(optionType === AddOn.OPTION_TYPES.scheduled && !!addOn.value) && (
                    <AnimatedCheck size={30} />
                )}
                <View style={{flex: 1}}>
                    {optionType === AddOn.OPTION_TYPES.integer && (
                        <Text style={styles.rowTitle}>Need an extra {name}?</Text>
                    )}
                    {optionType === AddOn.OPTION_TYPES.boolean && (
                        <Text style={styles.rowTitle}>Need a {name}?</Text>
                    )}
                    {optionType === AddOn.OPTION_TYPES.scheduled && (
                        <Text style={styles.rowTitle}>{name}</Text>
                    )}
                    <Spacer size={5} />
                    {optionType === AddOn.OPTION_TYPES.integer && (
                            <IntegerAddOnRow addOn={addOn} setAddOn={setAddOn} price={price} key={addOn.id} />
                        )
                    }
                    {optionType === AddOn.OPTION_TYPES.scheduled && (
                            <ScheduledAddOnRow addOn={addOn} setAddOn={setAddOn} price={price} key={addOn.id} />
                        )
                    }
                    <Text style={styles.rowPrice}>${price}</Text>
                    <Text style={styles.rowDescription}>{description}</Text>
                    <Spacer size={15} />
                </View>
                {optionType === AddOn.OPTION_TYPES.boolean && (
                    <View style={styles.rowFooter}>
                        <Button
                            onPress={() => setAddOn(addOn.id, !addOn.value)}
                            style={[addOn.value ? styles.greyCircleButton : styles.whiteCircleButton, styles.circleButton, {height: 48}]}
                            color={addOn.value ? colors.white : colors.darkGrey2}
                            height={48}
                        >
                            {addOn.value ? 'Selected' : 'Select'}
                        </Button>
                    </View>
                )}
                {optionType === AddOn.OPTION_TYPES.scheduled && (
                    <View style={styles.rowFooter}>
                        <Button
                            onPress={() => setAddOn(addOn.id, !addOn.value ? { date: null, time: null } : null)}
                            style={[addOn.value ? styles.greyCircleButton : styles.whiteCircleButton, styles.circleButton, {height: 48}]}
                            color={addOn.value ? colors.white : colors.darkGrey2}
                            height={48}
                        >
                            {addOn.value ? 'Selected' : 'Select'}
                        </Button>
                    </View>
                )}
            </View>
        );
    };

    render() {
        const { goBack, onContinue } = this.props;
        return (
            <View style={styles.container}>
                <NavigationHeader 
                    goBack={goBack} 
                    titleText={'Additional Services'}
                    style={{backgroundColor: colors.creamWhite}}
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <Text style={styles.titleSubText}>
                            Add additional services
                        </Text>
                        <Spacer size={15} />
                        <ListView
                            style={styles.listContainer}
                            dataSource={this.state.dataSource}
                            renderRow={this.renderRow}
                        />
                    </View>
                    <View style={styles.footer}>
                        <Button
                            onPress={() => onContinue()}
                            style={styles.greenCircleButton}
                            color={colors.white}
                        >
                            Continue
                        </Button>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    schedulingInfoText: {
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'SctoGroteskA-Regular',
        marginTop: 5
    },
    schedulingInput: {
        backgroundColor: colors.creamWhite,
        padding: 10,
        fontFamily: 'SctoGroteskA-Regular',
        border: 'none',
        borderRadius: 4,
        fontSize: 18,
        minHeight: 26
    },
    renderControls: {
        marginBottom: 25
    },
    integerControls: {
        flexDirection: 'row',
        width: 172,
        margin: 'auto'
    },
    integerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.green,
        width: 36,
        height: 36,
        borderRadius: 4
    },
    integerButtonDisabled: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.grey,
        width: 36,
        height: 36,
        borderRadius: 4
    },
    integerButtonText: {
        fontSize: 24,
        color: colors.white
    },
    integerDisplay: {
        textAlign: 'center',
        flex: 1,
        margin: 'auto',
        color: colors.darkGrey2,
        fontSize: 26
    },
    body: {
        width: `100%`,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        flex: 1
    },
    titleText: {
        fontSize: 26,
        textAlign: 'center',
        marginLeft: 30,
        marginRight: 30
    },
    titleSubText: {
        fontSize: 18,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    container: {
        flex: 1,
        backgroundColor: colors.creamWhite,
    },
    rowContainer: {
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 400,
        shadowColor: colors.black,
        shadowOpacity: 0.21,
        shadowRadius: 6,
        elevation: 16,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 22,
        paddingRight: 22, 
        marginTop: 10,
        marginBottom: 10
    },
    rowTitle: {
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        fontFamily: 'Recoleta-Semibold',
        marginBottom: 10,
        marginHorizontal: 30
    },
    rowPrice: {
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        marginBottom: 5,
        fontFamily: 'Recoleta-Semibold',
    },
    rowDescription: {
        fontSize: 18,
        color: colors.darkGrey2,
        textAlign: 'center',
    },
    rowButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        backgroundColor: colors.white,
        borderRadius: 30
    },
    rowFooter: {
        justifyContent: 'flex-end'
    },
    footer: {
        padding: 20,
        justifyContent: 'flex-end'
    },
    listContainer: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20
    },
    greenCircleButton: {
        backgroundColor: colors.green,
        borderRadius: 30
    },
    whiteCircleButton: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        backgroundColor: colors.white,
        borderRadius: 30
    },
    greyCircleButton: {
        backgroundColor: colors.darkGrey2,
        borderRadius: 30
    }
});