import { toClientEntity, toServerEntity } from '../../helpers';

export default class AddOnAdapter {
    constructor(api) {
        this._api = api;
    }

    async getAvailableAddOns(cityId) {
        const addOns = await _resolve(this._api.get(`/add-ons/city/${cityId}`));

        if (addOns.error) return errorObject(addOns.error);

        return resultObject(addOns.result.filter(addOn => addOn.active));
    }
}