import React from 'react';

import { InfoModal } from '../modals';

export default () => (
    <InfoModal
        title={'Value & Weight'}
        body={
            'Enter the estimated total value and weight for your item(s).\n\nEx. If you have 5 chairs that weigh 10 lbs each, and $50 per chair, the total value is $250 and total weight is 50 lbs.'
        }
    />
);
