export default class Coupon {
    constructor(args) {
        this.store = args.store;
    }

    initLib(lib) {
        this.lib = lib;
    }

    async checkIfCouponIsValid(name, originCityId, presenter) {
        let coupon = await _resolve(this.store.checkIfCouponIsValid(name, originCityId));

        if (coupon.error) {
            return {
                error: coupon.error,
            };
        }

        coupon = coupon.result;

        return {
            result: presenter(coupon),
        };
    }
}
