import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';

import { Text, LargeLogo } from '../components';

import { colors } from '../constants/theme';

class SideMenu extends React.Component {
    static propTypes = {
        menuItems: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    };

    _renderRow = (label, action, footer = false) => {
        const { onSelect } = this.props;

        return (
            <TouchableOpacity key={label} onPress={() => onSelect(action)}>
                <View style={styles.rowContainer}>
                    <Text style={footer ? styles.smallRowText : styles.rowText}>
                        {label}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    render() {
        const { menuItems, onClose } = this.props;

        const mainMenuItems = menuItems.filter(item => item.section === 'main');
        const footerMenuItems = menuItems.filter(
            item => item.section === 'footer'
        );

        return (
            <View style={styles.container}>
                <View style={styles.closeButton}>
                    <TouchableOpacity style={{height: 26}} onPress={onClose}>
                        <Text style={{fontSize: 16, color: colors.white, margin: 'auto', fontFamily: 'SctoGroteskA-Regular'}}>X</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.logoContainer}>
                    <LargeLogo style={{height: 40, width: 100}} />
                </View>
                <ScrollView style={styles.scrollView} contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}}>
                    <View style={styles.menuItemsContainer}>
                        {mainMenuItems.map(item => {
                            return this._renderRow(item.label, item.action);
                        })}
                        <View style={styles.spacer} />
                        <View style={styles.footerContainer}>
                            {footerMenuItems.map(item => {
                                return this._renderRow(item.label, item.action, true);
                            })}
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: colors.darkGrey2,
    },
    scrollView: {
        backgroundColor: colors.darkGrey2,
    },
    logoContainer: {
        marginTop: 84,
        marginBottom: 40,
        display: 'block',
        marginHorizontal: 20
    },
    menuItemsContainer: {
        flex: 1,
        paddingHorizontal: 20,
        paddingBottom: 20
    },
    rowContainer: {
        paddingVertical: 5
    },
    rowText: {
        color: colors.white,
        fontSize: 34,
        fontFamily: 'Recoleta-Regular',
    },
    spacer: {
        flex: 1,
    },
    closeButton: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 15,
        width: 26,
        height: 26,
        position: 'absolute',
        right: 0,
        marginRight: 20,
        marginTop: 28
    },
    smallRowText: {
        fontSize: 15,
        color: colors.white
    },
    footerContainer: {
        marginTop: 25
    }
});

module.exports = SideMenu;