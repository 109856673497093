import React from 'react';

import { InfoModal } from '../modals';

export default () => (
    <InfoModal
        title={'CVC'}
        body={
            'The CVC on your credit card is a 3-4 digit number usually ' +
                'printed on the signature side area of the credit card.'
        }
    />
);
