import React from 'react';
import {
    StyleSheet,
    View
} from 'react-native';

const AnimatedCheck = ({ style = {}, size }) => {
    return (
        <View style={[{height: size, width: size}, styles.container]}>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" style={{width: size, height: size}}>
                <circle className="checkmark_circle" cx={size} cy={size} r="25" fill="none" />
                <path className="checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        marginRight: 20,
        right: 0
    }
});

export default AnimatedCheck;
