import '../global';

import React from 'react';
import {
    View,
    Platform,
    TextInput,
    ActivityIndicator,
} from 'react-native';

import composeLib from './compose-lib';

import Drawer from 'react-native-drawer';
import { SideMenu, Modal } from './components';

import { backButtonHandler, routerContainer } from './helpers';

import { colors } from './constants/theme';

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.composeLib();
    }

    state = {
        rootNavigator: View,
        menuItems: null,
        menuState: 'closed',
        modal: null,
        loading: false,
    };

    composeLib() {
        this.lib = composeLib({
            routerContainer,
            routerProps: {
                handleToggleMenu: this.handleToggleMenu,
                handleLogout: this.handleLogout,
                handleShowModal: this.handleShowModal,
                handleDismissModal: this.handleDismissModal,
                handleBeginLoading: this.handleBeginLoading,
                handleEndLoading: this.handleEndLoading,
                handleShowAlert: this.handleShowAlert,
            },
        });
    }

    async componentDidMount() {
        this.setupMenuAndRootNavigator();

        backButtonHandler({
            onBackButtonPress: () =>
                this.lib.router.goBack(this._rootNavigator),
        });
    }

    async setupMenuAndRootNavigator() {
        const menuItems = await this.lib.router.getSideMenuItems();
        const rootNavigator = await this.lib.router.getInitialRoot();

        this.setState({
            menuItems,
            rootNavigator,
            modal: null,
            loading: false,
        });
    }

    handleLogout = () => {
        document.location.href = '/';
    };

    handleToggleMenu = () => {
        TextInput.State.blurTextInput(TextInput.State.currentlyFocusedField());

        this.drawer.toggle();
    };

    handleMenuSelect = action => {
        this.handleToggleMenu();

        action(this._rootNavigator);
    };

    handleShowModal = (component, passProps = {}, persistent = false) => {
        this.setState({
            modal: {
                component,
                passProps,
                persistent
            },
        });
    };

    handleDismissModal = () => {
        this.modal.animateOut(() => {
            this.setState({ modal: null });
            this.modal = null;
        });
    };

    handleBeginLoading = () => {
        this.setState({ loading: true });
    };

    handleEndLoading = () => {
        this.setState({ loading: false });
    };

    handleShowAlert = ({ title, message }) => {
        if (Platform.OS === 'web') {
            alert(`${title}: ${message}`);
        }
        // TODO(dave): Native alert here, or Alert modal...
    };

    render() {
        const {
            modal,
            rootNavigator: RootNavigator,
            menuItems,
            menuState,
            loading,
        } = this.state;

        if (menuItems === null) {
            return <View />;
        }

        let maxWidthStyle = {};

        if (Platform.OS === 'web') {
            maxWidthStyle = {
                maxWidth: 800,
                overflow: 'hidden',
            };
        }

        return (
            <View style={{ flex: 1, ...maxWidthStyle }}>
                <Drawer
                    ref={c => (this.drawer = c)}
                    type={drawerType}
                    open={menuState === 'open'}
                    onOpen={() => this.setState({ menuState: 'open' })}
                    onClose={() => this.setState({ menuState: 'closed' })}
                    content={
                        <SideMenu
                            menuItems={menuItems}
                            onSelect={this.handleMenuSelect}
                            onClose={() => this.setState({ menuState: 'closed' })}
                        />
                    }
                    openDrawerOffset={0}
                    acceptPan={false}
                    negotiatePan={
                        menuState === 'closed' && Platform.OS === 'ios'
                            ? true
                            : false
                    }
                    panOpenMask={menuState === 'closed' ? 0.2 : null}
                    tapToClose
                    styles={styles.drawer}
                    tweenDuration={tweenDuration}
                    tweenHandler={tweenHandler}
                    tweenEasing="easeInOutCirc"
                    side="left"
                >
                    <RootNavigator ref={c => (this._rootNavigator = c)} />
                </Drawer>
                {modal &&
                    <Modal
                        ref={c => (this.modal = c)}
                        onDismiss={this.handleDismissModal}
                        {...modal}
                    />}
                {loading &&
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator
                            color={colors.green}
                            size={'large'}
                        />
                    </View>}
            </View>
        );
    }
}

const drawerType = Platform.select({
    web: 'overlay',
    ios: 'static',
    android: 'overlay',
});

const tweenDuration = Platform.select({
    web: 350,
    ios: 110,
    android: 350,
});

const tweenHandler = Platform.select({
    web: ratio => {
        return {
            mainOverlay: {
                backgroundColor: colors.black,
                opacity: ratio / 2,
                display: ratio === 0 ? 'none' : 'block',
            },
        };
    },
    ios: Drawer.tweenPresets.parallax,
    android: ratio => {
        return {
            drawer: {
                elevation: 16,
            },
            mainOverlay: {
                backgroundColor: colors.black,
                opacity: ratio / 2,
            },
        };
    },
});

const styles = {
    loadingContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.black,
        opacity: 0.5,
    },
    drawer: {
        ...Platform.select({
            ios: {
                main: {
                    shadowColor: colors.black,
                    shadowOpacity: 0.4,
                    shadowRadius: 10,
                },
            },
            android: {
                drawer: {
                    shadowColor: colors.black,
                    shadowOpacity: 0.8,
                    shadowRadius: 3,
                    elevation: 16,
                },
            },
        }),
    },
};
