import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    View,
    TouchableOpacity,
    StyleSheet,
    ActivityIndicator,
} from 'react-native';

import { Spacer, Text } from '../components';
import { colors } from '../constants/theme';

export default class Button extends Component {
    static propTypes = {
        children: PropTypes.any,
        style: PropTypes.any,
        height: PropTypes.number,
        onPress: PropTypes.func,
        type: PropTypes.string,
        muted: PropTypes.bool,
        disabled: PropTypes.bool,
        inverted: PropTypes.bool,
        dark: PropTypes.bool,
        lightBorder: PropTypes.bool,
        textAlign: PropTypes.string,
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
        loading: PropTypes.bool,
        renderLeft: PropTypes.func,
        renderRight: PropTypes.func,
        fontSize: PropTypes.number
    };

    static defaultProps = {
        type: 'primary',
        textAlign: 'center',
        height: 54,
    };

    render() {
        const {
            children,
            style,
            height,
            onPress,
            type,
            muted,
            disabled,
            inverted,
            textAlign,
            color,
            backgroundColor,
            loading,
            dark,
            lightBorder,
            fontSize = 21
        } = this.props;

        let { renderLeft, renderRight } = this.props;

        const containerStyle = [
            styles.container,
            { height },
            type === 'primary'
                ? styles.containerPrimary
                : styles.containerSecondary,
            muted && type === 'primary' ? styles.mutedPrimary : null,
            muted && type === 'secondary' ? styles.mutedSecondary : null,
            inverted && type === 'secondary' ? styles.containerInverted : null,
            backgroundColor ? { backgroundColor } : null,
            lightBorder ? { borderColor: colors.midGrey } : null,
        ];

        const textStyle = [
            styles.text,
            { textAlign },
            inverted ? styles.textInverted : null,
            color ? { color } : null,
        ];

        return (
            <View style={[{ height }, style]}>
                <TouchableOpacity style={containerStyle} onPress={onPress} disabled={disabled}>
                    {renderLeft && renderLeft()}
                    {renderLeft && <Spacer horizontal size={10} />}
                    {loading
                        ? <ActivityIndicator
                              style={styles.activityIndicator}
                              color={dark ? '#373737' : colors.white}
                          />
                        : <Text style={[textStyle, { fontSize }]}>
                              {children}
                          </Text>}
                    {renderRight && <Spacer horizontal size={10} />}
                    {renderRight && renderRight()}
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        // borderRadius: 2,
        alignItems: 'center',
        paddingHorizontal: 20,
        flexDirection: 'row',
    },
    containerPrimary: {
        backgroundColor: colors.accent,
    },
    containerSecondary: {
        borderWidth: 1,
        borderColor: colors.darkGrey,
    },
    containerInverted: {
        borderWidth: 1,
        borderColor: colors.darkGrey,
    },
    text: {
        flex: 1,
        fontSize: 18,
        color: colors.white,
        textAlign: 'center',
    },
    textInverted: {
        color: 'black',
    },
    mutedPrimary: {
        backgroundColor: colors.green,
    },
    mutedSecondary: {
        borderColor: colors.darkGrey,
    },
    activityIndicator: {
        flex: 1,
    },
});

// ComponentRegistry.register(() => <Button type={'primary'}>Button</Button>);
// ComponentRegistry.register(() => <Button type={'secondary'}>Button</Button>);
// ComponentRegistry.register(() => (
//     <Button type={'primary'} muted>Button</Button>
// ));
// ComponentRegistry.register(() => (
//     <Button type={'secondary'} muted>Button</Button>
// ));
// ComponentRegistry.register(() => (
//     <Button
//         type={'secondary'}
//         textAlign={'left'}
//         iconLeft={'FontAwesome/phone'}
//     >
//         Button
//     </Button>
// ));
// ComponentRegistry.register(() => (
//     <Button
//         type={'primary'}
//         muted
//         renderLeft={() => (
//             <Icon name={'FontAwesome/phone'} size={20} color={'white'} />
//         )}
//         renderRight={() => <Spacer horizontal size={20} />}
//     >
//         Button
//     </Button>
// ));
