import User from './user';
import Truck from './truck';
import Drive from './drive';
import Coupon from './coupon';
import City from './city';
import AddOn from './add-on';

export default {
    User,
    Truck,
    Drive,
    Coupon,
    City,
    AddOn,
};
