import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, View } from 'react-native';

import { colors } from '../constants/theme';

export default class RouteProgress extends Component {
    static propTypes = {
        status: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { status } = this.props;
        let pillColor = styles.blank;
        let numberToColor = 0;

        switch (status) {
            case "accepted":
                pillColor = styles.green;
                numberToColor = 1;
                break;
            case "enRoute":
                pillColor = styles.green;
                numberToColor = 2;
                break;
            case "ordered":
                pillColor = styles.blank;
                numberToColor = 3;
                break;
            case "complete":
                pillColor = styles.lightRed;
                numberToColor = 3;
                break;
            default:
                pillColor = styles.blank;
                numberToColor = 3;
        }
        return (
            <View style={{flexDirection: 'row'}}>
                {[0, 1, 2].map(index => {
                    let pillType = styles.pillStart;
                    if (index === 1) {
                        pillType = styles.pillMiddle;
                    } else if (index === 2) {
                        pillType = styles.pillEnd;
                    }

                    if (numberToColor - (index+1) < 0) {
                        pillColor = styles.blank;
                    }
                    return (
                        <View style={[styles.pill, pillType, pillColor]} />
                    )
                })}
            </View>
        )
    }
};

const styles = StyleSheet.create({
    pill: {
        width: 52,
        height: 10
    },
    pillMiddle: {
        marginHorizontal: 2
    },
    pillStart: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    pillEnd: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    green: {
        backgroundColor: colors.green
    },
    lightRed: {
        backgroundColor: colors.lightRed
    },
    blank: {
        backgroundColor: colors.midGrey3
    }
});