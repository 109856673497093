import React from 'react';

import { Text } from 'react-native';

import { colors } from '../constants/theme';

const defaultStyle = { fontFamily: 'SctoGroteskA-Regular', color: colors.darkGrey };

const AppText = ({ style, ...props }) => (
    <Text style={[defaultStyle, style]} {...props} />
);

export default AppText;
