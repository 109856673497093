import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import loadImage from 'blueimp-load-image';

import '../helpers/canvas-to-blob';

export default class ImageInput extends Component {
    static propTypes = {
        onImageChange: PropTypes.func.isRequired,
        style: PropTypes.any,
        beginLoading: PropTypes.func.isRequired,
    };

    handleImageChange = async e => {
        this.props.beginLoading();

        loadImage(
            e.target.files[0],
            (image, meta) => {
                image.toBlob(blob => {
                    this.props.onImageChange(blob);
                }, 'image/jpeg', 0.75);
            },
            {
                orientation: true,
                canvas: true,
                meta: true,
            }
        );
    };

    render() {
        return (
            <TouchableOpacity
                style={this.props.style}
                onPress={() => this.refs.imageInput.click()}
            >
                <input
                    id="file"
                    ref="imageInput"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    onChange={this.handleImageChange}
                />
                {this.props.children}
            </TouchableOpacity>
        );
    }
}
