import { buildPhotoURL } from '../../helpers';

export default (apiBase) => photoIds => {
    const authToken = getAuthToken();
    return photoIds.map(photoId => {
        return {
            id: photoId,
            url: buildPhotoURL(apiBase, authToken, photoId),
        };
    });
};
