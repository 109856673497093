import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Spacer } from '../components';

import { colors } from '../constants/theme';

export default ({ title, body }) => (
    <View style={styles.container}>
        <View style={styles.innerContainer}>
            <Text style={styles.titleText}>
                {title}
            </Text>
            <Spacer size={15} />
            <Text style={styles.contentText}>
                {body}
            </Text>
        </View>
    </View>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    innerContainer: {
        width: `100%`,
        backgroundColor: colors.white,
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 30,
        justifyContent: 'center',
    },
    titleText: {
        fontSize: 21,
        fontFamily: 'Recoleta-Semibold',
        color: colors.darkGrey2,
    },
    contentText: {
        fontSize: 18,
        color: colors.darkGrey2,
    },
});
