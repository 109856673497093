import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    TouchableOpacity,
    StyleSheet,
    View,
    ScrollView,
    Image,
    Platform,
    ActivityIndicator,
    TextInput
} from 'react-native';

import {
    MenuButton,
    LocationMap,
    Button,
    Input,
    Text,
    Spacer,
} from '../components';

import {blurAllFields} from '../helpers/blur-all-fields';

import { colors } from '../constants/theme';

import originIcon from '../assets/origin@3x.png';
import destinationIcon from '../assets/destination@3x.png';
import locationIcon from '../assets/location-icon@3x.png';
import activeDeliveryIcon from '../assets/active-delivery-icon@3x.png';
import rowArrowWhite from '../assets/row-arrow-white@3x.png';

const handleGeocodeStatus = (status, reasonForGeocode) => {
    // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingStatusCodes
    if (status === "ZERO_RESULTS") {
        return `Unable to ${reasonForGeocode} - No results found`;
    }
    if (status === "INVALID_REQUEST") {
        return `Unable to ${reasonForGeocode} - Please check address provided and contact support if the error persists`;
    }
    if (status === "ERROR") {
        return `Unable to ${reasonForGeocode} - Please try again and contact support if the error persists`;
    }
    // Other statuses should probably not be shown to the user - making up codes for our own reference when errors are reported
    if (status === "OVER_QUERY_LIMIT") {
        return `Unable to ${reasonForGeocode} - error code 1`;
    }
    if (status === "REQUEST_DENIED") {
        return `Unable to ${reasonForGeocode} - error code 2`;
    }
    if (status === "UNKNOWN_ERROR") {
        return `Unable to ${reasonForGeocode} - error code 3`;
    }
    return null;
}

const getCenterForAddress = (address, bounds, callback) => {
    const Geocoder = new google.maps.Geocoder();

    Geocoder.geocode({ address, bounds }, (results, status) => {
        const geocodeErrorMessage = handleGeocodeStatus(status, 'get coordinates for address');

        if (geocodeErrorMessage) {
            return callback(null, new Error(geocodeErrorMessage));
        }

        const center = results[0].geometry.location;

        callback(center, null);
    });
};

const getAddressForLocation = (location, bounds, callback) => {
    const Geocoder = new google.maps.Geocoder();
    
    Geocoder.geocode({ location, bounds }, (results, status) => {
        if (status !== "OK") {
            console.error(`Geocode error: ${status}`);
            return callback(null, new Error('Could not geocode address for this location - Please try entering manually'));
        }
        const address = results[0].formatted_address;

        callback(address);
    });
};

const getVerifiedAddressForAddress = (address, bounds, callback) => {
    const Geocoder = new google.maps.Geocoder();

    Geocoder.geocode({ address, bounds }, (results, status) => {
        const geocodeErrorMessage = handleGeocodeStatus(status, 'verify address');

        if (geocodeErrorMessage) {
            return callback(null, new Error(geocodeErrorMessage));
        }

        const verifiedAddress = results[0];
        const addressComponents = verifiedAddress.address_components;
        const extract = extractFromAddress(addressComponents);

        callback({
            formattedAddress: verifiedAddress.formatted_address,
            street: extract('street_number') + ' ' + extract('route'),
            city: extract('locality')
                    ? extract('locality') // city
                    : extract('administrative_area_level_3') // sometimes also the city
                    ? extract('administrative_area_level_3')
                    : extract('neighborhood') // sometimes also the city (i.e. fisherville, ky)
                    ? extract('neighborhood')
                    : extract('administrative_area_level_2'), // probably the county
            state: extract('administrative_area_level_1'),
            zip: extract('postal_code'),
        });
    });
};

const extractFromAddress = components => type => {
    return (
        components
            .filter(component => component.types.indexOf(type) === 0)
            .map(item => item.long_name)
            .pop() || null
    );
};

const geolocation = navigator.geolocation
    ? navigator.geolocation
    : {
          getCurrentPosition(success, failure) {
              failure(`Your browser doesn't support geolocation.`);
          },
      };

export default class LocationScreen extends PureComponent {
    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired,
        onContinue: PropTypes.func.isRequired,
        onOutOfCoverageArea: PropTypes.func.isRequired,
        checkIfActiveDeliveryIsInProgress: PropTypes.func.isRequired,
        onActiveDeliveryPressed: PropTypes.func.isRequired,
        onGetCity: PropTypes.func.isRequired,
        onLocationSpecialInstructions: PropTypes.func.isRequired,
        onCheckHowYouHeard: PropTypes.func.isRequired
    };

    isUnmounted = false;

    state = {
        // origin: '221 E Fourth Street, Cincinnati, 45242, OH',
        // destination: '10732 Adventure Lane, Cincinnati, 45242, OH',
        origin: '',
        originFinal: '',
        originObject: {},
        originValid: false,

        destination: '',
        destinationFinal: '',
        destinationObject: {},
        destinationValid: false,

        currentLocationLoading: false,
        isActiveDeliveryInProgress: false,
        bounds: undefined,
        isValidatingAddress: false,
    };

    isLocationWithinServiceCity = async (location, callback) => {
        const city = await this.props.onGetCity(location);

        callback(city.result, city.error);
    };

    handleBoundsChange = (newBounds) => {
        this.setState({
            bounds: newBounds
        });
    }

    async componentDidMount() {
        const { checkIfActiveDeliveryIsInProgress, onCheckHowYouHeard } = this.props;

        const isActiveDeliveryInProgress = await checkIfActiveDeliveryIsInProgress();

        await onCheckHowYouHeard();

        this.setState({
            isActiveDeliveryInProgress,
        });
    };

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    handleInputChange = drivePoint => e => {
        let { value: address } = e.target;
        if (address !== '') {
            this.validateAddress(drivePoint, address, (newAddressState, validateError) => {
                if (validateError && validateError.message) {
                    alert(validateError.message);
                }
                this.setState(newAddressState);
            });
        } else {
            this.setState({
                [`${drivePoint}Final`]: address,
            });
        }
    };

    validateAddress = (drivePoint, address, callback) => {
        const { onOutOfCoverageArea } = this.props;
        const bounds = this.state.bounds;

        this.setState({ isValidatingAddress: true });

        const handleValidationError = (error) => {
            callback({
                [drivePoint]: address,
                [`${drivePoint}Final`]: address,
                [`${drivePoint}Valid`]: false,
                isValidatingAddress: false,
            }, error);
        }

        getCenterForAddress(address, bounds, (location, centerError) => {
            if (centerError) {
                return handleValidationError(centerError);
            }
            this.isLocationWithinServiceCity(location, (city, cityError) => {
                if (cityError) {
                    console.error(cityError);
                    return handleValidationError(new Error("Could not determine service city - Unknown error"));
                }

                if (!city) {
                    onOutOfCoverageArea({ address });

                    return handleValidationError(new Error("")); // We are showing the out of coverage modal so no alert message is needed
                }

                getVerifiedAddressForAddress(address, bounds, (verifiedAddress, verifyError) => {
                    if (verifyError) {
                        return handleValidationError(verifyError);
                    }
                    const { formattedAddress } = verifiedAddress;

                    verifiedAddress.serviceCityId = city.id;

                    delete verifiedAddress.formattedAddress;

                    callback({
                        [drivePoint]: formattedAddress,
                        [`${drivePoint}Final`]: formattedAddress,
                        [`${drivePoint}Valid`]: true,
                        [`${drivePoint}Object`]: verifiedAddress,
                        isValidatingAddress: false,
                    });
                });
            });
        });
    };

    handleGetCurrentLocation = (triggeredByUserInput) => {
        const { currentLocationLoading } = this.state;

        if (this.isUnmounted) {
            return;
        }

        if (!currentLocationLoading) {
            this.setState({ currentLocationLoading: true });

            geolocation.getCurrentPosition(
                position => {
                    if (this.isUnmounted) {
                        return;
                    }

                    getAddressForLocation(
                        {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                        this.state.bounds,
                        (address, addressError) => {
                            if (addressError) {
                                this.setState({ currentLocationLoading: false });
                                if (addressError.message) {
                                    if (triggeredByUserInput) {
                                        alert(addressError.message);
                                    } else {
                                        console.error(addressError.message);
                                    }
                                }
                                return;
                            }
                            this.validateAddress('origin', address, (newAddressState, validateError) => {
                                this.setState({ currentLocationLoading: false });
                                if (validateError && validateError.message) {
                                    if (triggeredByUserInput) {
                                        alert(validateError.message);
                                    } else {
                                        console.error(validateError.message);
                                    }
                                }
                                this.setState({
                                    ...newAddressState
                                });
                            });
                        }
                    );
                },
                reason => {
                    if (this.isUnmounted) {
                        return;
                    }

                    this.setState({ currentLocationLoading: false });
                    
                    if (Platform.OS === 'web') {
                        if (reason.code === reason.PERMISSION_DENIED && triggeredByUserInput) {
                            alert('Geolocation is blocked - please see your browser settings to use this feature');
                        }
                        else if (reason.code !== reason.PERMISSION_DENIED) {
                            alert(
                                'Sorry, your current location could not be determined, please try again.'
                            );
                        }
                    }
                }
            );
        }
    };

    handleContinue = async () => {
        blurAllFields();
        const { onContinue } = this.props;
        const {
            origin,
            originObject,
            originValid,
            destination,
            destinationObject,
            destinationValid
        } = this.state;

        if (!(originValid && destinationValid && origin && destination)) {
            return alert('You must enter a valid origin & destination address inside of the coverage area.');
        }
        // if (originObject.serviceCityId !== destinationObject.serviceCityId) {
        //     return alert('Drives must start and end within one coverage area');
        // }

        const originBusiness = await this.props.checkForSpecialInstructions(origin);
        const destinationBusiness = await this.props.checkForSpecialInstructions(destination);

        originObject.business = originBusiness;
        destinationObject.business = destinationBusiness;

        const continueWithLocations = () => onContinue({
            origin: originObject,
            destination: destinationObject
        });

        const hasSpecialInstructions = 
            (originBusiness && originBusiness.consumerNotes) ||
            (destinationBusiness && destinationBusiness.consumerNotes);

        if (hasSpecialInstructions) {
            this.props.onLocationSpecialInstructions({
                originBusiness,
                destinationBusiness,
                onContinue: continueWithLocations
            });
        } else {
            continueWithLocations();
        }
    };

    render() {
        const { onToggleMenu, onActiveDeliveryPressed } = this.props;
        const {
            origin,
            originFinal,
            originValid,
            destination,
            destinationFinal,
            destinationValid,
            currentLocationLoading,
            isActiveDeliveryInProgress,
            isValidatingAddress,
        } = this.state;

        return (
            <View style={styles.container}>
                <ScrollView style={styles.mapContainer}>
                    <LocationMap
                        origin={originFinal}
                        destination={destinationFinal}
                        onMapLoad={this.map}
                        onMapMounted={this.handleGetCurrentLocation}
                        onBoundsChanged={this.handleBoundsChange}
                    />
                </ScrollView>
                <View
                    style={styles.mainContentContainer}
                    pointerEvents="box-none"
                >
                    <View style={styles.menuButtonContainer}>
                        <MenuButton onPress={onToggleMenu} />
                    </View>
                    <View style={styles.inputContainer}>
                        <Image
                            resizeMode={Image.resizeMode.contain}
                            source={{ uri: originIcon }}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                        />
                        <Input
                            type={'primary'}
                            ref={'origin'}
                            style={styles.input}
                            placeholderTextColor={'dark'}
                            placeholder={'Enter Origin Location'}
                            onChangeText={origin => this.setState({ origin, originValid: false })}
                            onSubmitEditing={this.handleInputChange('origin')}
                            onBlur={this.handleInputChange('origin')}
                            value={origin}
                        />
                    </View>
                    <View style={styles.inputContainer}>
                        <Image
                            resizeMode={Image.resizeMode.contain}
                            source={{ uri: destinationIcon }}
                            style={{ width: 20, height: 20 }}
                        />

                        <Input
                            type={'primary'}
                            ref={'destination'}
                            style={styles.input}
                            placeholderTextColor={'dark'}
                            placeholder={'Enter Delivery Location'}
                            onChangeText={destination =>
                                this.setState({ destination, destinationValid: false })}
                            onSubmitEditing={this.handleInputChange(
                                'destination'
                            )}
                            onBlur={this.handleInputChange('destination')}
                            value={destination}
                        />
                    </View>
                    <TouchableOpacity
                        style={styles.currentLocationContainer}
                        onPress={() => this.handleGetCurrentLocation(true)}
                    >
                        {currentLocationLoading
                            ? <ActivityIndicator color={colors.green} />
                            : <Image
                                  source={{ uri: locationIcon }}
                                  style={{ width: 25, height: 25 }}
                              />}
                    </TouchableOpacity>
                    {isActiveDeliveryInProgress &&
                        <View style={styles.activeDeliveryContainer}>
                            <TouchableOpacity
                                style={styles.activeDeliveryButton}
                                onPress={onActiveDeliveryPressed}
                            >
                                <View style={styles.activeDeliveryInside}>
                                    <Image
                                        source={{ uri: activeDeliveryIcon }}
                                        style={{ width: 20, height: 20 }}
                                    />
                                    <Spacer horizontal size={15} />
                                    <Text
                                        style={styles.activeDeliveryText}
                                    >{`ACTIVE DELIVERY\nIN PROGRESS`}</Text>
                                    <Spacer horizontal size={15} />
                                    <Image
                                        source={{ uri: rowArrowWhite }}
                                        style={{ width: 8, height: 15 }}
                                    />
                                </View>
                                <View style={styles.activeDeliveryTriangle} />
                            </TouchableOpacity>
                        </View>}
                </View>
                <View style={styles.continueContainer}>
                    <Button 
                        onPress={this.handleContinue} 
                        style={[styles.circleButton, isValidatingAddress ? styles.disabledButton : {}]}
                        disabled={isValidatingAddress} 
                        loading={isValidatingAddress}
                        >
                        Continue
                    </Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.white,
    },
    mainContentContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 94,
        padding: 25,
    },
    menuButtonContainer: {
        marginTop: 5,
        marginBottom: 13,
    },
    inputContainer: {
        flexDirection: 'row',
        backgroundColor: colors.white,
        width: `100%`,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingLeft: 20,
    },
    continueContainer: { 
        padding: 20,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        padding: 25,
    },
    currentLocationContainer: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        backgroundColor: colors.white,
        padding: 12.5,
        borderRadius: 25,
    },
    activeDeliveryContainer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
    },
    activeDeliveryButton: {
        alignItems: 'center',
    },
    activeDeliveryInside: {
        flexDirection: 'row',
        backgroundColor: colors.red,
        padding: 7.5,
        paddingHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    activeDeliveryText: {
        fontSize: 12,
        color: colors.white,
    },
    activeDeliveryTriangle: {
        backgroundColor: colors.red,
        height: 10,
        width: 10,
        marginTop: -5,
        transform: [{ rotate: '45deg' }],
    },
    input: {
        height: 54,
        color: colors.darkGrey2,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16
    },
    circleButton: {
        backgroundColor: colors.green,
        borderRadius: 30,
    },
    disabledButton: {
        backgroundColor: colors.transparentGreen,
    }
});
