import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, Image, View } from 'react-native';

import { Button, Text, LargeLogo, Spacer } from '../components';

import { colors } from '../constants/theme';

import magicLinkIllustration from '../assets/magic-hat@3x.png';

export default class Login extends Component {
    static propTypes = {
        handleGoBack: PropTypes.func.isRequired
    };

    handleGoBack = () => {
        this.props.handleGoBack();
    };

    render() {
        return (
            <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                <View style={styles.body}>
                    <LargeLogo />
                    <Spacer size={25} />
                    <Image
                        resizeMode={Image.resizeMode.contain}
                        source={{ uri: magicLinkIllustration }}
                        style={styles.illustration}
                    />
                    <Spacer size={20} />
                    <Text style={styles.instructionsText}>
                        {this.props.sender === 'register' ? "It looks like you've used Joey before. " : null} 
                        Check your email/phone for your magic link to login.
                    </Text>
                    <Text style={styles.wrongInfo}>Entered the wrong email/phone?</Text>
                </View>
                <View style={styles.footer}>
                    <Button 
                        style={styles.circleButton}
                        onPress={this.handleGoBack}>
                        Go back
                    </Button>
                </View>
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 5,
        backgroundColor: colors.green,
    },
    illustration: {
        width: 245,
        height: 175,
        alignSelf: 'center',
    },
    instructionsText: {
        textAlign: 'center',
        fontSize: 21,
        color: colors.white,
        paddingLeft: 25,
        paddingRight: 25
    },
    goBackText: {
        textAlign: 'center',
        fontSize: 16,
        color: colors.white,
        paddingLeft: 25,
        paddingRight: 25
    },
    circleButton: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 30
    },
    footer: {
        padding: 20,
        backgroundColor: colors.green,
        justifyContent: 'flex-end'
    },
    wrongInfo: {
        fontSize: 18,
        color: colors.white,
        textAlign: 'center',
        marginTop: 40
    }
});