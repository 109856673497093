export default class Business {
    constructor(args) {
        this.store = args.store;
    }

    initLib(lib) {
        this.lib = lib;
    }

    async getSpecialInstructions(address) {
        return await this.store.getSpecialInstructions(address);
    }
}