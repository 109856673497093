const STATUS_TYPES = {
    available: 'ordered',
    accepted: 'accepted',
    started: 'enRoute',
    loading: 'enRoute',
    driving: 'enRoute',
    delivered: 'complete',
    refunded: 'complete',
};

export default class Drive {
    constructor(args) {
        this.store = args.store;
        this.presenters = args.presenters;
    }

    initLib(lib) {
        this.lib = lib;
    }

    filter = async (query = {}) => {
        this.lib.router.beginLoading();

        let drives = await _resolve(
            this.store.getByUser({
                populate: 'truck',
            })
        );

        if (drives.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drives.error,
            };
        }

        drives = drives.result;

        this.lib.router.endLoading();

        return {
            result: this.presenters.all(drives),
        };
    };

    async create(data) {
        let drive = await _resolve(this.store.create(data));

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        return {
            result: drive,
        };
    }

    async update(data) {
        let drive = await _resolve(this.store.update(data));

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        return {
            result: drive,
        };
    }

    async chargeCreditCard(data) {
        let drive = await _resolve(this.store.chargeCreditCard(data));

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        return {
            result: drive,
        };
    }

    async chargeApplePay(data) {
        let drive = await _resolve(this.store.chargeApplePay(data));

        if (drive.error) {
            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        return {
            result: drive,
        };
    }

    async getById(id) {
        this.lib.router.beginLoading();

        let drive = await _resolve(this.store.getById(id));

        if (drive.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        this.lib.router.endLoading();

        return {
            result: this.presenters.details(drive),
        };
    }

    async cancel(id) {
        this.lib.router.beginLoading();

        let drive = await _resolve(
            this.store.update({ id, status: 'cancelledNoFee' })
        );

        if (drive.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        this.lib.router.endLoading();

        return {
            result: drive,
        };
    }

    isActiveDeliveryInProgress = async () => {
        let drives = await _resolve(this.store.getByUser());

        if (drives.error) {
            console.log(drives.error);
            return false;
        }

        drives = drives.result;

        let isActiveDeliveryInProgress = false;

        drives.forEach(({ status }) => {
            if (STATUS_TYPES[status] === 'enRoute') {
                isActiveDeliveryInProgress = true;
            }
        });

        return isActiveDeliveryInProgress;
    };

    rateDrive = async (id, starRating) => {
        this.lib.router.beginLoading();

        let drive = await _resolve(this.store.update({ id, starRating }));

        if (drive.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error,
            };
        }

        drive = drive.result;

        this.lib.router.endLoading();

        return {
            result: drive,
        };
    }

    addTip = async (id, value) => {
        this.lib.router.beginLoading();

        const centsValue = value*100;

        let drive = await _resolve(this.store.update({id, payment: {tip: centsValue}}));

        if (drive.error) {
            this.lib.router.showAlert({
                title: 'Error',
                message: drive.error.message,
            });

            this.lib.router.endLoading();

            return {
                error: drive.error
            };
        }

        drive = drive.result;

        this.lib.router.endLoading();
        return {
            result: drive,
        };
    };
}
