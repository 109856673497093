import React from 'react';

import { View, StyleSheet } from 'react-native';

import { Text, Spacer, Button } from '../components';

import { colors } from '../constants/theme';

const RenderInstruction = ({ business, startsOrEnds }) => (
    <View>
        <Text style={styles.subtitleText}>
            It looks like your delivery {startsOrEnds} at <Text style={styles.bold}>{business.name}</Text>.&nbsp;
            Please review the following special instructions for this location:
        </Text>
        <Spacer size={10} />
        <Text style={styles.contentText}>
            {business.consumerNotes}
        </Text>
        <Spacer size={30} />
    </View>
);

const LocationSpecialInstructions = ({ originBusiness = {}, destinationBusiness = {}, onContinue, onDismiss }) => (
    <View style={styles.container}>
        <View style={styles.innerContainer}>
            {!!originBusiness.consumerNotes && <RenderInstruction business={originBusiness} startsOrEnds="starts" />}
            {!!destinationBusiness.consumerNotes && <RenderInstruction business={destinationBusiness} startsOrEnds="ends" />}
        </View>
        {onContinue ? (
            <Button onPress={onContinue} muted>
                CONTINUE
            </Button>
        ) : (
            <Button onPress={onDismiss} muted>
                CLOSE
            </Button>
        )}
    </View>
);

export default LocationSpecialInstructions;

const styles = StyleSheet.create({
    container: {
        padding: 20,
        maxHeight: '100vh',
        overflow: 'auto',
    },
    innerContainer: {
        width: `100%`,
        backgroundColor: colors.white,
        padding: 20,
        paddingBottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        fontFamily: 'ClanOT-Medium',
        fontSize: 24,
        textAlign: 'center',
    },
    subtitleText: {
        fontFamily: 'ClanOT-Book',
        fontSize: 18,
    },
    bold: {
        fontFamily: 'ClanOT-Medium',
    },
    underline: {
        textDecoration: 'underline',
    },
    contentText: {
        fontSize: 14
    },
});
