import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Text } from '../components';

import { colors } from '../constants/theme';

export default () => (
    <View style={styles.container}>
        <Text style={styles.text}>
            ORDER - DELIVERY DETAILS
        </Text>
        <Text style={styles.text}>
            STATUS
        </Text>
    </View>
);

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flexDirection: 'row',
        backgroundColor: colors.darkGrey,
        justifyContent: 'space-between',
    },
    text: {
        fontFamily: 'ClanOT-Medium',
        color: colors.midGrey2,
        fontSize: 12,
    },
});
