import { toClientEntity, toServerEntity } from '../../helpers';

export default class UserApiAdapter {
    constructor(api, magicUrl) {
        this._api = api;
        this._magicUrl = magicUrl;
    }

    async getById(id) {
        let user = await _resolve(this._api.get('/users/' + id));

        if (user.error) {
            return {
                error: user.error.message,
            };
        }

        user = user.result;

        user = toClientEntity.User(user);

        return {
            result: user,
        };
    }

    async checkForExistingUserByUserInfo(userId, email, phone) {
        phone = phone.replace('(', '')
                     .replace(')', '')
                     .replace('-', '')
                     .replace(' ', '');
                
        let users = await _resolve(this._api.get(`/users?email=${email}&phone=${phone}`));

        if (users.error) {
            return {
                error: users.error.message,
            };
        }

        users = users.result;

        users = users.filter(x => x.id !== userId);

        users = users.map(u => toClientEntity.User(u));

        return {
            result: users
        };
    }

    async register(email, phone) {
        let data = {
            url: this._magicUrl,
        };

        if (email) {
            data = {
                ...data,
                email,
            };
        }

        if (phone) {
            data = {
                ...data,
                phone,
            };
        }

        let user = await _resolve(this._api.post('/users/register', data));
        console.log(user);

        user = user.result;

        if (user.error) {
            return {
                error: user.error,
            };
        }

        return {
            result: user,
        };
    }

    async update(data) {
        let { id, ...updatedUser } = data;

        data = toServerEntity.User(updatedUser);

        let user = await _resolve(this._api.put('/users/' + id, data));

        if (user.error) {
            return {
                error: user.error,
            };
        }

        user = user.result;

        user = toClientEntity.User(user);

        return {
            result: user,
        };
    }

    async generateMagicLink(email, phone) {
        let data = {
            url: this._magicUrl,
        };

        if (email) {
            data = {
                ...data,
                email,
            };
        }

        if (phone) {
            data = {
                ...data,
                phone,
            };
        }

        let user = await _resolve(this._api.post('/users/magic', data));

        if (user.error) {
            return {
                error: user.error,
            };
        }

        user = user.result;

        user = toClientEntity.User(user);

        return {
            result: user,
        };
    }

    async validateMagicLink(code) {
        let user = await _resolve(this._api.get('/users/magic/' + code));

        if (user.error) {
            return {
                error: user.error,
            };
        }

        user = user.result;

        return {
            result: user,
        };
    }

    async removeSavedCard(userId, cardId) {
        return await _resolve(this._api.delete(`/users/savedcard/${cardId}`));
    }

    async recommendFriend(email) {
        return await _resolve(this._api.post('/users/recommend-friend', {email}));
    }

    async eraseUserData(){
        let result = await _resolve(this._api.delete(`/users/delete-consumer`))
        return result
    }
}


