import React from 'react';

import { InfoModal } from '../modals';

export default () => (
    <InfoModal
        title={'Unadded coupon'}
        body={
            'If you pay before hitting "Use Coupon", your coupon will not be used.'
        }
    />
);