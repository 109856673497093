const errorObject = (objectProperties) => {
    return {
        error: objectProperties
    }
}

const resultObject = (objectProperties) => {
    return {
        result: objectProperties
    }
}

global.errorObject = errorObject;
global.resultObject = resultObject;