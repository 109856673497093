export default class City {
    constructor(args) {
        this.store = args.store;
    }

    initLib(lib) {
        this.lib = lib;
    }

    async getClosestCity(query) {
        return await _resolve(this.store.getClosestCity(query));
    }
}