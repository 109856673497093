import { toClientEntity, toServerEntity } from '../../helpers';

export default class BusinessAdapter {
    constructor(api) {
        this._api = api;
    }

    async getSpecialInstructions(address) {
        return await this._api.post('/businesses/notes', { address });
    }
}