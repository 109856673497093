import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import { Button, Text } from './';

import { colors } from '../constants/theme';

export default class ApplePayButton extends PureComponent {
    static propTypes = {
        shouldAllowProcess: PropTypes.func.isRequired,
        onPress: PropTypes.func.isRequired,
        amount: PropTypes.string.isRequired,
    };

    handlePress = () => {
        const { shouldAllowProcess, onPress, amount } = this.props;

        if (shouldAllowProcess()) {
            const paymentRequest = {
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    label: 'JOEY Inc',
                    amount,
                },
            };

            const session = Stripe.applePay.buildSession(
                paymentRequest,
                (result, completion) => {
                    console.log('result: ', result.token);

                    onPress(result.token.id, err => {
                        if (!err) {
                            completion(ApplePaySession.STATUS_SUCCESS);
                        } else {
                            completion(ApplePaySession.STATUS_FAILURE);
                        }
                    });
                },
                error => {
                    console.log(error.message);
                }
            );

            session.oncancel = () => {
                console.log('User hit the cancel button in the payment window');
            };

            session.begin();
        }
    };

    render() {
        const { onPress } = this.props;

        return (
            <Button
                onPress={this.handlePress}
                type={'secondary'}
                color={colors.darkGrey}
            >
                <View
                    style={{
                        height: 25,
                        backgroundImage: '-webkit-named-image(apple-pay-logo-black)',
                    }}
                />
            </Button>
        );
    }
}
