export default class Upload {
    constructor(args) {
        this.store = args.store;
    }

    initLib(lib) {
        this.lib = lib;
    }

    async create(file) {
        let upload = await _resolve(this.store.upload(file));

        if (upload.error) {
            return {
                error: upload.error,
            };
        }

        upload = upload.result;

        return {
            result: upload,
        };
    }

    async fetch(key) {
        let upload = await _resolve(this.store.fetch(key));

        if (upload.error) {
            return {
                error: upload.error,
            };
        }

        upload = upload.result;

        return {
            result: upload,
        };
    }
}
