import React from 'react';

import ImageGallery from 'react-image-gallery';

export default ({ photos }) => {
    const images = photos.map(photoUrl => {
        return {
            original: photoUrl,
            thumbnail: photoUrl
        };
    });

    return <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} />;
};
