import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, TextInput, Platform } from 'react-native';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';

import { colors } from '../constants/theme';

const currencyMask = createNumberMask({
    prefix: '$',
});

const weightMask = createNumberMask({
    prefix: '',
    suffix: ' lbs',
});

const cvcMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    integerLimit: 4,
    allowLeadingZeroes: true,
});

const MASK_TYPES = {
    money: currencyMask,
    weight: weightMask,
    email: emailMask,
    phoneNumber: [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ],
    creditCard: [
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ],
    expiryMonth: [/[0-9]/, /\d/],
    expiryYear: [/[0-9]/, /\d/, /\d/, /\d/],
    cvc: cvcMask,
};

const MASKED_INPUT_KEYBOARD_TYPES = {
    ['phone-pad']: 'tel',
};

// NOTE(dave) For the web we are disabling the "words" autoCapitalization type
// since it doesn't work. See https://forums.developer.apple.com/thread/18634
const AUTO_CAPITALIZATION_TYPES = {
    characters: 'characters',
    none: 'none',
    sentences: 'sentences',
    words: Platform.OS === 'web' ? 'none' : 'words',
};

export default class Input extends Component {
    static propTypes = {
        style: PropTypes.any,
        fontSize: PropTypes.number,
        type: PropTypes.string,
        theme: PropTypes.string,
        textAlign: PropTypes.string,
        onLayout: PropTypes.func,
        maskType: PropTypes.any,
        keyboardType: PropTypes.string,
        onChangeText: PropTypes.func,
        autoCapitalize: PropTypes.string,
        placeholderTextColor: PropTypes.string
    };

    static defaultProps = {
        textAlign: 'left',
    };

    static maskTypes = MASK_TYPES;

    focus = () => {
        this.refs.input.focus();
    };

    blur = () => {
        const { blur } = this.refs.input;

        if (blur) {
            blur();
        }
    };

    handleChange = e => {
        this.props.onChangeText(e.target.value);
    };

    // NOTE(dave): Code adapted from the following jQuery plugin:
    // https://github.com/agrublev/autocapitalize
    handleAutoCapitalize = text => {
        const { autoCapitalize } = this.props;

        if (Platform.OS === 'web' && autoCapitalize === 'words') {
            const match = text.match(/[a-zA-Z0-9-_ ]/);

            if (match && match.length > 0) {
                let value = text.toLowerCase();

                const splitIdentifier = ' ';
                const split = value.split(splitIdentifier);

                split.forEach((v, i) => {
                    if (v.length) {
                        split[i] = v.charAt(0).toUpperCase() + v.substring(1);
                    }
                });

                value = split.join(splitIdentifier);

                this.props.onChangeText(value);

                return;
            }
        }

        this.props.onChangeText(text);
    };

    render() {
        let {
            style,
            type,
            textAlign,
            onLayout,
            maskType,
            onSubmitEditing = () => null,
            onKeyPress = () => null,
            keyboardType,
            autoCapitalize,
            fontSize = 16,
            placeholderTextColor
        } = this.props;

        const passthrough = { ...this.props };
        delete passthrough.ref;
        delete passthrough.style;
        delete passthrough.type;
        delete passthrough.onLayout;
        delete passthrough.fontSize;
        delete passthrough.textAlign;
        delete passthrough.maskType;
        delete passthrough.onChange;
        delete passthrough.onChangeText;

        if (maskType && onSubmitEditing) {
            // react-text-mask doesn't support onSubmitEditing
            // for masked inputs, add a new keypress handler that calls the keypress handler if provided,
            // and if the key was enter, call onSubmitEditing if provided
            passthrough.onKeyPress = (e) => {
                onKeyPress(e);
                if (e.nativeEvent.key === 'Enter') onSubmitEditing(e);
            }
        }

        style = style ? style : {};

        const containerStyle = [
            styles.container,
            type === 'secondary'
                ? styles.secondary
                : styles.primary,
            { textAlign },
            style && style.height && { height: style.height }
        ];

        // NOTE(dave): This is here because Mobile Safari will auto zoom in on
        // input fields that are below 16px in fontSize. We no likely.
        // SO answer: https://stackoverflow.com/a/7655319/4932710
        if (Platform.OS === 'web' && fontSize && fontSize < 16) {
            fontSize = 16;
        }

        let maskedStyle = {
            height: 44,
            borderRadius: 2,
            borderWidth: 0,
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: 'SctoGroteskA-Regular',
            fontSize,
            ...style
        };

        if (type === 'secondary') {
            maskedStyle = Object.assign(maskedStyle, secondary);
        } else {
            maskedStyle = Object.assign(maskedStyle, primary);
        }

        return (
            <View style={[{ height: 44 }, style]} onLayout={onLayout}>
                {maskType
                    ? <MaskedInput
                            ref={'input'}
                            mask={maskType}
                            type={MASKED_INPUT_KEYBOARD_TYPES[keyboardType]}
                            onChange={e => this.handleChange(e)}
                            {...passthrough}
                            render={(ref, props) => (
                                <input 
                                    ref={ref} 
                                    className={placeholderTextColor === 'dark' ? "masked-input-dark" : 'masked-input'}
                                    value={props.defaultValue ? props.defaultValue : ""} 
                                    style={maskedStyle}
                                    placeholder={props.placeholder}
                                    onChange={props.onChange}
                                    onKeyPress={props.onKeyPress}
                                     />
                            )}
                      />
                    : <TextInput
                          ref={'input'}
                          style={[containerStyle, { fontSize }, style]}
                          underlineColorAndroid={'transparent'}
                          placeholderTextColor={placeholderTextColor === 'dark' ? colors.darkGrey2 : colors.white}
                          autoCapitalize={
                              AUTO_CAPITALIZATION_TYPES[autoCapitalize]
                          }
                          onChangeText={text => this.handleAutoCapitalize(text)}
                          {...passthrough}
                      />}
            </View>
        );
    }
}

const primary = {
    backgroundColor: colors.white,
    color: colors.darkGrey2
};

const secondary = {
    backgroundColor: colors.transparentGreen,
    color: colors.white
};

const styles = StyleSheet.create({
    container: {
        height: 44,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
        color: colors.darkGrey,
        fontFamily: 'SctoGroteskA-Regular',
        paddingVertical: 10,
        width: '100%'
    },
    primary,
    secondary
});