import React from 'react';

import { StyleSheet, Image } from 'react-native';

import logoWhite from '../assets/logo-large-white@3x.png';
import logoGreen from '../assets/logo-large-green@3x.png';

export default ({ green, style }) => (
    <Image
        resizeMode={Image.resizeMode.contain}
        source={{ uri: green ? logoGreen : logoWhite }}
        style={[styles.logo, style]}
    />
);

const styles = StyleSheet.create({
    logo: {
        width: 200,
        height: 72,
        alignSelf: 'center',
        marginTop: 20,
    },
});
