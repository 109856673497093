import { toClientEntity, toServerEntity } from '../../helpers';

export default class CityApiAdapter {
    constructor(api) {
        this._api = api;
    }

    async getClosestCity(coords) {
        const latitude = coords.lat();
        const longitude = coords.lng();

        return await _resolve(this._api.post('/cities/filter', { latitude, longitude }))
    }
}