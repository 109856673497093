import { roundUpToHundredth } from "../../helpers/math";

export default class AddOn {
    constructor(args) {
        this.store = args.store;
    }

    initLib(lib) {
        this.lib = lib;
    }

    static COST_TYPES = {
        fixed: 'fixed',
        fixedPlus: 'fixedPlus',
    }
    
    static OPTION_TYPES = {
        integer: 'integer',
        boolean: 'boolean',
        scheduled: 'scheduled',
    }

    static SCHEDULING_OPTIONS = {
        morning: 'Morning (8am-12pm)',
        afternoon: 'Afternoon (12pm-5pm)',
        evening: 'Evening (After 5pm)',
    }

    async getAvailableAddOns(cityId) {
        return await this.store.getAvailableAddOns(cityId);
    }

    static calcBasePrice(addOn, truckTimeLimit = 60) {
        let price = 0;

        if (addOn.optionType === AddOn.OPTION_TYPES.integer) {
            price = addOn.baseRate * addOn.value;
        } else if (addOn.value) {
            price = addOn.baseRate;
        }

        if (addOn.costType === AddOn.COST_TYPES.fixedPlus) {
            // Add-on base rates are hourly, truck times can be anything
            // Fixed-plus add-ons need to be converted to the truck's time limit
            // For example, a $20 helper should have a base cost of $10 for a courier with a 30 minute truck time
            const truckTimeFactor = truckTimeLimit / 60;
            return roundUpToHundredth(price * truckTimeFactor);
        }

        return price;
    }

    static calcMinuteRate(addOn) {
        if (addOn.costType === AddOn.COST_TYPES.fixed) return 0;

        if (addOn.optionType === AddOn.OPTION_TYPES.integer) {
            return addOn.ratePerMinute * addOn.value;
        } else if (addOn.value) {
            return addOn.ratePerMinute;
        } else {
            return 0;
        }
    }

    static filterUnselected(addOns) {
        return addOns.filter(addOn => this.isSelected(addOn));
    }

    static isSelected(addOn) {
        const { optionType, value } = addOn;
        if (optionType === AddOn.OPTION_TYPES.boolean && value === true) return true;
        if (optionType === AddOn.OPTION_TYPES.integer && value > 0) return true;
        if (optionType === AddOn.OPTION_TYPES.scheduled && value && value.date && value.time) return true;
        return false;
    }

    static enumerate(addOns = [], joinChar) {
        const filteredAddOns = this.filterUnselected(addOns);
        return filteredAddOns.map(addOn => this.displayEnumerated(addOn)).join(joinChar);
    }

    static displayEnumerated(addOn) {
        if (addOn.optionType === AddOn.OPTION_TYPES.integer) {
            return `${addOn.value}x ${addOn.name}`;
        }
        return addOn.name;
    }
}