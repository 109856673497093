import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  StyleSheet,
  View
} from 'react-native';

import {
  Button,
  Text,
  Spacer,
  RadioPillGroup,
  NavigationHeader
} from '../components';

import { colors } from '../constants/theme';
import {blurAllFields} from '../helpers/blur-all-fields';

export default class DeliveryCategoryReasonScreen extends PureComponent {
  static propTypes = {
    onContinue: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      reason: null,
      error: false
    };
  }

  continue = () => {
    blurAllFields();
    const { reason } = this.state;
    const {onContinue} = this.props;

    if (reason) {
      onContinue({
        reason
      });
    } else {
      this.setState({
        error: true
      });
    }
  };

  handleReason = (reason) => {
    this.setState({
      error: false,
      reason
    });
  }

  render() {
    const {
      goBack
    } = this.props;

    return (
      <View style={styles.container}>
        <NavigationHeader 
          goBack={goBack} 
          titleText={'Select a category or reason for delivery.'}
          style={{backgroundColor: colors.creamWhite}}
        />
        <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
            <View style={styles.body}>
                <Text style={styles.titleSubText}>
                  Please select one
                </Text>
                <Spacer size={25} />
                {this.state.error && 
                    <Text style={styles.error}>Category or reason for delivery required</Text>
                }
                <RadioPillGroup 
                    options={[{
                        label: 'New Furniture',
                        value: 'newFurniture'
                    },{
                        label: 'Appliances',
                        value: 'appliances'
                    },{
                        label: 'Home Improvement',
                        value: 'homeImprovement'
                    },{
                        label: 'Auction Pickup',
                        value: 'auction'
                    },{
                        label: 'Lawn & Garden',
                        value: 'lawnAndGarden'
                    },{
                        label: 'Documents',
                        value: 'documents'
                    },{
                        label: 'Used Furniture',
                        value: 'usedFurniture'
                    },{
                        label: 'Home/Apt. Move',
                        value: 'homeApartmentMove'
                    },{
                        label: 'College Move',
                        value: 'college'
                    },{
                        label: 'Sports & Fitness',
                        value: 'sportsAndFitness'
                    },{
                        label: 'A Gift',
                        value: 'gift'
                    },{
                        label: 'Other',
                        value: 'other'
                    }
                  ]
                }
                onClick={this.handleReason} />
            </View>
        </ScrollView>
        <View style={styles.footer}>
              <Button
                  onPress={this.continue}
                  style={styles.circleButton}
              >
                  Continue
              </Button>
          </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  circleButton: {
    backgroundColor: colors.green,
    borderRadius: 30
  },
  footer: {
      padding: 20,
      justifyContent: 'flex-end'
  },
  body: {
    width: `100%`,
    paddingHorizontal: 20,
    paddingBottom: 20,
    paddingTop: 10,
    alignItems: 'center',
    flex: 1
  },
  titleText: {
      fontSize: 26,
      textAlign: 'center',
      marginLeft: 30,
      marginRight: 30
  },
  titleSubText: {
      fontSize: 18,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
  },
  container: {
    flex: 1,
    backgroundColor: colors.creamWhite,
  },
  error: {
    color: colors.red
  }
});