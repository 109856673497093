export default class Truck {
    constructor(args) {
        this.store = args.store;
        this.presenters = args.presenters;
    }

    initLib(lib) {
        this.lib = lib;
    }

    filter = async (query = {}) => {
        this.lib.router.beginLoading();

        let trucks = await _resolve(this.store.filter(query));

        if (trucks.error) {
            return {
                error: truck.error,
            };
        }

        trucks = trucks.result;

        this.lib.router.endLoading();

        return {
            result: this.presenters.all(trucks),
        };
    };

    async create(data, presenter) {
        let truck = await _resolve(this.store.create(data));

        if (truck.error) {
            return {
                error: truck.error,
            };
        }

        truck = truck.result;

        return {
            result: presenter(truck),
        };
    }

    async getById(id, presenter) {
        let truck = await _resolve(this.store.getById(id));

        if (truck.error) {
            return {
                error: truck.error,
            };
        }

        truck = truck.result;

        return {
            result: presenter(truck),
        };
    }

    async delete(id, presenter) {
        let truck = await _resolve(this.store.delete(id));

        if (truck.error) {
            return {
                error: truck.error,
            };
        }

        truck = truck.result;

        return {
            result: presenter(truck),
        };
    }

    async update(data, presenter) {
        let truck = await _resolve(this.store.update(data));

        if (truck.error) {
            return {
                error: truck.error,
            };
        }

        truck = truck.result;

        return {
            result: presenter(truck),
        };
    }
}
