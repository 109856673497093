export default class Router {
    constructor(router, routerContainer) {
        this.router = router;
        this.routerContainer = routerContainer;
    }

    initLib(lib) {
        this.lib = lib;
    }

    async getSideMenuItems() {
        if (this.lib.user.isLoggedIn()) {
            return this.router.getConsumerSideMenu(this.lib);
        }

        return this.router.getUnauthenticatedSideMenu(this.lib);
    }

    async getInitialRoot() {
        if (this.lib.user.isLoggedIn()) {
            return this.routerContainer(
                this.router.getAuthenticatedNavigator(this.lib)
            );
        }

        return this.routerContainer(
            this.router.getUnauthenticatedNavigator(this.lib)
        );
    }

    goBack(navigator) {
        this.router.goBack(navigator);
    }

    logout() {
        this.router.logout();
    }

    //.NOTE(dave): These next few functions should probably be in some sort of
    // Infrastructure lib/adapter...
    onAuthenticationSuccess(token) {
        this.router.onAuthenticationSuccess(token);
    }

    beginLoading() {
        this.router.onBeginLoading();
    }

    endLoading() {
        this.router.onEndLoading();
    }

    showAlert(args) {
        this.router.onShowAlert(args);
    }
}
