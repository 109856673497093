import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Text, Spacer, RouteProgress } from '../components';

import { colors } from '../constants/theme';

export default ({ title, description, statusDisplay, statusType, style }) => (
    <View style={[styles.container, style]}>
        <View style={styles.textContainer}>
            <Text style={styles.titleText}>{title}</Text>
            <Spacer size={5} />
            <Text style={styles.text}>{description}</Text>
        </View>
        <View style={styles.statusContainer}>
            <RouteProgress status={statusType} />
            <Text style={styles.statusText}>
                {statusDisplay}
            </Text>
        </View>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textContainer: {
        flex: 1
    },
    titleText: {
        color: colors.darkGrey,
        fontSize: 14,
    },
    text: {
        fontSize: 18,
        fontFamily: 'Recoleta-Semibold'
    },
    statusContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    statusText: {
        textAlign: 'center',
        fontSize: 14,
        fontFamily: 'Recoleta-Semibold',
        marginTop: 5
    },
});