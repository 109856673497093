import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { View, ScrollView, StyleSheet, Image } from 'react-native';

import { Button, Text, Spacer, NavigationHeader, MenuButton, Input } from '../components';

import { colors } from '../constants/theme';
import smile from '../assets/smile@3x.png';

export default class ConfirmationScreen extends PureComponent {
    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onNavigateHome: PropTypes.func.isRequired
    };
  
    constructor(props) {
      super(props);
  
      this.state = {
        email: '',
        submited: false
      };
    }

    handleOnSubmit = async () => {
        const { email } = this.state;
        await this.props.onSubmit(email);
        this.setState({submited: true});
    }

    handleOnPress = () => {
        this.props.onNavigateHome();
    }

    componentDidMount = () => {}

    render() {
        const { onToggleMenu } = this.props;
        const { email, submited } = this.state;
        return (
            <View style={styles.container}>
                <NavigationHeader 
                    titleText={'Order Complete. Yay!'}
                    style={{backgroundColor: colors.creamWhite}}
                    hamburgerMenu={
                        <MenuButton
                            onPress={onToggleMenu}
                            style={{ marginLeft: 25, marginTop: 8, position: 'absolute' }}
                        />
                    }
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <Text style={styles.titleSubText}>
                            Your Joey Driver will be contacting you soon for scheduling.
                        </Text>
                        <Spacer size={13} />

                        <View style={styles.tab}>
                            <View style={{flex: 1}}>
                                <Text style={styles.tabBody}>
                                    Recommend a friend to use Joey and you’ll both get 10% OFF your next delivery.
                                </Text>
                                {!submited ?
                                    <Input
                                        ref={'email'}
                                        type={'primary'}
                                        style={{ height: 44, backgroundColor: colors.creamWhite }}
                                        placeholderTextColor={'dark'}
                                        placeholder={
                                            "Enter their email here"
                                        }
                                        onChangeText={email => this.setState({ email })}
                                        value={email}
                                    />
                                :
                                    <View>
                                        <Image 
                                            source={{uri: smile}}
                                            style={{ width: 36, height: 36, marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Text style={styles.thanks}>Thank you.</Text>
                                    </View>
                                }
                            </View>
                            <View style={styles.tabFooter}>
                                {!submited &&
                                    <Button
                                        onPress={this.handleOnSubmit}
                                        style={styles.circleButton}
                                        color={colors.white}
                                    >
                                        Submit
                                    </Button>
                                }
                                <Spacer size={15} />
                                <Text style={styles.back} onPress={this.handleOnPress}>
                                    {!submited ? "Skip" : "Back to Home"}
                                </Text>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.creamWhite,
        flex: 1
    },
    titleSubText: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: colors.darkGrey2,
        textAlign: 'center',
        fontFamily: 'Recoleta-Semibold',
        fontSize: 24,
        marginBottom: 15,
        marginTop: 15
    },
    body: {
        width: `100%`,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1,
        marginBottom: 20
    },
    tab: {
        backgroundColor: colors.white,
        borderRadius: 10,
        height: 400,
        shadowColor: colors.black,
        shadowOpacity: 0.21,
        shadowRadius: 6,
        elevation: 16,
        paddingTop: 47,
        paddingBottom: 47,
        paddingLeft: 37,
        paddingRight: 37
    },
    circleButton: {
        backgroundColor: colors.green,
        borderRadius: 30
    },
    tabBody: {
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        marginBottom: 36
    },
    back: {
        color: colors.green,
        fontSize: 18,
        textDecorationLine:'underline',
        textAlign: 'center'
    },
    thanks: {
        color: colors.green,
        fontSize: 24,
        textAlign: 'center',
        marginTop: 10
    }
});