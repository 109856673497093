export default class Payment {
    constructor(args) {
        this.gateway = args.gateway;
    }

    initLib(lib) {
        this.lib = lib;
    }

    isApplePayAvailable = async () => {
        let isApplePayAvailable = await _resolve(
            this.gateway.isApplePayAvailable()
        );

        isApplePayAvailable = isApplePayAvailable.result;

        if (isApplePayAvailable === true) {
            return true;
        } else {
            return false;
        }
    };

    // processApplePayPayment = async amount => {
    //     let result = await this.gateway.processApplePayPayment(amount);

    //     result = result.result;

    //     if (result.token) {
    //         result.onSuccess();

    //         return result.token;
    //     } else {
    //         result.onFailure();

    //         return {};
    //     }
    // };
}
