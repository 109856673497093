import moment from 'moment';

export default coupon => {
    let {
        id,
        startDate,
        endDate,
        value,
        used,
        available,
        name,
        limited,
        type,
        cities,
    } = coupon;

    return {
        id,
        startDate: moment(startDate).format('MM/DD/YYYY hh:mm A'),
        endDate: endDate ? moment(endDate).format('MM/DD/YYYY hh:mm A') : null,
        value,
        used,
        available,
        name,
        limited: limited ? limited : false,
        type,
        cities,
    };
};
