import React from 'react';

import { TouchableOpacity, Image } from 'react-native';

import infoIcon from '../assets/info-icon@3x.png';
import infoIconDark from '../assets/info-icon-dark@3x.png';

export default ({ onPress, dark, style = {} }) => (
    <TouchableOpacity style={style} onPress={onPress}>
        <Image
            resizeMode={Image.resizeMode.contain}
            source={{ uri: dark ? infoIconDark : infoIcon }}
            style={{
                width: 20,
                height: 20,
            }}
        />
    </TouchableOpacity>
);
