import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet,
    View,
    Image,
    ScrollView
} from 'react-native';

import { 
    Text, 
    NavigationHeader, 
    Spacer,
    Button
} from '../components';

import { colors } from '../constants/theme';

export default class ServiceConfirmationScreen extends Component {
    static propTypes = {
        onContinue: PropTypes.func.isRequired,
        driveBuilder: PropTypes.any.isRequired,
        goBack: PropTypes.func.isRequired
    };

    constructor() {
        super();

        this.state = {};
    }

    async componentDidMount() {}

    render() {
        const { goBack, onContinue, driveBuilder: { calcMinuteRate, truck: { icon, title, haulSize, basePrice, timeLimit, displayTimeFor } } } = this.props;

        const additionalTimeText = `Additional time over ${displayTimeFor.toLowerCase()} will be billed at $${calcMinuteRate()} per minute`;
        let priceDetailsText = `Includes driver and vehicle for ${displayTimeFor.toLowerCase()}. ${additionalTimeText}`;
        let confirmBoxText = `Please confirm your item(s) dimensions to ensure you're selecting a vehicle with sufficient hauling capacity.`;

        if (title === 'Muscle Only') {
            priceDetailsText = `Includes 1 Joey helper to move your item(s) within the same location for ${displayTimeFor.toLowerCase()}. ${additionalTimeText}`;
            confirmBoxText = `Muscle Only is for moving your item(s) around one location. If you need your item(s) delivered to a new location, please return to the previous screen and select the vehicle needed.`;
        }

        return (
            <View style={styles.container}>
                <NavigationHeader 
                    goBack={goBack} 
                    titleText={'Service Type'}
                    style={{backgroundColor: colors.white}}
                />
                <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                    <View style={styles.body}>
                        <Text style={styles.truckTitle}>{title}</Text>
                        <Spacer size={5} />
                        <Text style={styles.size}>Haul Size: {haulSize}</Text>
                        <Spacer size={15} />
                        <Image
                            source={{ uri: icon }}
                            style={{ width: 240, height: 150, resizeMode: "contain", marginVertical: 5 }}
                        />
                        <Text style={styles.price}>${parseFloat(parseFloat(basePrice).toFixed(0) / (timeLimit / 60)).toFixed(0)}</Text>
                        <Text style={styles.priceDetails}>
                            {priceDetailsText}
                        </Text>
                        <Spacer size={25} />
                        <View style={styles.confirmBox}>
                            <Text style={styles.confirmBoxText}>{confirmBoxText}</Text>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Button
                            onPress={onContinue}
                            style={styles.circleButton}
                        >
                            {'Select & Continue'}
                        </Button>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    body: {
        width: `100%`,
        paddingBottom: 20,
        paddingTop: 5,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1
    },
    container: {
        flex: 1,
        backgroundColor: colors.white,
    },
    truckTitle: {
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        fontFamily: 'Recoleta-Semibold'
    },
    size: {
        fontSize: 16,
        color: colors.darkGrey2,
        textAlign: 'center',
    },
    price: {
        fontFamily: 'Recoleta-Semibold',
        fontSize: 24,
        color: colors.darkGrey2,
        textAlign: 'center',
        marginBottom: 5
    },
    priceDetails: {
        fontSize: 18, 
        textAlign: 'center'
    },
    perHour: {
        justifyContent: 'center',
        flexDirection: 'row'
    },
    footer: {
        justifyContent: 'flex-end',
        padding: 20
    },
    circleButton: {
        backgroundColor: colors.green,
        borderRadius: 30
    },
    confirmBox: {
        paddingHorizontal: 30,
        paddingVertical: 20,
        justifyContent: 'center',
        backgroundColor: colors.creamWhite
    },
    confirmBoxText: {
        fontSize: 14, 
        textAlign: 'center', 
        color: colors.midGrey4
    }
});