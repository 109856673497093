import React, {Component} from 'react';
import {
    StyleSheet, TextInput, View, TouchableOpacity, Image
} from 'react-native';
import {Text,Button} from '../components';
import { colors } from '../constants/theme';
import closeIcon from '../assets/close-icon-large-orange@3x.png';
import { withNavigation } from 'react-navigation';


class DeleteAccount extends Component{

    constructor() {
        super();
        this.state = {
            userInput:'',
            disableButton:true,
            email:'',
            deleted: false
        }
    }

    handleChange = (e) =>{
        this.setState({userInput:e.target.value})
        if(e.target.value.toUpperCase()===this.state.email.toUpperCase()){
            this.setState({disableButton: false})
        } else {
            this.setState({disableButton: true})
        }
    }

    setUserState(user) {

        this.setState({
            email: user.email || '',
        })
    }

    async getUser() {
        const { result: user, error: getUserError} = await this.props.getUser();

        if (getUserError) {
            console.error(getUserError);
            alert('Could not fetch user details');
        } else {
            this.setUserState(user);
        }
    }

    async componentDidMount() {
        await this.getUser();
    }

    async handleSubmit() {
      
            let response = await this.props.eraseUserData()
            if(response.result){
                this.setState({deleted:true})
            setTimeout(()=>{this.props.logout(this.props.navigation)}, 5000)
            }
      
            
        
    }


    render(){
        if (this.state.deleted === true){
            return(
                <View style={styles.container}>
                    <Text style={styles.header}>Your account has been deleted.</Text>
                    <Text style={styles.subHeader}>You will be logged off in five seconds.</Text>
                </View>
                
            )
        } else {
            return(
                <View>
                  <View style={styles.row}>
                   <TouchableOpacity style={styles.exit} onPress={()=>this.props.navigation.navigate('AccountManagement')} >
                   <Image
                       source={{ uri: closeIcon }}
                       style={{ width: 30, height: 30, flex: 1, marginRight: 10 }}
                   />
                   </TouchableOpacity> 
               </View>
               <View style={styles.container}>
                   <Text style={styles.header} fontFamily='headerBold' color='primary' >Delete Account</Text>
                   <Text style={styles.text} color='primary' >Please type in your full email address <Text style={styles.email}>({this.state.email}) </Text>
                    to confirm your request to delete your account.</Text>
                   <TextInput onChange={this.handleChange} style={styles.input}/>
                   <Button 
                   type={'primary'}  
                   disabled={this.state.disableButton} 
                   color={this.state.disableButton? colors.green: colors.white} 
                   inverted={true} 
                   style={this.state.disableButton? styles.disabledButton: styles.button} 
                   onPress={()=>{this.handleSubmit()}}>Delete Account</Button>
               </View>
               </View>
                  
               )
        }
        
    }
        
    
}

export default withNavigation(DeleteAccount)

const styles = StyleSheet.create({
    container:{
        flexDirection:'column',
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
    },
    email:{
        color:'red'
    },
    input:{
        width: '75%',
        height: 50,
        borderWidth:1,
        borderRadius: 5,
        margin: '10%',
        color:'black',
        fontSize: 21,
        paddingHorizontal:10,
    },
    text:{
        fontSize: 21,
        textAlign: 'center',
        width: '75%'
    },
    button:{
        marginTop: 10,
        width: '100%',
        marginHorizontal: 10,
        backgroundColor: colors.green,
        borderRadius: 30,
        width: '75%'
    },
    header:{
        fontSize: 35,
        marginVertical: 10,
    },
    subHeader:{
        marginVertical: 40,
        fontSize: 25,
    },
    row:{
        flexDirection: 'row-reverse',
    },
    exit:{
        margin:10,
        width: 30,
        height: 30,
    },
    disabledButton:{
        backgroundColor: colors.gray,
        color: colors.green,
        borderColor: colors.green,
        marginTop: 10,
        width: '75%',
        marginHorizontal: 10,
        borderRadius: 30,
        borderWidth:2,
    }
});