import Router from './router';
import User from './user';
import DriveBuilder from './drive-builder';
import Drive from './drive';
import Upload from './upload';
import Truck from './truck';
import Payment from './payment';
import Coupon from './coupon';
import City from './city';
import AddOn from './add-on';
import Business from './business';

export default {
    Router,
    User,
    DriveBuilder,
    Drive,
    Upload,
    Truck,
    Payment,
    Coupon,
    City,
    AddOn,
    Business,
};
