import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  StyleSheet,
  View,
  TouchableOpacity
} from 'react-native';

import {
  InfoButton,
  Text,
  Input,
  Spacer,
  PhotoButton,
  NavigationHeader,
  Button
} from '../components';

import { colors } from '../constants/theme';
import {blurAllFields} from '../helpers/blur-all-fields';

export default class ItemDetailsScreen extends PureComponent {
  static propTypes = {
    onContinue: PropTypes.func.isRequired,
    onShowValueWeightInfo: PropTypes.func.isRequired,
    onEditPhoto: PropTypes.func.isRequired,
    onUploadPhoto: PropTypes.func.isRequired,
    uploadedPhotoURLs: PropTypes.array,
    beginLoading: PropTypes.func.isRequired,
    showLocationSpecialInstructions: PropTypes.func,
    goBack: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      totalValue: '',
      totalWeight: '',
      description: '',
      photos: this.processPhotos(props.uploadedPhotoURLs)
    };
  }

  processPhotos = uploadedPhotoURLs => {
    return [
      {
        current: uploadedPhotoURLs[0] ? false : true,
        url: uploadedPhotoURLs[0]
      },
      {
        current: uploadedPhotoURLs[1] || !uploadedPhotoURLs[0] ? false : true,
        url: uploadedPhotoURLs[1]
      },
      {
        current: uploadedPhotoURLs[2] || !uploadedPhotoURLs[1] ? false : true,
        url: uploadedPhotoURLs[2]
      },
      {
        current: uploadedPhotoURLs[3] || !uploadedPhotoURLs[2] ? false : true,
        url: uploadedPhotoURLs[3]
      }
    ];
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      photos: this.processPhotos(nextProps.uploadedPhotoURLs)
    });
  }

  continue = () => {
    blurAllFields();
    const { totalValue, totalWeight, description, photos } = this.state;

    this.refs.totalValue.blur();
    this.refs.totalWeight.blur();
    this.refs.description.blur();

    const strippedValue = Number(totalValue.replace('$', '').replace(',', ''));
    const strippedWeight = Number(
      totalWeight.replace(' lbs', '').replace(',', '')
    );

    this.props.onContinue({
      totalValue: strippedValue,
      totalWeight: strippedWeight,
      description
    });
  };

  handleImageChange = index => image => {
    const { onUploadPhoto } = this.props;

    if (image) {
      onUploadPhoto(image);
    } else {
      console.log('Image input cancelled');
    }
  };

  handleEditPhoto = index => () => {
    const { onEditPhoto } = this.props;
    const { photos } = this.state;

    onEditPhoto(index, photos[index].url);
  };

  render() {
    const {
      onContinue,
      onShowValueWeightInfo,
      uploadedPhotos,
      beginLoading,
      showLocationSpecialInstructions,
      goBack
    } = this.props;

    const { totalValue, totalWeight, description, photos } = this.state;

    return (
      <View style={styles.container}>
        <NavigationHeader 
          goBack={goBack} 
          titleText={'What can we help you deliver?'}
          style={{backgroundColor: colors.creamWhite}}
        />
        <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
          <View style={styles.scrollView}>
              <View style={styles.body}>
                  <Text style={styles.titleSubText}>
                      Tap the camera to take photos
                  </Text>
                  <Spacer size={15} />
                  <View style={styles.photoAreaContainer}>
                    {photos.map((photo, index) => {
                      return (
                        <PhotoButton
                          key={index}
                          current={photo.current}
                          onImageChange={this.handleImageChange(index)}
                          onEditPhoto={this.handleEditPhoto(index)}
                          uploadedPhotoUrl={photo.url}
                          beginLoading={beginLoading}
                        />
                      );
                    })}
                </View>
                {showLocationSpecialInstructions && (
                  <View style={styles.specialInstructionsContainer}>
                      <TouchableOpacity onPress={showLocationSpecialInstructions}>
                          <Text style={styles.specialInstructions}>Alert: This location has special instructions. Click to view.</Text>
                      </TouchableOpacity>
                  </View>
                )}
                <View style={styles.formArea}>
                  <View style={styles.inputRow}>
                    <Text style={styles.inputTitleText}>Total Value</Text>
                    <InfoButton style={{marginRight: 20}} onPress={onShowValueWeightInfo} dark={true} />
                    <Input
                      ref={'totalValue'}
                      type={'primary'}
                      keyboardType={'phone-pad'}
                      maskType={Input.maskTypes.money}
                      style={{ flex: 1, height: 44 }}
                      placeholderTextColor={'dark'}
                      placeholder={'$ Estimated'}
                      onChangeText={totalValue => this.setState({ totalValue })}
                      value={totalValue}
                    />
                  </View>
                  <View style={styles.inputRow}>
                    <Text style={styles.inputTitleText}>Total Weight</Text>
                    <InfoButton style={{marginRight: 20}} onPress={onShowValueWeightInfo} dark={true} />
                    <Input
                      ref={'totalWeight'}
                      type={'primary'}
                      keyboardType={'phone-pad'}
                      maskType={Input.maskTypes.weight}
                      style={{ flex: 1, height: 44}}
                      placeholderTextColor={'dark'}
                      placeholder={'lbs Estimated'}
                      onChangeText={totalWeight => this.setState({ totalWeight })}
                      value={totalWeight}
                    />
                  </View>
                  <Spacer size={10} />
                  <View style={styles.hr} />
                  <View style={styles.descriptionRow}>
                    <Text style={styles.inputTitleText}>
                      Description
                    </Text>
                    <Spacer size={10} />
                    <Input
                      ref={'description'}
                      type={'primary'}
                      multiline={true}
                      style={{ height: 120 }}
                      placeholderTextColor={'dark'}
                      placeholder={
                        "Describe the item(s) you’re shipping, the quantity, and anything else relevant for your driver."
                      }
                      onChangeText={description => this.setState({ description })}
                      value={description}
                    />
                  </View>
              </View>
            </View>
            <View style={styles.footer}>
              <Button
                  onPress={this.continue}
                  style={styles.circleButton}
              >
                  Continue
              </Button>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  photoAreaContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  formArea: {
    flex: 2,
    width: '100%'
  },
  inputRow: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.grey
  },
  inputTitleText: {
    fontFamily: 'Recoleta-SemiBold',
    fontSize: 21,
    color: colors.darkGrey,
    marginRight: 10,
    minWidth: 125
  },
  descriptionRow: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.grey
  },
  buttonContainer: {
    padding: 20
  },
  specialInstructions: {
      fontSize: 18,
      color: 'white',
      marginHorizontal: 'auto',
  },
  scrollView: {
    backgroundColor: colors.creamWhite,
    flex: 1
  },
  body: {
    width: `100%`,
    paddingTop: 10,
    alignItems: 'center',
    flex: 1
  },
  circleButton: {
    backgroundColor: colors.green,
    borderRadius: 30
  },
  footer: {
    padding: 20,
    justifyContent: 'flex-end'
  },
  titleSubText: {
    fontSize: 18,
    textAlign: 'center'
  },
  hr: {
    borderBottomWidth: 1, 
    borderColor: '#D5DBD4',
    borderStyle: 'solid',
    marginLeft: 20,
    marginRight: 20
  },
  container: {
    flex: 1,
    backgroundColor: colors.creamWhite
  },
  specialInstructionsContainer: {
    backgroundColor: colors.red,
    paddingVertical: 5,
    paddingHorizontal: 25,
    marginHorizontal: 20,
    textAlign: 'center',
    borderRadius: 3,
    marginTop: 10
  }
});