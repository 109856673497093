import React from 'react';

import { StyleSheet, View, Image } from 'react-native';

import { Text, Spacer } from '../components';

import { colors } from '../constants/theme';

import originDetailPin from '../assets/origin-detail-pin@3x.png';
import destinationDetailPin from '../assets/destination-detail-pin@3x.png';

export default ({ origin, destination, style }) => (
    <View style={style}>
        <View style={styles.row}>
            <Image
                source={{ uri: originDetailPin }}
                style={{ width: 10, height: 10 }}
            />
            <Spacer horizontal size={20} />
            <Text style={styles.text}>{origin}</Text>
        </View>
        <Spacer size={15} />
        <View style={styles.row}>
            <Image
                source={{ uri: destinationDetailPin }}
                style={{ width: 10, height: 10 }}
            />
            <Spacer horizontal size={20} />
            <Text style={styles.text}>{destination}</Text>
        </View>
    </View>
);

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontSize: 13,
        color: colors.darkGrey,
    },
});
