import sanitize from '../sanitize';

export default data => {
    let { driver = {}, address = {} } = data;
    let { license = {} } = driver;

    return sanitize({
        id: data.id,
        email: data.email,
        password: data.password,
        scope: data.scope,
        connect_id: driver.paymentAccountId,
        firstname: data.firstname,
        middle_initial: data.middleInitial,
        lastname: data.lastname,
        nickname: data.nickname,
        profile_photo: data.avatar,
        phone: data.phone,
        dob: data.dob,
        notes: driver.notes,
        drivers_license: {
            expiration: license.expiration,
            number: license.number,
            photo: license.photo,
            state: license.state,
        },
        address: {
            street: address.street,
            city: address.city,
            state: address.state,
            zip: address.zip,
        },
        status: driver.status,
        social_security_number: data.ssn,
        howYouHeardAboutUs: data.howYouHeardAboutUs,
        howYouHeardAboutUsTimeStamp: data.howYouHeardAboutUsTimeStamp
    });
};
