import React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { BackButton } from '../components';
import { colors } from '../constants/theme';

const NavigationHeader = ({ goBack, titleText, style, hamburgerMenu }) => (
    <View style={[styles.container, style]}>
        {hamburgerMenu && (
            hamburgerMenu
        )}
        {goBack && (
            <BackButton
                onPress={() => setTimeout(goBack, 0)}
                style={styles.backButton}
            />
        )}
        <Text style={styles.titleText}>
            {titleText}
        </Text>
    </View>
);

const styles = StyleSheet.create({
    titleText: {
        fontFamily: 'Recoleta-Semibold',
        color: colors.darkGrey2,
        fontSize: 26,
        textAlign: 'center',
        marginLeft: 60,
        marginRight: 60
    },
    container: {
        width: '100%',
        paddingTop: 22,
        paddingBottom: 15
    },
    backButton: {
        marginLeft: 25, 
        marginTop: 8, 
        position: 'absolute'
    }
  });
  
export default NavigationHeader;