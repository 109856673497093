import React from 'react';

import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';

import { Text, Spacer } from '../components';

import { colors } from '../constants/theme';
import images from '../assets/images@3x.png';

export default ({ description, showPhotoViewer, photo, photoSize, style }) => {
    let size = { height: 160, width: 160};
    if (photoSize) {
        size = { height: photoSize, width: photoSize};
    }
    return (
        <View style={[styles.container, style]}>
            <View style={[styles.descriptionContainer, photo ? {marginRight: 20} : {}]}>
                <Text style={styles.text}>Delivery Description</Text>
                <Spacer size={5} />
                <Text style={styles.text}>{description}</Text>
            </View>
            { photo ? 
                <View style={styles.photoButtonContainer}>
                    <TouchableOpacity
                        style={[styles.photoButton, size]}
                        onPress={showPhotoViewer}
                    >
                        <Image
                            source={{ uri: images }}
                            style={{ width: 18, height: 18, position: 'absolute', right: 0, bottom: 0, marginRight: 8, marginBottom: 8, zIndex: 1000 }}
                        />
                        <Image
                            source={{ uri: photo }}
                            style={{ width: photoSize ? photoSize : 160, height: null, flex: 1, borderRadius: 5 }}
                        />
                    </TouchableOpacity>
                </View>
            :
            null
            }
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%'
    },
    descriptionContainer: {
        flex: 1
    },
    text: {
        fontSize: 12,
        color: colors.midGrey4
    }
});
