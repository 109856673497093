import React from 'react';

import {
    StyleSheet,
    View,
    ScrollView
} from 'react-native';

import {
    Text,
    Button,
    Spacer,
    DeliveryRow,
    DeliveryAddressRow,
    DeliveryCargoRow,
    NavigationHeader
} from '../components';

import { colors } from '../constants/theme';

export default ({
    summaryData,
    cargoData,
    deliveryData,
    onCancel,
    showPhotoViewer,
    photo,
    id,
    goBack,
    consumerPrice,
}) => {
    const { title, description, statusDisplay, statusType } = summaryData;
    const { description: cargoDescription } = cargoData;
    const { origin, destination } = deliveryData;

    return (
        <View style={styles.container}>
            <NavigationHeader 
                titleText={'Delivery Details'}
                style={{backgroundColor: colors.creamWhite}}
                goBack={goBack}
            />
            <ScrollView style={{ width: '100%' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
                <View style={styles.body}>
                    <DeliveryRow
                        style={{width: '100%', marginBottom: 20}}
                        title={title}
                        description={description}
                        statusDisplay={statusDisplay}
                        statusType={statusType}
                    />
                    <View style={styles.hr} />
                    <DeliveryAddressRow 
                        style={{marginTop: 20, marginBottom: 20, width: '100%'}} 
                        origin={origin} 
                        destination={destination} 
                    />
                    <View style={styles.hr} />
                    <DeliveryCargoRow
                        style={{marginTop: 20, marginBottom: 20}} 
                        description={cargoDescription}
                        showPhotoViewer={showPhotoViewer}
                        photo={photo}
                    />
                    <View style={styles.hr} />
                    <View style={{flexDirection: 'row', width: '100%', marginTop: 5}}>
                        <Text style={styles.estimatedTotalTitle}>Est. Total</Text>
                        <Text style={styles.estimatedTotal}>{`$${consumerPrice}`}</Text>
                    </View>
                    <Spacer size={40} />
                    <View style={{width: '100%'}}>
                        <Button onPress={onCancel} 
                                style={styles.cancel}
                                color={colors.darkGrey2}
                                height={36}
                                fontSize={14}
                        >
                            Cancel Order
                        </Button>
                    </View>
                    <Text style={styles.supportIdText}>Support ID: {id.slice(-5)}</Text>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.white,
        width: `100%`
    },
    footerContainer: {
        padding: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    estimatedTotalTitle: {
        flex: 1,
        fontSize: 21,
        fontFamily: 'Recoleta-Semibold',
        color: colors.darkGrey2
    },
    estimatedTotal: {
        fontSize: 21,
        fontFamily: 'Recoleta-Semibold',
        color: colors.darkGrey2
    },
    totalValueContainer: {
        backgroundColor: colors.grey,
        padding: 10,
        paddingHorizontal: 25,
        width: 110
    },
    totalValueText: {
        fontFamily: 'ClanOT-Medium',
        color: colors.midGrey2,
        textAlign: 'right',
        fontFamily: 'ClanOT-Medium',
        fontSize: 16
    },
    supportIdText: {
        fontSize: 12,
        color: 'lightslategray',
        textAlign: 'center',
        marginTop: 10
    },
    couponAddedEstimatedCostContainer: {
        flexDirection: 'row',
        marginBottom: 5,
        alignItems: 'center'
    },
    couponAddedHeader: {
        flex: 3,
        textAlign: 'right',
        marginRight: 20
    },
    couponAddedValue: {
        textAlign: 'right',
        fontFamily: 'ClanOT-Medium',
        fontSize: 16,
        width: 110
    },
    hr: {
        borderBottomWidth: 1, 
        borderColor: '#D5DBD4',
        borderStyle: 'solid',
        marginLeft: 20,
        marginRight: 20,
        width: '100%'
    },
    body: {
        width: `100%`,
        paddingHorizontal: 20,
        alignItems: 'center',
        flex: 1,
        marginBottom: 20,
        marginTop: 15
    },
    cancel: {
        borderWidth: 1,
        borderColor: colors.darkGrey2,
        borderRadius: 30,
        height: 36,
        width: 130
    },
});