import sanitize from '../sanitize';

export default data => {
    let {
        route = {},
        support = {},
        payment = {},
        customer = {},
        consumerCargo = {},
        commercialCargoItems = [],
        driveProgressConfirmation = {},
        driver = {},
    } = data;

    let { origin = {}, destination = {} } = route;

    return sanitize({
        id: data.id,
        requester_id: data.requesterId,
        driver_id: data.driverId,
        truck_id: data.truckId,
        helpers: data.helpers,
        dolly: data.dolly,
        addOns: data.addOns,
        customer: {
            name: customer.name,
            phone: customer.phone,
            email: customer.email,
        },
        payment: {
            charge_id: payment.chargeId,
            transfer_ids: payment.transferIds,
            tip: payment.tip
        },
        rating: data.rating, // deprecated
        starRating: data.starRating,
        price: data.price,
        status: data.status,
        start_time: data.startTime,
        pickup_time: data.pickupTime,
        end_time: data.endTime,
        accept_time: data.accept_time,
        drive_progress_confirmation: {
            pickup_arrival: driveProgressConfirmation.pickupArrival,
            pickup_loaded: driveProgressConfirmation.pickupLoaded,
            dropoff: driveProgressConfirmation.dropoff,
        },
        drive_type: data.driveType,
        consumer_cargo: {
            value: consumerCargo.value,
            weight: consumerCargo.weight,
            description: consumerCargo.description,
            images: consumerCargo.images,
        },
        commercial_cargo_items: commercialCargoItems.map(item => ({
            name: item.name,
            quantity: item.quantity,
            height: item.height,
            width: item.width,
            length: item.length,
            weight: item.weight,
            value: item.value,
            notes: item.notes,
            images: item.images,
        })),
        route: {
            distance: route.distance,
            origin: {
                business: origin.business,
                serviceCityId: origin.serviceCityId,
                name: origin.name,
                street: origin.street,
                city: origin.city,
                state: origin.state,
                zip: origin.zip,
                geo: origin.geo,
            },
            destination: {
                business: destination.business,
                serviceCityId: destination.serviceCityId,
                name: destination.name,
                street: destination.street,
                city: destination.city,
                state: destination.state,
                zip: destination.zip,
                geo: destination.geo,
            },
        },
        support: {
            driver_ext: support.driverExt,
            requester_ext: support.requesterExt,
            customer_ext: support.customerExt, // Legacy
        },
        guestName: data.guestName,
        categoryReason: data.categoryReason
    });
};
