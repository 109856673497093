import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    View,
    StyleSheet,
    Image,
    ActivityIndicator,
    Dimensions,
} from 'react-native';

import { Button } from '../components';

import { colors } from '../constants/theme';

export default class EditPhoto extends Component {
    static propTypes = {
        photo: PropTypes.any,
        onPhotoRemove: PropTypes.func.isRequired,
    };

    render() {
        const { photo, onPhotoRemove } = this.props;

        return (
            <View style={styles.container}>
                {photo
                    ? <Image
                          resizeMode={'contain'}
                          source={{ uri: photo }}
                          style={{ width: `100%`, height: `100%` }}
                      />
                    : <ActivityIndicator color={colors.green} />}
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={onPhotoRemove}
                        backgroundColor={colors.red}
                        muted
                    >
                        REMOVE
                    </Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        padding: 20,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
});
